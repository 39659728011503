import { createContext, useState, useEffect } from "react";

function Drivers(props) {
    const removeDriver = (index) => {
        const updatedDriverList = props.selectedDriverList.filter((_, i) => i !== index);
        props.setSelectedDriverList(updatedDriverList);
    };

    // Function to format the date
    const formatDate = (date) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString('en-US', options);
    };

    const formattedDate = formatDate(props.selectedDate);

    return (
        <div className="pt-14 pb-5 px-5 border-solid border-b border-gray-300">
            <h1 className="mt-7 text-xl font-bold">{formattedDate}</h1>

            <div onClick={() => props.setOpenDriversModal(true)} className="w-full shadow mt-2 border border-myteal-500 rounded py-2 cursor-pointer hover:bg-myteal-500 hover:text-white group">
                <div className="flex justify-center items-center text-myteal-500 group-hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="ionicon" viewBox="0 0 512 512">
                        <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/>
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 176v160M336 256H176"/>
                    </svg>
                    <p className="text-xs ml-1">Add drivers</p>
                </div>
            </div>

            <div id="driver-carousel" className="flex flex-wrap mt-3 mb-5">
                {props.selectedDriverList.map((driver, index) => (
                    <div
                        key={index}
                        className="mr-1 mt-1 pl-2 pr-1 py-1 rounded-3xl text-xs hover:shadow"
                        style={{
                            backgroundColor: `${driver.colorCode}25`, // transparency in hex
                            color: driver.colorCode
                        }}
                    >
                        <div className="flex justify-center items-center">
                            <p className="mr-1">{driver.name}</p>
                            <svg 
                                className="cursor-pointer" 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="15" 
                                height="15" 
                                viewBox="0 0 512 512"
                                onClick={() => removeDriver(index)}
                            >
                                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/>
                            </svg>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Drivers;
