// import React, { useEffect, useContext, useState } from 'react';
// import Header from '../../partials/Header'

// function TeamsWaitlist() {

//     function goWaitlist() {

//         // fb tracking
//         if (window.fbq) {
//             window.fbq('track', 'Lead', {
//                 product: 'Routora for Teams'
//             });
//         }

//         // google tracking
//         try {
//             // Send the waitlist click event to Google Analytics after the user is successfully created
//             window.gtag && window.gtag('event', 'waitlist_click', {
//                 'event_category': 'Teams Waitlist',
//                 'event_label': 'Waitlist',
//                 'value': 10
//             });
//             console.log('New Waitlist Click')
//             } catch (error) {
//             console.log('Error in tracking waitlist click in Google Analytics', error);
//         }

//         window.open('https://docs.google.com/forms/d/e/1FAIpQLSdwD52EgyuTukBcDh4pliKp2kvMyDMjTg8yKNzhUmgz2puFJg/viewform?usp=sf_link', '_blank').focus();
//     }

//   return (
//     <div className="relative min-h-screen">

//         <Header></Header>
//         <div className="buffer-100"></div>


//         <div
//             className="absolute bottom-0 left-0 px-10 rounded-tl-4xl"
//             style={{ width: '40%' }}
//         >
//             <div className="relative max-w-4xl mx-auto px-4 sm:px-6">
//                 <div className="pb-4 md:pb-7"></div>
//                 {/* Section header */}
//                 <div className="max-w-3xl mt-10">
//                     <h2 className="h1 mb-4 text-myblue-900">Got a Team?</h2>
//                     <h3 className="h4 mb-10 text-gray-500">We're building something just for you.</h3>
//                 </div>

//                 <div className="mt-7 max-w-lg">
//                     <div className="flex my-2">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
//                         <p style={{ fontSize: 20 }} className="font-medium text-gray-600 text-left ml-1">Scalable fleet management</p>
//                     </div>
//                     <div className="flex my-2">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
//                         <p style={{ fontSize: 20 }} className="font-medium text-gray-600 text-left ml-1">Add multiple drivers</p>
//                     </div>
//                     <div className="flex my-2">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
//                         <p style={{ fontSize: 20 }} className="font-medium text-gray-600 text-left ml-1">Balanced route distribution</p>
//                     </div>

//                     <div className="flex my-2">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
//                         <p style={{ fontSize: 20 }} className="font-medium text-gray-600 text-left ml-1">Import stops from excel, CSV</p>
//                     </div>
//                     <div className="flex my-2">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
//                         <p style={{ fontSize: 20 }} className="font-medium text-gray-600 text-left ml-1">Download your itinerary</p>
//                     </div>
//                     <div className="flex my-2">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
//                         <p style={{ fontSize: 20 }} className="font-medium text-gray-600 text-left ml-1">Share routes</p>
//                     </div>
//                 </div>

//                 <div className="flex items-center justify-center mt-10 mb-20">
//                     <div onClick={goWaitlist} className="btn text-xl px-10 bg-blue-500 w-full rounded text-white cursor-pointer hover:bg-myteal-600">
//                         <p>Join the Waitlist</p>
//                     </div>
//                 </div>


//             </div>
//         </div>


//         {/* Image remains at the bottom */}
//         <div>
//             <img
//                 className="absolute bottom-0 right-0 shadow-2xl rounded-tl-4xl"
//                 style={{ width: '60%' }}
//                 src={require('../../images/fleet-visual-border1.png').default}
//                 alt="Routora for Teams Visual"
//             />
//         </div>

//     </div>
//   );
// }

// export default TeamsWaitlist;



import React, { useEffect, useContext, useState } from 'react';
import Header from '../../partials/Header'

function TeamsWaitlist() {

    function goWaitlist() {

        // fb tracking
        if (window.fbq) {
            window.fbq('track', 'Lead', {
                product: 'Routora for Teams'
            });
        }

        // google tracking
        try {
            // Send the waitlist click event to Google Analytics after the user is successfully created
            window.gtag && window.gtag('event', 'waitlist_click', {
                'event_category': 'Teams Waitlist',
                'event_label': 'Waitlist',
                'value': 10
            });
            console.log('New Waitlist Click')
            } catch (error) {
            console.log('Error in tracking waitlist click in Google Analytics', error);
        }

        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdwD52EgyuTukBcDh4pliKp2kvMyDMjTg8yKNzhUmgz2puFJg/viewform?usp=sf_link', '_blank').focus();
    }

  return (
    <div >

        <Header></Header>
        <div className="buffer-100"></div>

        <div className="relative max-w-4xl mx-auto px-4 sm:px-6">
            <div className="pb-4 md:pb-7"></div>
            {/* Section header */}
            <div className="max-w-3xl mx-auto mt-10 text-center">
                <h2 className="h1 mb-4 text-myblue-900">Got a Team?</h2>
                <h3 className="h4 mb-10 text-gray-500 italic">We're building something just for you.</h3>
            </div>

            <div className="hidden md:flex justify-center text-gray-600 items-center my-5">
                {/* Container */}
                <div className="flex text-xs md:text-xl items-start space-x-4">
                    
                    {/* Left Text */}
                    <div className="flex flex-col items-end">
                    <p className="mb-4 hover:text-gray-900" data-aos="fade-right" data-aos-delay="50">Scalable fleet management</p>
                    <p className="mb-4 hover:text-gray-900" data-aos="fade-right" data-aos-delay="100">Add multiple drivers</p>
                    <p className="mb-4 hover:text-gray-900" data-aos="fade-right" data-aos-delay="150">Balanced route distribution</p>
                    </div>
                    
                    {/* Vertical Line */}
                    <div className="flex flex-col justify-center" data-aos="fade-down" data-aos-delay="10">
                        <div className="w-px bg-gray-400" style={{ height: '125px', width: '1.5px' }}></div>
                    </div>
                    
                    {/* Right Text */}
                    <div className="flex flex-col items-start">
                    <p className="mb-4 hover:text-gray-900" data-aos="fade-left" data-aos-delay="50">Import stops from excel, CSV</p>
                    <p className="mb-4 hover:text-gray-900" data-aos="fade-left" data-aos-delay="100">Download your itinerary</p>
                    <p className="mb-4 hover:text-gray-900" data-aos="fade-left" data-aos-delay="150">Share routes</p>
                    </div>

                </div>
            </div>

            <div className="md:hidden mt-7 max-w-lg mx-auto text-center">
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Scalable fleet management</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Add multiple drivers</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Balanced route distribution</p>
                </div>

                <div className="w-full border-t border-gray-300 my-3">
                    <div className="border-t-0 h-0.5" />
                </div>

                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Import stops from excel, CSV</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Download your itinerary</p>
                </div>
                <div className="flex my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                    <p style={{ fontSize: 15 }} className="font-medium text-gray-600 text-left ml-1">Share routes</p>
                </div>
            </div>

            <div className="flex items-center justify-center mt-10 mb-16">
                <div onClick={goWaitlist} className="btn shadow-2xl text-2xl py-5 px-10 bg-myteal-500 rounded-full text-white cursor-pointer hover:bg-myteal-600">
                    <p>Join the Waitlist</p>
                </div>
            </div>


        </div>



        {/* Image remains at the bottom */}
        <div className="flex justify-center" data-aos="fade-up" data-aos-delay="200">
            <img
                className="shadow-2xl px-4 md:px-0 rounded-tl-4xl"
                style={{ width: '1100px' }}
                src={require('../../images/full-fleet-visual.png').default}
                alt="Routora for Teams Visual"
            />
        </div>
        
        
        <div style={{ backgroundImage: "linear-gradient(white, #c9fff3)", zIndex: -1 }} className="fixed h-2/5 bottom-0 w-full"></div>
    </div>
  );
}

export default TeamsWaitlist;