import { useState, useEffect } from "react";
import ShareRoutesModal from './ShareRoutesModal'

function DriverRoutes(props) {
    const [isVisible, setIsVisible] = useState(false); // set up the animation

    useEffect(() => {

        if (!props.driverRoutes || !props.driverRoutes.routes) {
            props.setOpenDriverRoutes(false)
        }

        // Trigger the visibility after the component mounts
        setTimeout(() => {
            setIsVisible(true);
        }, 100); // Delay to ensure the component is mounted before the animation starts
    }, []);

    function goBack() {
        props.setOpenDriverRoutes(false)
        props.setDriverRoutes(null)
        props.setClickedDriver(0)
    }

    function open() {
        props.setOpenShareRoutesModal(true)
    }

    return (
        <div
            className={`absolute bg-gray-100 top-0 left-0 w-96 h-screen overflow-y-auto pt-12 pb-5 px-5 bg-white z-10 border-solid border-r border-gray-300 shadow-xl
                transition-transform duration-500 ease-out transform ${
                    isVisible ? "translate-x-0" : "-translate-x-full"
                }`}
            >

            <div className="mt-7 flex">
                <svg onClick={goBack} xmlns="http://www.w3.org/2000/svg" class="mt-1 mr-2 cursor-pointer" width="13" height="13" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M244 400L100 256l144-144M120 256h292"/></svg>
                <p onClick={goBack} className="text-sm cursor-pointer">Back</p>
            </div>

            {(props.driverRoutes && props.driverRoutes.routes) &&
                <div className="mt-5">
                    {props.driverRoutes.routes.map((route, index) => {
                        // Function to convert minutes to hours and minutes
                        const convertToHoursAndMinutes = (totalMinutes) => {
                            const hours = Math.floor(totalMinutes / 60);
                            const minutes = Math.ceil(totalMinutes % 60); // Use Math.ceil to round up
                        
                            return hours > 0 
                                ? `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`
                                : `${minutes} minute${minutes !== 1 ? 's' : ''}`;
                        };
                        

                        return (
                        <a
                            key={index + 1}
                            className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${props.clickedDriver !== (route.driverInfo.id) ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                            href="#0"
                            onClick={(e) => { e.preventDefault(); props.setClickedDriver(route.driverInfo.id); }}
                        >
                            <div>
                            <div 
                                style={{ color: route.driverInfo.colorCode }}
                                className="font-bold text-sm leading-snug tracking-tight mb-1">
                                {route.driverInfo.name}
                            </div>
                            <div className="text-gray-600 text-sm font-semibold">{route.routeOrder.length - 2} stops</div>
                            <div className="text-gray-600 text-sm">{route.totalDistance} miles | {convertToHoursAndMinutes(route.totalTime)}</div>
                            </div>
                            <div
                                className="flex justify-center items-center w-7 h-7 rounded-full shadow flex-shrink-0 ml-auto"
                                style={{ backgroundColor: route.driverInfo.colorCode }}  // Inline style for background color
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className={`${props.clickedDriver !== (route.driverInfo.id) ? 'text-white' : 'text-gray-200'}`} width="15" height="15" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M184 112l144 144-144 144"/></svg>
                            </div>
                        </a>
                        );
                    })}
                </div>
            }


            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2">
                <button onClick={open} className="bg-myteal-500 w-60 shadow-xl justify-center items-center flex text-white py-2 px-6 rounded hover:bg-myteal-600">
                    <p className="mr-1 text-sm font-medium">Send routes to drivers</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"/></svg>
                </button>
            </div>


        </div>
    );
}

export default DriverRoutes;
