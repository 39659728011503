import React from 'react'
import Box from './Box.js';
import IndividualRoutes from './IndividualRoutes/IndividualRoutes.js'
import DirectionsInterface from './DirectionsInterface.js'
import Header from '../../partials/Header.js'
import Footer from '../../partials/Footer.js'
import { useState } from 'react';


function Interface() {

    const [directionsData, setDirectionsData] = useState({
        curatedLinks: {googleUrl: "", appleUrl: "", wazeUrl: ""},
        routeOrder: [],
        totalDistance: 0,
        totalTime: 0,
        fullApiResponse: {routes: []}
    })
    const [openEmailModal, setOpenEmailModal] = useState(false)
    console.log('IN INTERFACE PARENT')
    console.log(directionsData)

    // for route storage/retrieval
    const [routeId, setRouteId] = useState(null)

    return (
        <div>
            <Box
                setDirectionsData={setDirectionsData}
                directionsData={directionsData}
                setOpenEmailModal={setOpenEmailModal}
                openEmailModal={openEmailModal}
                setRouteId={setRouteId}
                routeId={routeId}
            ></Box>

            {/* used to scroll to the gap */}
            <div id="box-individual-routes-gap"></div>

            <IndividualRoutes
                directionsData={directionsData}
                setOpenEmailModal={setOpenEmailModal}
                openEmailModal={openEmailModal}
                routeId={routeId}
            ></IndividualRoutes>

            {/* used to scroll to the gap */}
            <div id="box-directions-gap"></div>

            {directionsData.routeOrder.length <= 25 && 
                <DirectionsInterface
                    directionsData={directionsData}
                ></DirectionsInterface>
            }

            <div className="mt-24"></div>
            <Footer></Footer>
        </div>
    );
}

export default Interface;