import React from 'react';

const SliderComponent = (props) => {

  const handleChange = (event) => {
    props.setValue(event.target.value);
  };

  return (
    <div className="flex items-center space-x-4 max-w-lg">
      <input
        type="range"
        min="1"
        max="40"
        value={props.value}
        onChange={handleChange}
        step="1"
        className="w-full h-2 rounded-lg bg-myteal-600 appearance-none cursor-pointer"
      />
      <input
        type="text"
        value={props.value}
        disabled
        className="w-16 p-2 text-center bg-gray-100 border border-gray-300 rounded-md focus:outline-none"
      />
    </div>
  );
};

export default SliderComponent;
