import React, { useContext } from 'react';

function Grid1() {
    return (
        <div className="group grid-elements grid1-parent flex flex-col md:flex-row text-lg items-end rounded-xl border transition duration-300 ease-in-out mb-3 shadow-md hover:bg-gray-100 hover:shadow-lg">
            {/* Image container */}
            <div className="grid1-container md:w-3/5 w-full border-r border-myblue-300">

                
                <div className="results-container-1">
                    <div className=" flex items-center">
                        <div className="flex items-center">
                            <div className="totalTimeResultsText text-2xl :text-2xl text-white font-bold leading-snug tracking-tight mt-2 md:mt-6 ml-6">4 hrs, 22 mins</div>
                            <div className="totalDistanceResultsText text-1xl :text-2xl text-gray-400 font-bold leading-snug tracking-tight mt-3 md:mt-7 ml-1">(128.4 mi)</div>
                        </div>
                        <div className="flex items-center mt-6 ml-auto">
                            <div className="mapsResultsButton btn-sm mx-5 px-6 text-gray-800 bg-myteal-500">
                                <span className="mapsResultsText">Start All</span>
                                <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 -mr-1" fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                                </svg>        
                            </div>
                            <div class="shareOptions visual flex justify-center rounded-lg text-lg mr-6" role="group">
                                <div class="bg-mygray-500 text-myteal-500 border-2 border-r-1 border-myteal-500 rounded-l-lg px-4 py-1 mx-0 outline-none focus:shadow-outline">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px" viewBox="0 0 24 24"><path fill="#00B28B" d="M12.71,16.29l-.15-.12a.76.76,0,0,0-.18-.09L12.2,16a1,1,0,0,0-.91.27,1.15,1.15,0,0,0-.21.33,1,1,0,0,0,1.3,1.31,1.46,1.46,0,0,0,.33-.22,1,1,0,0,0,.21-1.09A1,1,0,0,0,12.71,16.29ZM16,2H8A3,3,0,0,0,5,5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V5A1,1,0,0,1,8,4h8a1,1,0,0,1,1,1Z"/></svg>
                                </div>
                                <div class="bg-mygray-500 text-myteal-500 border-2 border-l-0 border-myteal-500 rounded-r-lg px-4 py-1 mx-0 outline-none focus:shadow-outline">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px" viewBox="0 0 24 24"><path fill="#00B28B" d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z"/></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img src={require('./GridImages/route-grid1.png').default} alt="Optimized Route Visual" className="grid1-img rounded-br-lg rounded-tl-lg md:rounded-l-lg md:rounded-br-none"/>
            </div>
            
            {/* Text container */}
            <div className="text-container md:w-2/5 w-full p-5 md:p-10">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">Plug in your stops and optimize</h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    No more backtracking. Routora generates the fastest and most efficient order of stops, leaving you with more time for what's important.
                </p>
                {/* Add more text or elements as needed */}
            </div>
        </div>
    );
}

export default Grid1;






