import React from "react";

const Dropdown = (props) => {

  const displayOptions = () => {
    return props.options.map((option, index) => {
      return (
        <option key={index} value={option.value} className="text-gray-800">
          {option.label}
        </option>
      );
    });
  };

  return (
    <div className="p-4">
      <label
        className="block text-gray-600 text-xs font-medium mb-1"
        htmlFor="dropdown"
      >
        {props.label}
      </label>
      <select
        id="dropdown"
        className="form-input w-full text-gray-800 px-2 py-1 pr-8 text-xs hover:bg-gray-100"
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      >
        <option className="text-gray-600" disabled value="">
          {props.default_option}
        </option>
        {displayOptions()}
      </select>
    </div>
  );
};

export default Dropdown;
