import React, { useState } from 'react';
import './ImportMethods.css'

function ImportMethods(props) {


    function typeClicked(e) {
        if (props.showResultBox === 1) {
            props.setShowResultBox(2)
        }
        let currType = e.currentTarget.id.split('-')[1]
        console.log(currType)

        if (currType === 'address') {
            props.setButtonTextColors({address: '#00b28b', url: '#7F7F7F', list: '#7F7F7F', import: '#7F7F7F'})
            props.setButtonBGColors({address: '#e0fff8', url: '#FFFFFF', list: '#FFFFFF', import: '#FFFFFF'})

            props.setImportType('address')
        } else if (currType === 'url') {
            props.setButtonTextColors({address: '#7F7F7F', url: '#00b28b', list: '#7F7F7F', import: '#7F7F7F'})
            props.setButtonBGColors({address: '#FFFFFF', url: '#e0fff8', list: '#FFFFFF', import: '#FFFFFF'})

            props.setImportType('url')
        } else if (currType === 'list') {
            props.setButtonTextColors({address: '#7F7F7F', url: '#7F7F7F', list: '#00b28b', import: '#7F7F7F'})
            props.setButtonBGColors({address: '#FFFFFF', url: '#FFFFFF', list: '#e0fff8', import: '#FFFFFF'})

            props.setImportType('list')
            props.setListModalOpen(true)
        } else if (currType === 'import') {
            props.setButtonTextColors({address: '#7F7F7F', url: '#7F7F7F', list: '#7F7F7F', import: '#00b28b'})
            props.setButtonBGColors({address: '#FFFFFF', url: '#FFFFFF', list: '#FFFFFF', import: '#e0fff8'})

            props.setImportType('import')
            props.setImportModalOpen(true)
            // alert('Coming Soon!')
        }
    }
    

    return (
        <div className="flex justify-center">
            <div className="flex w-4/5">
                <div id="type-address" style={{ backgroundColor: props.buttonBGColors.address }} className="flex mr-1 px-3 py-2 rounded-3xl cursor-pointer" onClick={typeClicked}>
                    <svg className="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="map-marker"><path fill={props.buttonTextColors.address} d="M12,2a8,8,0,0,0-8,8c0,5.4,7.05,11.5,7.35,11.76a1,1,0,0,0,1.3,0C13,21.5,20,15.4,20,10A8,8,0,0,0,12,2Zm0,17.65c-2.13-2-6-6.31-6-9.65a6,6,0,0,1,12,0C18,13.34,14.13,17.66,12,19.65ZM12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z"></path></svg>
                    <p style={{ color: props.buttonTextColors.address }} className="text-xs font-semibold hover:text-myteal-500">Address</p>
                </div>
                <div id="type-url" style={{ backgroundColor: props.buttonBGColors.url }} className="flex mx-1 px-3 py-2 rounded-3xl cursor-pointer" onClick={typeClicked}>
                    <svg className="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="link-alt"><path fill={props.buttonTextColors.url} d="M12.11,15.39,8.23,19.27a2.52,2.52,0,0,1-3.5,0,2.47,2.47,0,0,1,0-3.5l3.88-3.88a1,1,0,1,0-1.42-1.42L3.31,14.36a4.48,4.48,0,0,0,6.33,6.33l3.89-3.88a1,1,0,0,0-1.42-1.42ZM20.69,3.31a4.49,4.49,0,0,0-6.33,0L10.47,7.19a1,1,0,1,0,1.42,1.42l3.88-3.88a2.52,2.52,0,0,1,3.5,0,2.47,2.47,0,0,1,0,3.5l-3.88,3.88a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l3.88-3.89A4.49,4.49,0,0,0,20.69,3.31ZM8.83,15.17a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.92-4.92a1,1,0,1,0-1.42-1.42L8.83,13.75A1,1,0,0,0,8.83,15.17Z"></path></svg>
                    <p style={{ color: props.buttonTextColors.url }} className="text-xs font-semibold hover:text-myteal-500">URL</p>
                </div>
                <div id="type-list" style={{ backgroundColor: props.buttonBGColors.list }} className="flex mx-1 px-3 py-2 rounded-3xl cursor-pointer" onClick={typeClicked}>
                    <svg className="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="clipboard-notes"><path fill={props.buttonTextColors.list} d="M13,14H9a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2ZM17,4H15.82A3,3,0,0,0,13,2H11A3,3,0,0,0,8.18,4H7A3,3,0,0,0,4,7V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V7A3,3,0,0,0,17,4ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm8,14a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V7A1,1,0,0,1,7,6H8V7A1,1,0,0,0,9,8h6a1,1,0,0,0,1-1V6h1a1,1,0,0,1,1,1Zm-3-9H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"></path></svg>
                    <p style={{ color: props.buttonTextColors.list }} className="text-xs font-semibold hover:text-myteal-500">List</p>
                </div>
                <div id="type-import" style={{ backgroundColor: props.buttonBGColors.import }} className="flex mx-1 px-3 py-2 rounded-3xl cursor-pointer" onClick={typeClicked}>
                    <svg className="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="file-import"><path fill={props.buttonTextColors.import} d="M11 20H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v2a1 1 0 0 0 2 0V8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.32 1.32 0 0 0-.19-.29l-6-6a1.32 1.32 0 0 0-.29-.19.32.32 0 0 0-.09 0l-.31-.1H6a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h5a1 1 0 0 0 0-2Zm2-14.59L15.59 8H14a1 1 0 0 1-1-1ZM19 15h-5.59l1.3-1.29a1 1 0 0 0-1.42-1.42l-3 3a1.15 1.15 0 0 0-.21.33 1 1 0 0 0 0 .76.93.93 0 0 0 .21.33l3 3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42L13.41 17H19a1 1 0 0 0 0-2Z"></path></svg>
                    <p style={{ color: props.buttonTextColors.import }} className="text-xs font-semibold hover:text-myteal-500">Import</p>
                </div>
            </div>
        </div>
    );
}

export default ImportMethods;

