import { createContext, useState, useEffect } from "react";
import './StopEntry.css'
import StopsAutocomplete from "../../../mycomponents/Interface/StopsAutocomplete";
import ImportMethodsTeams from "./ImportMethodsTeams";


function StopEntry(props) {


    function clearAddresses() {
        let stoplist = ['','']

        props.setEnteredStops(stoplist)

        // reset stops
        let list = {...props.addressCoordinateList}
        list.stops = []

        props.setAddressCoordinateList(list)
        props.setDriverRoutes(null)
    }
    function handleStopChange(value, stopIndex) {
        let stopContent = value

        const list = [...props.enteredStops]
        list[stopIndex] = stopContent
        if (stopIndex === props.enteredStops.length-1 && stopContent.length > 0 && list.length < 100) {
            list.push('')
        }
        props.setEnteredStops(list)
    }
    async function handleStopSelect(value, stopIndex) {
        handleStopChange(value, stopIndex)

        if (props.enteredStops.slice(0, -2).includes(value)) {
            alert('Oops, looks like you already have this address')
            // setMainMessage('Oops, looks like you already have this address')
            // setIsAction(false)
            // setActionMessage('')
            // setNavigate('')
            // setModalOpen(true);

            let stoplist = [...props.enteredStops]
            if (stoplist.length > 1) {
                stoplist.splice(stopIndex, 1)
            }
            props.setEnteredStops(stoplist)
        } else {
            // get coordinates and update list
            let coordinates = await props.getCoordinates(value)
            let list = {...props.addressCoordinateList}
            list.stops.push({name: value, coordinates: [coordinates.lat, coordinates.lng]})
            props.setAddressCoordinateList(list)
        }
    }
    function handleDeleteStop(stopIndex) {
        let stoplist = [...props.enteredStops]
        if (stoplist.length > 1) {
            stoplist.splice(stopIndex, 1)
        }

        props.setEnteredStops(stoplist)
    }


    return (
        <div className="flex-grow overflow-hidden flex flex-col">

            <div className="flex">
                <h4 className="stops-text-teams ml-4 text-base">Stops</h4>
                <h5 className="clear-text-message-teams cursor-pointer" onClick={clearAddresses}>CLEAR ALL</h5>
            </div>
            <ImportMethodsTeams
                importType={props.importType}
                setImportType={props.setImportType}
                setListModalOpen={props.setListModalOpen}
                setImportModalOpen={props.setImportModalOpen}
                setButtonTextColors={props.setButtonTextColors}
                buttonTextColors={props.buttonTextColors}
                setButtonBGColors={props.setButtonBGColors}
                buttonBGColors={props.buttonBGColors}
            ></ImportMethodsTeams>
            <div id="stop-list" className="flex-grow overflow-y-auto mt-1">
                {props.enteredStops.map((item, i) =>
                    <div key={i} className="input-container-teams stops">
                        <div className="stopCount-teams-container">
                            {(i < 9) ? (
                                <p className="stopCount-teams-text">{'0'+(i+1)}</p>
                            ) : (
                                <p className="stopCount-teams-text">{i+1}</p>
                            )}
                        </div>
                        <div className="relative">
                            <StopsAutocomplete
                                class="form-input w-11/12 text-gray-800 px-2 py-1 pr-8 text-xs float-right hover:bg-gray-100"
                                placeholder="Add Destination"
                                content={item}
                                contentFunction={props.setEnteredStops}
                                handleChange={e => handleStopChange(e, i)}
                                handleSelect={e => handleStopSelect(e, i)}
                            ></StopsAutocomplete>
                            <button className="absolute inset-0 left-auto">
                                <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                                <div className="justify-center items-center text-gray-400 hover:text-black" onClick={() => handleDeleteStop(i)}>
                                    <svg style={{marginTop: 7}} className="cursor-pointer mr-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
                                </div>
                            </button>
                        </div>
                    </div>
                )}
                <div className="buffer-200"></div>
            </div>
        </div>
    );
}

export default StopEntry;