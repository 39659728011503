import React from 'react';
import './DirectionsInterface.css'


function DirectionsInterface(props) {


    let content = (
        <div>
            <div class="md:text-3xl font-bold text-gray-700 leading-snug tracking-tight mb-1">Full route direction breakdown will be shown here🗺</div>
            <div class="md:text-2xl text-gray-600">For routes under 25 stops, a detailed, step by step direction instruction guide will be broken down for you:) </div>
        </div>
    )

    if (props.directionsData.fullApiResponse.routes.length > 0) {

        content = (
            <div>
                <div className="p-6 flex items-center">
                    <div>
                        <div className="text-5xl :text-5xl font-bold leading-snug tracking-tight mb-1 mr-3">Full Route Directions👇</div>
                    </div>
                </div>
                {
                    props.directionsData.fullApiResponse.routes[0].legs.map((leg, i) =>
                        <div>
                            <div className="items-center text-lg p-6 rounded border transition duration-300 ease-in-out mb-3 mt-3 bg-white shadow-md border-gray-200 hover:shadow-lg cursor-pointer">
                                <div className="flex items-center">
                                    <div className="text-4xl :text-4xl font-bold leading-snug tracking-tight mb-1 mr-3">{leg.duration.text}</div>
                                    <div className="md:text-2xl text-gray-500">{'(' + leg.distance.text + ')'}</div>
                                </div>
                                <div className="flex items-center">
                                    <div class="md:text-2xl font-bold text-gray-500 leading-snug tracking-tight mb-1 mr-4">{leg.start_address}</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#00B28B" d="M15.71,12.71a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-3-3a1,1,0,0,0-1.42,1.42L12.59,11H9a1,1,0,0,0,0,2h3.59l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0ZM22,12A10,10,0,1,0,12,22,10,10,0,0,0,22,12ZM4,12a8,8,0,1,1,8,8A8,8,0,0,1,4,12Z"/></svg>
                                    <div class="md:text-2xl font-bold text-gray-500 leading-snug tracking-tight mb-1 ml-4">{leg.end_address}</div>
                                </div>
                            </div>
                            {
                                leg.steps.map((item, i) =>
                                    <div className="pl-6 pt-6 pb-6">
                                        <div className="flex items-center">
                                            {setDirectionSvg(decodeURIComponent(item.html_instructions))}
                                            <div class="md:text-3xl font-bold text-gray-700 leading-snug tracking-tight mb-1 ml-4" dangerouslySetInnerHTML={{ __html: item.html_instructions }}></div>
                                        </div>
                                        <div className="padding1"></div>
                                        <p className="line-gap"><span className="md:text-1xl font-bold text-gray-500">{item.duration.text + ' (' + item.distance.text + ')'}</span></p>
                                        <div className="padding2"></div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        )
    }
    function setDirectionSvg(instruction) {
        let svgIcon = <div></div>
        if (instruction.includes('<b>right</b>')) {
            svgIcon = <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#00B28B" d="M19.61,7.25a1,1,0,0,0-.22-.33L14.76,2.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l2.92,2.92h-8a4,4,0,0,0-4,4V21a1,1,0,0,0,2,0V10.63a2,2,0,0,1,2-2h8l-2.92,2.92a1,1,0,0,0,.71,1.71,1,1,0,0,0,.7-.3l4.63-4.62A1.19,1.19,0,0,0,19.61,8,1,1,0,0,0,19.61,7.25Z"/></svg>
        } else if (instruction.includes('<b>left</b>')) {
            svgIcon = <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#00B28B" d="M14.69,6.63h-7l2.92-2.92a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L4.61,6.92a1,1,0,0,0-.22.33,1,1,0,0,0,0,.76,1.19,1.19,0,0,0,.22.33L9.24,13a1,1,0,0,0,.7.3,1,1,0,0,0,.71-1.71L7.73,8.63h7a3,3,0,0,1,3,3V21a1,1,0,0,0,2,0V11.63A5,5,0,0,0,14.69,6.63Z"/></svg>
        } else if (instruction.includes('Head') || instruction.includes('Continue')) {
            svgIcon = <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" data-name="Layer 1" viewBox="0 0 24 24"><path fill="#00B28B" d="M17.71,11.29l-5-5a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-5,5a1,1,0,0,0,1.42,1.42L11,9.41V17a1,1,0,0,0,2,0V9.41l3.29,3.3a1,1,0,0,0,1.42,0A1,1,0,0,0,17.71,11.29Z"/></svg>
        }

        return svgIcon
    }


    return (
        <div id="directions-box">
            {content}
        </div>
    );
}

export default DirectionsInterface;

