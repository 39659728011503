import React, { useState, useEffect, useRef, useContext } from 'react';

import './Box.css';
import Autocomplete from './Autocomplete.js'
import StopsAutocomplete from './StopsAutocomplete.js'
import MapInterface from './MapInterface/MapInterface.js'
import ModeBox from './ModeBox'
import ImportMethods from './ImportMethods.js'
import ResultsBox from './ResultsBox'
import Modal from '../Modal/Modal'
import FeedbackModal from './FeedbackForm/FeedbackModal'; // remove when done
import EmailModal from './EmailModal'
import ListModal from './ListModal.js'
import ImportModal from './ImportModal.js'
import SplitUrlModal from '../SplitUrlModal/SplitUrlModal'
import Header from '../../partials/Header.js'
import { AuthContext } from '../../AuthContext'

import {getGeocode, getLatLng,} from "use-places-autocomplete";

import { useHistory } from 'react-router-dom';

import stringSimilarity from "string-similarity";

import Axios from 'axios'

import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

function Box(props) {
    // const [refreshComponent, setRefreshComponent] = useState(0)

    const { currentUser, currentUserTier, checkFeedbackStatus, checkSubStatus, updateUserTier, updateSubId } = useContext(AuthContext);
    const history = useHistory();

    const [googleUrlContent, setGoogleUrlContent] = useState('');
    const [startLocationContent, setStartLocationContent] = useState('');
    const [endLocationContent, setEndLocationContent] = useState('');
    const [enteredStops, setEnteredStops] = useState(['','']);
    // all addresses and coordinates are tracked (even if deleted)
    const [addressCoordinateList, setAddressCoordinateList] = useState({
        start: {name: "", coordinates: [null, null]},
        stops: [],
        end: {name: "", coordinates: [null, null]}
    });

    const [fullAddressList, setFullAddressList] = useState([]) // only updated when optimized button pressed, all current addresses entered are tracked
    const [newRouteOrder, setNewRouteOrder] = useState([])

    const [startLocationLoading, setStartLocationLoading] = useState(false)
    const [endLocationLoading, setEndLocationLoading] = useState(false)
    const [mode, setMode] = useState('d')
    const [importType, setImportType] = useState('address')
    const [showResultBox, setShowResultBox] = useState(0) // 0=nothing, 1=fadein, 2=fadeout
    const [buttonContent, setButtonContent] = useState('optimize route');

    // Modal states
    const [mainMessage, setMainMessage] = useState('')
    const [isAction, setIsAction] = useState(false)
    const [actionMessage, setActionMessage] = useState('')
    const [navigate, setNavigate] = useState('')
    const [modalOpen, setModalOpen] = useState(false);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false); // remove when done
    const [listModalOpen, setListModalOpen] = useState(false)
    const [importModalOpen, setImportModalOpen] = useState(false)
    const [splitUrlModalOpen, setSplitUrlModalOpen] = useState(false)

    // Import Methods File
    let [buttonTextColors, setButtonTextColors] = useState({address: '#00b28b', url: '#7F7F7F', list: '#7F7F7F', import: '#7F7F7F'})
    let [buttonBGColors, setButtonBGColors] = useState({address: '#e0fff8', url: '#FFFFFF', list: '#FFFFFF', import: '#FFFFFF'})

    const googleUrlInputRef = useRef(null)
    useEffect(() => {
        if (!currentUser) {
            history.push('/signin')
        }
        
        // if there is data stored in local storage from last session
        const savedEnteredStops = JSON.parse(localStorage.getItem('enteredStops'));
        const savedAddressCoordinateList = JSON.parse(localStorage.getItem('addressCoordinateList'));
        const savedStartLocationContent = JSON.parse(localStorage.getItem('startLocationContent')); 
        const savedEndLocationContent = JSON.parse(localStorage.getItem('endLocationContent'));
        console.log('local storage:', localStorage)
        if (savedEnteredStops !== null && savedAddressCoordinateList !== null) {
            setEnteredStops(savedEnteredStops)
            setAddressCoordinateList(savedAddressCoordinateList)
            setStartLocationContent(savedStartLocationContent)
            setEndLocationContent(savedEndLocationContent)
        }

    }, [])

    // saves (in local storage) entered stops and addressCoordinateList when addressCoordinateList changes at all 
    useEffect(() => {

        if (addressCoordinateList.stops.length > 0 || addressCoordinateList.start.name !== '' || addressCoordinateList.start.name !== '') {
            localStorage.setItem('enteredStops', JSON.stringify(enteredStops));
            localStorage.setItem('addressCoordinateList', JSON.stringify(addressCoordinateList));
            localStorage.setItem('startLocationContent', JSON.stringify(startLocationContent));
            localStorage.setItem('endLocationContent', JSON.stringify(endLocationContent));
        }
        
    }, [addressCoordinateList])

    useEffect(() => {
        if (importType == 'list' && listModalOpen) {
            // Disable scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling
            document.body.style.overflow = 'auto';
        }
    }, [importType, listModalOpen]);

    function handleGoogleUrlChange(e) {
        if (showResultBox === 1) {
            setShowResultBox(2)
        }
        setGoogleUrlContent(e.target.value)
    }
    async function handleStartLocationChange(value){
        if (showResultBox === 1) {
            setShowResultBox(2)
        }
        let coordinates = await getCoordinates(value)
        let list = {...addressCoordinateList}
        list.start = {name: value, coordinates: [coordinates.lat, coordinates.lng]}
        setStartLocationContent(value)
        setAddressCoordinateList(list)
    }
    async function handleEndLocationChange(value){
        if (showResultBox === 1) {
            setShowResultBox(2)
        }
        let coordinates = await getCoordinates(value)
        let list = {...addressCoordinateList}
        list.end = {name: value, coordinates: [coordinates.lat, coordinates.lng]}
        setEndLocationContent(value)
        setAddressCoordinateList(list)
    }
    function handleStopChange(value, stopIndex) {
        let stopContent = value

        const list = [...enteredStops]
        list[stopIndex] = stopContent
        if (stopIndex === enteredStops.length-1 && stopContent.length > 0 && list.length < 100) {
            list.push('')
        }
        setEnteredStops(list)
    }
    async function handleStopSelect(value, stopIndex) {
        if (showResultBox === 1) {
            setShowResultBox(2)
        }
        handleStopChange(value, stopIndex)

        if (enteredStops.slice(0, -2).includes(value)) {
            setMainMessage('Oops, looks like you already have this address')
            setIsAction(false)
            setActionMessage('')
            setNavigate('')
            setModalOpen(true);

            let stoplist = [...enteredStops]
            if (stoplist.length > 1) {
                stoplist.splice(stopIndex, 1)
            }
            setEnteredStops(stoplist)
        } else {
            // get coordinates and update list
            let coordinates = await getCoordinates(value)
            let list = {...addressCoordinateList}
            list.stops.push({name: value, coordinates: [coordinates.lat, coordinates.lng]})
            setAddressCoordinateList(list)
        }
    }

    async function getCoordinates(address) {
        try {
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          return { lat, lng }
        //   panTo({ lat, lng });
        } catch (error) {
          console.log("😱 Error: ", error);
        }
    };

    async function currentLocation(e) {
        if (showResultBox === 1) {
            setShowResultBox(2)
        }
        let currLat, currLng
        let inputId = e.currentTarget.id

        if (inputId === 'currLocation-startInput') {
            setStartLocationLoading(true)
        } else if (inputId === 'currLocation-endInput') {
            setEndLocationLoading(true)
        }
        let isSuccess = true
        const pos = await new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            } else {
                if (inputId === 'currLocation-startInput') {
                    setStartLocationLoading(false)
                } else if (inputId === 'currLocation-endInput') {
                    setEndLocationLoading(false)
                }
            }
          }).catch(error => {
            isSuccess = false
            alert('Could not retrieve current location')
            console.error(error)
            if (inputId === 'currLocation-startInput') {
                setStartLocationLoading(false)
            } else if (inputId === 'currLocation-endInput') {
                setEndLocationLoading(false)
            }
          })

        // if current location is successfully tracked
        if (isSuccess) {
            currLat = pos.coords.latitude
            currLng = pos.coords.longitude

            console.log(currLat + ', ' + currLng)
            let currCoordinates = currLat + ',' + currLng
            let list = {...addressCoordinateList}
            if (inputId === 'currLocation-startInput') {
                if (list.end.name === '' || endLocationContent === '' || list.end.coordinates[0] == null) {
                    list.start = {name: currCoordinates, coordinates: [currLat, currLng]}
                    list.end = {name: currCoordinates, coordinates: [currLat, currLng]}
                    setEndLocationContent(currCoordinates)
                } else {
                    list.start = {name: currCoordinates, coordinates: [currLat, currLng]}
                }

                setStartLocationLoading(false)
                setStartLocationContent(currCoordinates)
            } else if (inputId === 'currLocation-endInput') {
                if (list.start.name === '' || startLocationContent === '' || list.start.coordinates[0] == null) {
                    list.start = {name: currCoordinates, coordinates: [currLat, currLng]}
                    list.end = {name: currCoordinates, coordinates: [currLat, currLng]}
                    setStartLocationContent(currCoordinates)
                } else {
                    list.end = {name: currCoordinates, coordinates: [currLat, currLng]}
                }

                setEndLocationLoading(false)
                setEndLocationContent(currCoordinates)
            }
            setAddressCoordinateList(list)

            
            if (inputId === 'currLocation-startInput') {
                setStartLocationLoading(false)
                setStartLocationContent(currCoordinates)
            } else if (inputId === 'currLocation-endInput') {
                setEndLocationLoading(false)
                setEndLocationContent(currCoordinates)
            }
        }
    }

    async function handleKeyDown(e) {

        if (e.key === 'Enter') {
            console.log('CURRENT ID -> ' + e.target.id)
            console.log('Enter Clicked, URL -> ' + googleUrlContent)

            await submitUrl()

        }
    }

    async function submitUrl() {

        try {

            if (googleUrlContent.includes('google.com/maps/dir/')) {
                let addressListStr = googleUrlContent.split('dir/')[1]
                addressListStr = addressListStr.split('/@')[0]
        
                let splitAddressList = addressListStr.split('/')
                console.log(splitAddressList)

                // set start and end location
                let startAddress = decodeAddress(splitAddressList[0])
                let endAddress = decodeAddress(splitAddressList[splitAddressList.length-1])
                setStartLocationContent(startAddress)
                setEndLocationContent(endAddress)

                // set stop list 
                let list = []
                for (let i = 1; i < splitAddressList.length-1; i++) {
                    list.push(decodeAddress(splitAddressList[i]))
                }
                list.push('')
                setEnteredStops(list)

                let coordinates = await getCoordinates(startAddress)
                let tempList = {...addressCoordinateList}
                tempList.start = {name: startAddress, coordinates: [coordinates.lat, coordinates.lng]}
                for (let i = 0; i < list.length; i++) {
                    if (list[i].length > 0) {
                        coordinates = await getCoordinates(list[i])
                        tempList.stops.push({name: list[i], coordinates: [coordinates.lat, coordinates.lng]})
                    }
                }
                coordinates = await getCoordinates(endAddress)
                tempList.end = {name: endAddress, coordinates: [coordinates.lat, coordinates.lng]}
                setAddressCoordinateList(tempList)
            } else {
                // alert('Invalid url')
                setMainMessage('Invalid Url entered! Please try again')
                setIsAction(false)
                setActionMessage('')
                setNavigate('')
                setModalOpen(true);
            }
        } catch(err) {
            //alert('Error with google maps url, please check if valid')
            setMainMessage('Error with google maps url, please check if valid')
            setIsAction(false)
            setActionMessage('')
            setNavigate('')
            setModalOpen(true);
        }

    }

    async function submitList(validationResults) {

        try {

            if (validationResults.length > 1) {

                // get selected results
                let selectedResults = []
                for (let i = 0; i < validationResults.length; i++) {
                    if (validationResults[i].selected) {
                        selectedResults.push(validationResults[i])
                    }
                }


                // set stop list 
                let list = []
                for (let i = 0; i < selectedResults.length; i++) {
                    list.push(selectedResults[i].formattedAddress)
                }
                list.push('')
                console.log('list', list)
                setEnteredStops(list)

                let tempList = {...addressCoordinateList}
                let lat
                let lng
                let coordinates
                for (let i = 0; i < list.length; i++) {
                    if (list[i].length > 0) {
                        
                        
                        lat = selectedResults[i].coordinates.lat
                        lng = selectedResults[i].coordinates.lng

                        // if coordinates already retrieved, add those -> else get the coordinates for the given address
                        if (lat !== null && lng !== null) {
                            tempList.stops.push({name: list[i], coordinates: [lat, lng]})
                        } else {
                            coordinates = await getCoordinates(list[i])
                            tempList.stops.push({name: list[i], coordinates: [coordinates.lat, coordinates.lng]})
                        }

                    }
                }
                console.log('temp list', tempList)
                setAddressCoordinateList(tempList)

            } else {
                // alert('Invalid url')
                setMainMessage('Need more than one stop')
                setIsAction(false)
                setActionMessage('')
                setNavigate('')
                setModalOpen(true);
            }
        } catch(err) {
            //alert('Error with google maps url, please check if valid')
            setMainMessage('Error with importing stops, please check if valid')
            setIsAction(false)
            setActionMessage('')
            setNavigate('')
            setModalOpen(true);
        }

    }

    function handleDeleteStop(stopIndex) {
        if (showResultBox === 1) {
            setShowResultBox(2)
        }
        let stoplist = [...enteredStops]
        if (stoplist.length > 1) {
            stoplist.splice(stopIndex, 1)
        }

        setEnteredStops(stoplist)
    }

    function decodeAddress(address) {
        let splitAddress = address.split('+')
        let newAddress = ''
        for (let a = 0; a < splitAddress.length; a++){
            if (a < splitAddress.length-1){
                newAddress += decodeURIComponent(splitAddress[a])+' '
            } else {
                newAddress += decodeURIComponent(splitAddress[a])
            }
        }
        return newAddress
    }

    function deleteTaskId(taskId) {
        // delete taskid document after use
        Axios.post(`https://api.routora.com/delete-taskid/${taskId}`).then((response) => {
            console.log('success') // .then waits for completion and runs code inside brackets

            console.log(response.data)

        }).catch((error) => {
            console.error('Error deleting taskId');
            console.error('Error:', error);
        })
    }

    // retrieve original address
    function getOgCoords(ogStopList, address) {

        for (let i = 0; i < ogStopList.length; i++) {
            if (ogStopList[i].address == address) {
                return ogStopList[i].coordinates
            }
        }
    
        // if above method does not work (should work) find most similar string
        let stopListAddresses = []
        for (let i = 0; i < ogStopList.length; i++) {
            stopListAddresses.push(ogStopList[i].address)
        }
        try {
            let matches = stringSimilarity.findBestMatch(address, stopListAddresses);
            return ogStopList[matches.bestMatchIndex].coordinates
        } catch (error) {
            console.log("String Similarity Error: " + error)
        }
    
        // should never get here
        return [null, null]
    }

    async function optimizeRoute() {
        if (startLocationContent.length > 0 && endLocationContent.length > 0 && enteredStops[0].length > 0) {
            setButtonContent('loading...')
            let addressList = [startLocationContent]
            for (let i = 0; i < enteredStops.length; i++) {
                if (enteredStops[i].length > 0) {
                    addressList.push(enteredStops[i])
                }
            }
            addressList.push(endLocationContent)

            setFullAddressList(addressList)

            if (currentUser) {

                // check subscription status on stripe
                let subStatus = 'active'
                if (currentUserTier > 1) {
                    subStatus = await checkSubStatus(currentUser)
                    console.log('Subscription Status:', subStatus)
                    if (subStatus == 'canceled') {
                        updateUserTier(currentUser, 0)
                        updateSubId(currentUser, null)
                    }
                }

                // if the user is a PLUS subscriber, proceed to optimization
                if (currentUserTier > 1 && subStatus != 'canceled') {

                    let stopList = []
                    // loop through current address list, just stops so ignoring first and last element
                    for (let a = 1; a < addressList.length-1; a++) {
                        // cross reference with address coordinate list to retrieve coordinates (this list includes deleted elements so cant use this directly)
                        for (let b = 0; b < addressCoordinateList.stops.length; b++) {
                            if (addressList[a] == addressCoordinateList.stops[b].name) {
                                stopList.push({address: addressCoordinateList.stops[b].name, coordinates: addressCoordinateList.stops[b].coordinates}) // update stop list parameter after match
                                break;
                            }
                        }
                    }
                    
                    // make sure coordinates of start and end are there
                    let tempCoords
                    if (addressCoordinateList.start.coordinates[0] == null) {
                        tempCoords = await getCoordinates(startLocationContent)
                        console.log(tempCoords)
                        addressCoordinateList.start.coordinates = [tempCoords.lat, tempCoords.lng]
                    }
                    if (addressCoordinateList.end.coordinates[0] == null) {
                        tempCoords = await getCoordinates(startLocationContent)
                        console.log(tempCoords)
                        addressCoordinateList.end.coordinates = [tempCoords.lat, tempCoords.lng]
                    }

                    // if discrepency between start address and actual content
                    if (addressCoordinateList.start.name !== startLocationContent) {
                        alert('Start Location not selected. Please ensure you choose your address from the suggested options in the autocomplete dropdown.')
                        setButtonContent('optimize route')
                        return
                    }
                    // if discrepency between end address and actual content
                    if (addressCoordinateList.end.name !== endLocationContent) {
                        alert('End Location not selected. Please ensure you choose your address from the suggested options in the autocomplete dropdown.')
                        setButtonContent('optimize route')
                        return
                    }

                    let parameters = {
                        startAddress: {address: startLocationContent, coordinates: addressCoordinateList.start.coordinates}, 
                        stopList: stopList, 
                        destinationAddress: {address: endLocationContent, coordinates: addressCoordinateList.end.coordinates}, 
                        travelMethod: mode
                    }
                    console.log('API PARAMETERS')
                    console.log(parameters)

                    // amplitude tracking
                    const eventProperties = {
                        start_coordinates: parameters.startAddress.coordinates,
                        destination_coordinates: parameters.destinationAddress.coordinates,
                        travel_method: mode,
                        route_length: addressList.length,
                        import_type: importType
                    };
                    amplitude.track('Optimize Clicked', eventProperties);

                    // to get past heroku's 30 second timeout, response is different for 25+ stop routes
                    let totalAddressCount = stopList.length + 2
                    if (totalAddressCount > 25) {

                        // trigger polling technique, api will auto send a 202 response (202 meaning in progress)
                        Axios.post(`https://api.routora.com/optimize-app-test/${process.env.REACT_APP_HASHED_GOOGLE_MAPS_API}`, parameters).then((response) => {
                            console.log('success') // .then waits for completion and runs code inside brackets

                            console.log(response.data)
                            let taskId = response.data.taskId

                            let count = 0

                            // polling every n seconds
                            const interval = setInterval(async () => {
                                try {
                                    Axios.post(`https://api.routora.com/poll-status/${taskId}`).then((response) => {
                                        
                                        let pollStatus = response.data.pollStatus
                                        console.log('POLL STATUS -> ' + count)
                                        console.log(pollStatus)

                                        // if poll status comes back with updated data, display optimized route
                                        if (pollStatus !== null) {

                                            // adding coordinates back to the response - DONE IN BACKEND NOW
                                            // let optimized = pollStatus
                                            // try {
                                            //     optimized.routeOrder[0].coordinates = parameters.startAddress.coordinates
                                            //     for (let i = 1; i < optimized.routeOrder.length-1; i++) {
                                            //         let coords = getOgCoords(parameters.stopList, optimized.routeOrder[i].address)
                                            //         optimized.routeOrder[i].coordinates = coords
                                            //     }
                                            //     optimized.routeOrder[optimized.routeOrder.length-1].coordinates = parameters.destinationAddress.coordinates
                                            //     console.log(optimized)
                                            // } catch (error) {
                                            //     optimized = pollStatus
                                            // }


                                            props.setDirectionsData(pollStatus)
                                            updateEnteredStops(pollStatus.routeOrder)
                                            setNewRouteOrder(pollStatus.routeOrder)
                
                                            setButtonContent('optimize route')
                                            setShowResultBox(1)
                
                                            // Scroll to individual routes section
                                            goIndividualRoutes()

                                            clearInterval(interval);
                                            // delete taskId from firestore after use
                                            deleteTaskId(taskId)

                                            // finally store this route for future retrieval and sharing
                                            try {
                                                // getting everything from optimized except for fullApiResponse
                                                let routeData = {
                                                    curatedLinks: pollStatus.curatedLinks,
                                                    splitUrls: pollStatus.splitUrls,
                                                    routeOrder: pollStatus.routeOrder,
                                                    totalDistance: pollStatus.totalDistance,
                                                    totalTime: pollStatus.totalTime
                                                }
                                                Axios.post(`https://api.routora.com/save-route`, { routeData }).then((response) => {
                                                    // route should be saved and id used to save it should be passed back
                                                    let routeId = response.data.routeId
                                                    console.log(routeId)

                                                    // set route id for other components to refer to in DB
                                                    props.setRouteId(routeId)
                                                })
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        }

                                    }).catch((error) => {
                                        console.error('Error:', error);
                                    })

                                    // increment count
                                    count = count + 1
                                    if (count > 15) {
                                        clearInterval(interval);
                                        setButtonContent('optimize route')
                                        setMainMessage('We are having trouble connecting to our server, please try again later')
                                        setIsAction(false)
                                        setActionMessage('')
                                        setNavigate('')
                                        setModalOpen(true);

                                        // delete taskId from firestore after use
                                        deleteTaskId(taskId)
                                    }

                                } catch (error) {
                                    clearInterval(interval);
                                    console.error('Error:', error);
                                }
                            }, 5000); // Polling interval (e.g., every 5 seconds)

                        }).catch((error) => {
                            console.error('Error:', error)
                            setButtonContent('optimize route')
                            //alert('One or more entries are invalid, please check and try again.')
                            setMainMessage('One or more entries are invalid, please check and try again')
                            setIsAction(false)
                            setActionMessage('')
                            setNavigate('')
                            setModalOpen(true);
                        })

                    } else {
                    
                        // if route 25 stops or less get the data back with this endpoint (no polling)
                        Axios.post(`https://api.routora.com/optimize-app-test/${process.env.REACT_APP_HASHED_GOOGLE_MAPS_API}`, parameters).then((response) => {
                            console.log('success') // .then waits for completion and runs code inside brackets

                            console.log(response.data)

                            // adding coordinates back to the response - COORDINATES HAVE BEEN ADDED IN BACKEND
                            // let optimized = response.data
                            // try {
                            //     optimized.routeOrder[0].coordinates = parameters.startAddress.coordinates
                            //     for (let i = 1; i < optimized.routeOrder.length-1; i++) {
                            //         let coords = getOgCoords(parameters.stopList, optimized.routeOrder[i].address)
                            //         optimized.routeOrder[i].coordinates = coords
                            //     }
                            //     optimized.routeOrder[optimized.routeOrder.length-1].coordinates = parameters.destinationAddress.coordinates
                            //     console.log(optimized)
                            // } catch (error) {
                            //     optimized = response.data
                            // }


                            if (response.data.routeOrder.length > 0) {
                                props.setDirectionsData(response.data)
                                updateEnteredStops(response.data.routeOrder)
                                setNewRouteOrder(response.data.routeOrder)
                                setShowResultBox(1)
                                // Scroll to individual routes section
                                goIndividualRoutes()
                            } else {
                                alert('No possible route detected. Please ensure that each stop is reachable by road from the other locations on your list.')
                            }

                            setButtonContent('optimize route')

                        }).catch((error) => {
                            console.error('Error:', error);
                            setButtonContent('optimize route')
                            //alert('One or more entries are invalid, please check and try again.')
                            setMainMessage('One or more entries are invalid, please check and try again')
                            setIsAction(false)
                            setActionMessage('')
                            setNavigate('')
                            setModalOpen(true);
                        })
                    }

                } else {

                    setButtonContent('optimize route')
                    setIsAction(true)
                    setNavigate('/pricing')
                    setModalOpen(true);
                    if (subStatus == 'canceled') {
                        setMainMessage('Payment failed following free trial. We\'ve canceled your subscription.')
                        setActionMessage('Retry')
                    } else {
                        setMainMessage('Must be a plus member to use this feature!')
                        setActionMessage('Try for Free')
                    }
                }
            }
        } else if (googleUrlContent.length > 0) {
            //alert('Once done entering a google maps url, please hit the Enter or Return button')
            setMainMessage('Once done entering a google maps url, please hit the Enter or Return button')
            setIsAction(false)
            setActionMessage('')
            setNavigate('')
            setModalOpen(true);
        } else if (startLocationContent.length === 0 || endLocationContent.length === 0) {
            //alert('Please ensure that Start Location and End Location are filled')
            setMainMessage('Please ensure that Start Location and End Location are filled')
            setIsAction(false)
            setActionMessage('')
            setNavigate('')
            setModalOpen(true);
        } else if (enteredStops[0].length === 0) {
            //alert('Please ensure that at least 1 stop is entered.')
            setMainMessage('Route already optimized, enter more stops to explore our optimization feature!')
            setIsAction(false)
            setActionMessage('')
            setNavigate('')
            setModalOpen(true);
        }

    }

    async function goIndividualRoutes() {
        // scroll to detailed directions
        const element = document.getElementById('box-individual-routes-gap'); 
        window.scrollTo({
            top:element.offsetTop,
            behavior:"smooth"
        });
        
        let eligible = await checkFeedbackStatus(currentUser)
        if (eligible) {
            setFeedbackModalOpen(true)
        }
    }

    function updateEnteredStops(optimizedOrder) {
        let newEnteredStops = []
        for (let i = 1; i < optimizedOrder.length-1; i++) {
            newEnteredStops.push(optimizedOrder[i].address)
        }
        newEnteredStops.push('')

        setEnteredStops(newEnteredStops)
    }

    function clearAddresses() {
        // having issues with removing everything on the map so temp refresh
        // window.location.reload();

        if (showResultBox === 1) {
            setShowResultBox(2)
        }
        let stoplist = ['','']

        setEnteredStops(stoplist)
        setStartLocationContent('')
        setEndLocationContent('')
        setGoogleUrlContent('')

        let tempAddressCoordinateList = {
            start: {name: "", coordinates: [null, null]},
            stops: [],
            end: {name: "", coordinates: [null, null]}
        }
        setAddressCoordinateList(tempAddressCoordinateList)
        setFullAddressList([])
    }

    /* JSX REACTIVE CODE */

    let loader = (
        <div class="loading-container currLocation">
            <div class="loader">
                <div class="loading-circle"></div>
            </div>
        </div>
    )
    let symbol = (
        <div className="currLocation-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#00b28b" d="M14.49,10.86a3.09,3.09,0,1,0-5,0,4.67,4.67,0,0,0-1.12,1A1,1,0,1,0,10,13.12a2.62,2.62,0,0,1,2.05-1h0a2.62,2.62,0,0,1,2.05,1,1,1,0,0,0,.78.37,1,1,0,0,0,.78-1.62A4.67,4.67,0,0,0,14.49,10.86ZM12,10.13h0A1.09,1.09,0,1,1,13.09,9,1.09,1.09,0,0,1,12,10.13Zm8.46-.5A8.5,8.5,0,0,0,7.3,3.36,8.56,8.56,0,0,0,3.54,9.63,8.46,8.46,0,0,0,6,16.46l5.3,5.31a1,1,0,0,0,1.42,0L18,16.46A8.46,8.46,0,0,0,20.46,9.63ZM16.6,15.05,12,19.65l-4.6-4.6A6.49,6.49,0,0,1,5.53,9.83,6.57,6.57,0,0,1,8.42,5a6.47,6.47,0,0,1,7.16,0,6.57,6.57,0,0,1,2.89,4.81A6.49,6.49,0,0,1,16.6,15.05Z"/></svg>
        </div>
    )
    let currStartLocationContent = (
        <div></div>
    )
    if (startLocationLoading) {
        currStartLocationContent = loader
    } else {
        currStartLocationContent = symbol
    }

    let currEndLocationContent = (
        <div></div>
    )
    if (endLocationLoading) {
        currEndLocationContent = loader
    } else {
        currEndLocationContent = symbol
    }

    let buttonDisplay = ( <div></div>)
    if (buttonContent === 'optimize route') {
        buttonDisplay = <h1 id="optimize-text">{buttonContent}</h1>
    } else {
        buttonDisplay = (
            <div class="loading-container submit">
                <div class="loader">
                    <div class="loading-circle"></div>
                </div>
            </div>
        )
    }
    return (
        <div>
            {/* remove when done */}
            {feedbackModalOpen && 
            <FeedbackModal 
                setFeedbackModalOpen={setFeedbackModalOpen}
            ></FeedbackModal>}
            {modalOpen && 
            <Modal 
                setOpenModal={setModalOpen} 
                mainMessage={mainMessage}
                isAction={isAction}
                actionMessage={actionMessage}
                navigate={navigate}
            ></Modal>}
            { listModalOpen &&
                <ListModal
                    setListModalOpen={setListModalOpen}
                    setButtonTextColors={setButtonTextColors}
                    setButtonBGColors={setButtonBGColors}
                    setImportType={setImportType}
                    submitList={submitList}
                ></ListModal>
            }
            { importModalOpen &&
                <ImportModal
                    setImportModalOpen={setImportModalOpen}
                    setButtonTextColors={setButtonTextColors}
                    setButtonBGColors={setButtonBGColors}
                    setImportType={setImportType}
                    submitList={submitList}
                ></ImportModal>
            }
            {props.openEmailModal && 
                <EmailModal 
                    setOpenEmailModal={props.setOpenEmailModal} 
                    email={currentUser.email}
                    directionsData={props.directionsData}
                    routeId={props.routeId}
                ></EmailModal>
            }
            {splitUrlModalOpen &&
                <SplitUrlModal
                    setOpenModal={setSplitUrlModalOpen}
                    directionsData={props.directionsData}
                    navApp={'google'}
                ></SplitUrlModal>
            }
            <Header></Header>
            <div className="buffer-100"></div>
            <div className="main-box">
                <div className="address-form">
                    <ModeBox
                        mode={mode}
                        setMode={setMode}
                        setShowResultBox={setShowResultBox}
                        showResultBox={showResultBox}
                    ></ModeBox>
                    <div className="flex justify-center my-4">
                        <div className="relative w-4/5">
                            <Autocomplete
                                class="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm hover:bg-gray-100"
                                idName="startLocation"
                                placeholder="Start Location"
                                content={startLocationContent}
                                contentFunction={setStartLocationContent}
                                handleChange={handleStartLocationChange}
                            ></Autocomplete>
                            <button className="absolute inset-0 left-auto">
                                <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                                <div id="currLocation-startInput" onClick={currentLocation}>
                                    {startLocationLoading ? (
                                        <div class="w-7 h-7 mx-2">
                                            <div className="loading-container-new">
                                                <div class="loader">
                                                    <div class="loading-circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <svg className="w-7 h-7 mx-2" viewBox="0 0 26 24"><path fill="#00b28b" d="M14.49,10.86a3.09,3.09,0,1,0-5,0,4.67,4.67,0,0,0-1.12,1A1,1,0,1,0,10,13.12a2.62,2.62,0,0,1,2.05-1h0a2.62,2.62,0,0,1,2.05,1,1,1,0,0,0,.78.37,1,1,0,0,0,.78-1.62A4.67,4.67,0,0,0,14.49,10.86ZM12,10.13h0A1.09,1.09,0,1,1,13.09,9,1.09,1.09,0,0,1,12,10.13Zm8.46-.5A8.5,8.5,0,0,0,7.3,3.36,8.56,8.56,0,0,0,3.54,9.63,8.46,8.46,0,0,0,6,16.46l5.3,5.31a1,1,0,0,0,1.42,0L18,16.46A8.46,8.46,0,0,0,20.46,9.63ZM16.6,15.05,12,19.65l-4.6-4.6A6.49,6.49,0,0,1,5.53,9.83,6.57,6.57,0,0,1,8.42,5a6.47,6.47,0,0,1,7.16,0,6.57,6.57,0,0,1,2.89,4.81A6.49,6.49,0,0,1,16.6,15.05Z"/></svg>
                                    )}
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex justify-center my-4">
                        <div className="relative w-4/5">
                            <Autocomplete
                                class="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm hover:bg-gray-100"
                                idName="endLocation"
                                placeholder="End Location"
                                content={endLocationContent}
                                contentFunction={setEndLocationContent}
                                handleChange={handleEndLocationChange}
                            ></Autocomplete>
                            <button className="absolute inset-0 left-auto">
                                <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                                <div id="currLocation-endInput" onClick={currentLocation}>
                                    {endLocationLoading ? (
                                        <div class="w-7 h-7 mx-2">
                                            <div className="loading-container-new">
                                                <div class="loader">
                                                    <div class="loading-circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <svg className="w-7 h-7 mx-2" viewBox="0 0 26 24"><path fill="#00b28b" d="M14.49,10.86a3.09,3.09,0,1,0-5,0,4.67,4.67,0,0,0-1.12,1A1,1,0,1,0,10,13.12a2.62,2.62,0,0,1,2.05-1h0a2.62,2.62,0,0,1,2.05,1,1,1,0,0,0,.78.37,1,1,0,0,0,.78-1.62A4.67,4.67,0,0,0,14.49,10.86ZM12,10.13h0A1.09,1.09,0,1,1,13.09,9,1.09,1.09,0,0,1,12,10.13Zm8.46-.5A8.5,8.5,0,0,0,7.3,3.36,8.56,8.56,0,0,0,3.54,9.63,8.46,8.46,0,0,0,6,16.46l5.3,5.31a1,1,0,0,0,1.42,0L18,16.46A8.46,8.46,0,0,0,20.46,9.63ZM16.6,15.05,12,19.65l-4.6-4.6A6.49,6.49,0,0,1,5.53,9.83,6.57,6.57,0,0,1,8.42,5a6.47,6.47,0,0,1,7.16,0,6.57,6.57,0,0,1,2.89,4.81A6.49,6.49,0,0,1,16.6,15.05Z"/></svg>
                                    )}
                                </div>
                            </button>
                        </div>
                    </div>
                    <ImportMethods
                        importType={importType}
                        setImportType={setImportType}
                        setShowResultBox={setShowResultBox}
                        showResultBox={showResultBox}
                        setListModalOpen={setListModalOpen}
                        setImportModalOpen={setImportModalOpen}
                        setButtonTextColors={setButtonTextColors}
                        buttonTextColors={buttonTextColors}
                        setButtonBGColors={setButtonBGColors}
                        buttonBGColors={buttonBGColors}
                    ></ImportMethods>
                    {
                        importType === 'url' && 

                        <div className="flex justify-center my-4">
                            <div className="relative w-4/5">
                                <input id="mapsUrl" type="text" ref={googleUrlInputRef} className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm hover:bg-gray-100" placeholder="Google Maps URL" value={googleUrlContent} onKeyDown={handleKeyDown} onChange={handleGoogleUrlChange}/>
                                <button className="absolute inset-0 left-auto" onClick={submitUrl}>
                                    <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                                    <svg className="w-7 h-7 mx-2" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="arrow-right"><path fill="#338CF5" d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"></path></svg>
                                </button>
                            </div>
                        </div>
                    }

                    <div className="stops-container">
                        <div className="flex">
                            <h4 className="stops-text">Stops</h4>
                            <h5 className="stops-text-message text-blue-500 bg-blue-200">UP TO 100</h5>
                            <h5 className="clear-text-message cursor-pointer" onClick={clearAddresses}>CLEAR ALL</h5>
                        </div>
                        {
                            enteredStops.map((item, i) =>
                                <div key={i} className="input-container stops">
                                    <div className="stopCount-container">
                                        {(i < 9) ? (
                                            <p className="stopCount-text">{'0'+(i+1)}</p>
                                        ) : (
                                            <p className="stopCount-text">{i+1}</p>
                                        )}
                                    </div>
                                    <div className="relative">
                                        <StopsAutocomplete
                                            class="form-input w-11/12 text-gray-800 px-3 py-2 pr-12 text-sm float-right hover:bg-gray-100"
                                            placeholder="Add Destination"
                                            content={item}
                                            contentFunction={setEnteredStops}
                                            handleChange={e => handleStopChange(e, i)}
                                            handleSelect={e => handleStopSelect(e, i)}
                                        ></StopsAutocomplete>
                                        <button className="absolute inset-0 left-auto">
                                            <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                                            <div className="deleteLocationButton" onClick={() => handleDeleteStop(i)}>x</div>
                                            {/* <svg style={{ marginTop: '10px' }} onClick={() => handleDeleteStop(i)} className="w-6 h-6 cursor-pointer mr-2 text-gray-500 hover:text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="times"><path fill="currentColor" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg> */}
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                        
                        <div className="buffer-200"></div>
                    </div>

                    <div className="optimize-button" onClick={optimizeRoute}>
                        {buttonDisplay}
                    </div>
                </div>
                <div className="map-container">
                    <MapInterface
                        addressCoordinateList={addressCoordinateList}
                        stopList={enteredStops}
                        startContent={startLocationContent}
                        endContent={endLocationContent}
                        fullAddressList={fullAddressList}
                        newRouteOrder={newRouteOrder}
                        mode={mode}
                    ></MapInterface>
                </div>
            </div>
            <ResultsBox
                showResultBox={showResultBox}
                directionsData={props.directionsData}
                setOpenEmailModal={props.setOpenEmailModal}
                setSplitUrlModalOpen={setSplitUrlModalOpen}
            ></ResultsBox>
        </div>
    );
}

export default Box;

