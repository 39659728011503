import React, { useEffect, useState } from "react";
import Input from "../components/input";
import { text } from "body-parser";




const AddDrivers = (props) => {

  const [currDriverCount, setCurrDriverCount] = useState(1)
  
  const getValue = (index, field) => {

    if (props.form.drivers && props.form.drivers[index]) {
      return props.form.drivers[index][field]
    }

    return null
  };

  function nextDriver() {
    if (props.form && props.form.drivers) {
      let driverFilledInfo = props.form.drivers
      let isFilled = true

      // loop through answers
      for (let i = 0; i < driverFilledInfo.length; i++) {
        if (driverFilledInfo[i].name == '' || driverFilledInfo[i].email == '') {
          isFilled = false
        }
      }

      // check last driver input
      if (!driverFilledInfo[currDriverCount-1]) {
        isFilled = false
      }

      if (isFilled) {
        setCurrDriverCount(currDriverCount+1)
      } else {
        alert('Be sure to fill in both the name and the email of each driver.')
      }
    } else {
      alert('Be sure to fill in both the name and the email of each driver.')
    }
  }

  const createDriverInputs = () => {
    const elementArray = [];
    const numberOfDrivers = props.numberOfDrivers;

    for (let i = 0; i < currDriverCount; i++) {
      elementArray.push(
        <div className="">
          {/* <div className="title text-md font-medium text-black-500 px-4">
            <h5>Driver {i + 1}</h5>
          </div> */}
          <div className="flex flex-row space-x-1 w-full">
            <div className="w-4 h-4 rounded-full" style={{ backgroundColor: props.colors[i], marginTop: 46 }}></div>
            <div className="flex-1">
              <Input
                label={`Driver ${i+1}/${numberOfDrivers}`}
                placeholder={"Enter driver name"}
                defaultValue={getValue(i, "name")}
                onBlur={(e) => {
                  const name = e.target.value;
                  props.saveDriverData(i, "name", name)
                }}
              />
            </div>
            <div className="flex-1" style={{ marginTop: 18 }}>
            <Input
              type="email"  // This ensures basic HTML email validation
              placeholder={"Enter driver email"}
              defaultValue={getValue(i, "email")}
              onBlur={(e) => {
                const email = e.target.value;

                // Regex for validating email addresses
                const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

                if (isValidEmail) {
                  props.saveDriverData(i, "email", email);
                } else {
                  alert("Please enter a valid email address");
                  // Optionally clear the input if the validation fails
                  e.target.value = '';
                }
              }}
            />
            </div>
            <div className="flex-1" style={{ marginTop: 0 }}>
            <Input
              type="tel"
              label={`*Optional*`}
              placeholder={"Enter driver phone #"}
              defaultValue={getValue(i, "phoneNum")}
              pattern="^\+?[0-9\s\-\(\)]{7,15}$" // Regex for international numbers (allowing +, spaces, dashes, and parentheses)
              onBlur={(e) => {
                const phoneNum = e.target.value;

                // Basic regex to match international phone numbers (7 to 15 digits, allowing +, spaces, dashes, and parentheses)
                const isValidPhone = /^\+?[0-9\s\-\(\)]{7,15}$/.test(phoneNum);

                if (isValidPhone) {
                  props.saveDriverData(i, "phoneNum", phoneNum);
                } else {
                  alert("Please enter a valid phone number");
                  // Optionally clear the input if the validation fails
                  e.target.value = '';
                }
              }}
            />


            </div>
          </div>
        </div>
      );
    }

    return elementArray;
  };

  return (
    <div className="px-4">
      <div className="title text-lg font-medium text-black-500 px-4">
        <h1>Add your drivers</h1>
        <label className="block text-gray-600 text-xs font-medium mb-1">
          Add the information of your drivers.
        </label>
        <div className="overflow-y-auto pt-2">
          {createDriverInputs()}
        </div>
        {(currDriverCount < props.numberOfDrivers) &&
          <div className="pl-9 pr-4">
            <div onClick={nextDriver} className="w-full cursor-pointer bg-white hover:bg-blue-100 py-2 rounded border border-dashed border-blue-500 flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" width="23" height="23" viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 176v160M336 256H176"/></svg>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default AddDrivers;
