import React, { useEffect, useState } from 'react';
import './SplitUrlModal.css'

function SplitUrlModal(props) {

    const [navLinks, setNavLinks] = useState([])
    const [message, setMessage] = useState(<p>Please navigate using the individual stop links below</p>)

    useEffect(() => {
        
        if (props.navApp == 'google') {
            setNavLinks(props.directionsData.splitUrls.google)
            setMessage(<p>Google Maps has a <span className="underline decoration-myteal-500">25-address limit</span>, so we've segmented your journey for you.</p>)
        } else if (props.navApp == 'apple') {
            setNavLinks(props.directionsData.splitUrls.apple)
            setMessage(<p>Apple Maps has a <span className="underline decoration-myteal-500">15-address limit</span>, so we've segmented your journey for you.</p>)
        }

    }, [])

    function openLink(url) {
        window.open(url)
    }


    return (
        <div className="splitModalBackground">
            <div className="splitModalContainer">
                <div className="splitCloseBtn">
                    <button
                        onClick={() => {
                            props.setOpenModal(false);
                        }}
                    >
                        x
                    </button>
                </div>

                <div className="md:text-xl mb-5 text-gray-600">
                    {message}
                </div>

                <div className="overflow-scroll">
                    {
                        navLinks.map((link, i) =>
                            <div key={i}>
                                <div className="items-center text-lg pt-4 rounded border transition duration-300 ease-in-out mb-3 mt-3 bg-white shadow-md border-gray-200 hover:shadow-lg">

                                    <div className="px-5 py-3">
                                        <div className="flex items-center mb-3">
                                            <div className="text-2xl :text-2xl font-bold leading-snug tracking-tight mb-1 mr-3">Route #{i+1}</div>
                                        </div>

                                        <div>
                                            <div className="flex">
                                                <div>
                                                    <svg fill="#00b28b" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512"><circle cx="256" cy="192" r="32"/><path d="M256 32c-88.22 0-160 68.65-160 153 0 40.17 18.31 93.59 54.42 158.78 29 52.34 62.55 99.67 80 123.22a31.75 31.75 0 0051.22 0c17.42-23.55 51-70.88 80-123.22C397.69 278.61 416 225.19 416 185c0-84.35-71.78-153-160-153zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z"/></svg>
                                                    <svg fill="#00b28b" className="ml-1" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512"><circle cx="256" cy="256" r="48"/><circle cx="256" cy="416" r="48"/><circle cx="256" cy="96" r="48"/></svg>
                                                    <svg fill="#00b28b" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512"><path d="M256 464c114.87 0 208-93.13 208-208S370.87 48 256 48 48 141.13 48 256s93.13 208 208 208zm-91.36-212.65a16 16 0 0122.63-.09L240 303.58V170a16 16 0 0132 0v133.58l52.73-52.32A16 16 0 11347.27 274l-80 79.39a16 16 0 01-22.54 0l-80-79.39a16 16 0 01-.09-22.65z"/></svg>
                                                    <svg fill="#00b28b" className="ml-1" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512"><circle cx="256" cy="256" r="48"/><circle cx="256" cy="416" r="48"/><circle cx="256" cy="96" r="48"/></svg>
                                                    <svg fill="#00b28b" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512"><path d="M80 480a16 16 0 01-16-16V68.13a24 24 0 0111.9-20.72C88 40.38 112.38 32 160 32c37.21 0 78.83 14.71 115.55 27.68C305.12 70.13 333.05 80 352 80a183.84 183.84 0 0071-14.5 18 18 0 0125 16.58v219.36a20 20 0 01-12 18.31c-8.71 3.81-40.51 16.25-84 16.25-24.14 0-54.38-7.14-86.39-14.71C229.63 312.79 192.43 304 160 304c-36.87 0-55.74 5.58-64 9.11V464a16 16 0 01-16 16z"/></svg>
                                                </div>
                                                <div className="pl-3 pr-3 w-full">
                                                    <div class="md:text-lg mb-5 text-gray-500 leading-snug tracking-tight h-6 overflow-hidden truncate">{link.start}</div>
                                                    <div class="md:text-lg mb-4 text-gray-500 italic leading-snug tracking-tight h-6 overflow-hidden truncate">{link.length - 2} {link.length - 2 === 1 ? 'stop' : 'stops'}</div>
                                                    <div class="md:text-lg text-gray-500 leading-snug tracking-tight h-6 overflow-hidden truncate">{link.end}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div onClick={() => openLink(link.url)} class="flex items-center justify-center text-lg mx-auto w-full mt-3 bg-myteal-500 text-gray-600 rounded-b hover:bg-myteal-600 cursor-pointer">
                                        <p class="text-white py-2 font-bold">Start</p>
                                        <svg width="12" height="12" class="fill-white flex-shrink-0 ml-2 -mr-1" fill="white" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                                        </svg>
                                    </div>

                                </div>
                            </div>
                        )
                    }
                    
                    <div className="h-10"></div>
                </div>

            </div>
        </div>
  );
}

export default SplitUrlModal;