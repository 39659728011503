import React, { useContext, useEffect } from 'react'

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import ExtensionInstructions from '../partials/ExtensionInstructions';
import AppInstructions from '../partials/AppInstructions'
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Testimonials from '../partials/Testimonials';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import Values from '../partials/Values'
import RoutoraWebDemo from '../partials/RoutoraWebDemo';
import WebGrid from '../mycomponents/RoutoraWebVisuals/WebGrid';
import RoutoraOnWeb from '../partials/RoutoraOnWeb'

import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../src/AuthContext.js'


function Home() {

  const { utm } = useParams();
  const history = useHistory();
  const { setUTMCode, utmHit } = useContext(AuthContext);

  useEffect(() => {

    if (utm) {
      console.log('UTM -> ' + utm)
      setUTMCode(utm)
      utmHit(utm)
      history.push('/')
    }
    
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroHome />
        <RoutoraWebDemo
            isWebIntro={false}
        ></RoutoraWebDemo>
        <RoutoraOnWeb
            isHomeWeb={true}
        ></RoutoraOnWeb>
        <WebGrid
            isHomeWeb={true}
        ></WebGrid>
        <AppInstructions />
        <Testimonials />
        <div className="mt-28">
          <Newsletter />
        </div>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;