import CompanyDetails from "../company_details";
import DepotDetails from "../depot_details";
import StartTrial from "../trial_details";
import AddDrivers from "../add_drivers";
import RouturaLogo from "../components/logo";
import Step from "../components/sidebar/step";
import { AuthContext } from "../../../AuthContext";
import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FormInstance } from "twilio/lib/rest/verify/v2/form";

const COLORS = [
  '#8377fd', '#55caf5', '#f55578', '#ffaf00', '#01a240', '#4426d9', '#d926d9', '#d92644', '#d99d26', '#80d926',
  '#996633', '#26bbd9', '#2626d9', '#bb26d9', '#d92662', '#d98026', '#9dd926', '#26d944', '#26d9d9', '#2644d9',
  '#808080', '#d92680', '#d96226', '#bbd926', '#26d926', '#26d9bb', '#2662d9', '#7f26d9', '#d9269d', '#d94426',
  '#664c33', '#44d926', '#26d99d', '#267fd9', '#6226d9', '#d926bb', '#d92626', '#d9bb26', '#62d926', '#26d980',
  '#269dd9', '#4426d9', '#d926d9', '#d92644', '#d99d26', '#80d926', '#26d962', '#26bbd9', '#2626d9', '#bb26d9',
  '#d92662', '#d98026', '#9dd926', '#26d944', '#26d9d9', '#2644d9', '#9d26d9', '#d92680', '#d96226', '#bbd926',
  '#26d926', '#26d9bb', '#2662d9', '#7f26d9', '#d9269d', '#d94426', '#d9d926', '#44d926', '#26d99d', '#267fd9',
  '#6226d9', '#d926bb', '#d92626', '#d9bb26', '#62d926', '#26d980', '#269dd9', '#4426d9', '#d926d9', '#d92644',
  '#d99d26', '#80d926', '#26d962', '#26bbd9', '#2626d9', '#bb26d9', '#d92662', '#d98026', '#9dd926', '#26d944',
  '#26d9d9', '#2644d9', '#9d26d9', '#d92680', '#d96226', '#bbd926', '#26d926', '#26d9bb', '#2662d9', '#7f26d9'
];

const Onboarding = () => {
  const COMPANY_DETAILS = "Company Details";
  const DEPOT_DETAILS = "Depot Details";
  const START_TRIAL = "Start Trial";
  const ADD_DRIVERS = "Add Drivers";

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");
  const numberOfDrivers = searchParams.get("numberOfDrivers");

  const { currentUser, createTeamProfile, updateCompanyProfile, updateDepotProfile, getTeamProfile, updateDriverInfo } = useContext(AuthContext);

  // temporary, this should be done in AuthContext probably after signing up for teams
  // useEffect(() => {
  //   if (currentUser) {
  //     createTeamProfile(currentUser.email)
  //   }
  // }, []);

  const [currentStep, setCurrentStep] = useState(
    page?.replace("_", " ") ?? COMPANY_DETAILS
  );
  
  // Get this data from firebase as page loads
  const [form, setForm] = useState({
    companyName: "",
    industry: "",
    role: "",
    numDrivers: null,
    depotName: "",
    startAddress: null,
    startTime: "",
    endAddress: null,
    averageStopTime: null,
    drivers: null
  });
  // Fetch user data from DB and update formData state
  useEffect(() => {
    // API call to get user DB data
    const fetchData = async (email) => {

      let userDbData = await getTeamProfile(email)

      if (userDbData) {
        const dbData = {
          companyName: userDbData.companyName || "",
          industry: userDbData.industry || "",
          role: userDbData.role || "",
          numDrivers: userDbData.numDrivers === 0 ? null : userDbData.numDrivers,
          depotName: userDbData.depotName || "",
          startAddress: userDbData.startAddress,
          startTime: userDbData.startTime || "",
          endAddress: userDbData.endAddress,
          averageStopTime: userDbData.averageStopTime === 0 ? null : userDbData.averageStopTime,
          drivers: userDbData.drivers.length === 0 ? null : userDbData.drivers
        };

        console.log(dbData)
        setForm(dbData);
      }
    };

    if (currentUser) {
      fetchData(currentUser.email);
    }
  }, [currentUser]);

  if (!currentUser) {
    return null;
  }

  const saveData = (field, data) => {
    let formData = {...form}
    formData[field] = data
    setForm(formData)
  }

  const saveDriverData = (index, field, data) => {
    let formData = { ...form }; // Create a shallow copy of the form object
  
    // Ensure drivers array exists
    if (!formData.drivers) {
      formData.drivers = []; // Initialize the drivers array if it's null or undefined
    }
  
    // Fill missing indices with default values if needed
    while (formData.drivers.length <= index) {
      formData.drivers.push({ name: "", email: "", phoneNum: "", colorCode: "" }); // Initialize with default values
    }
  
    // Update the specific field for the driver at the given index
    formData.drivers[index][field] = data;
    formData.drivers[index].colorCode = COLORS[index];
  
    console.log('Updated formData drivers:', formData.drivers);
  
    // Set the updated form data
    setForm(formData);
  };
  

  const STEPS = new Map([
    [
      COMPANY_DETAILS,
      {
        name: COMPANY_DETAILS,
        buttonName: "Company Details",
        component: (
          <CompanyDetails
            form={form}
            saveData={(field, data) => {
              saveData(field, data);
            }}
          />
        ),
      },
    ],
    [
      DEPOT_DETAILS,
      {
        name: DEPOT_DETAILS,
        buttonName: "Route Details",
        component: (
          <DepotDetails
            form={form}
            saveData={(field, data) => {
              saveData(field, data);
            }}
          />
        ),
      },
    ],
    [
      START_TRIAL,
      {
        name: START_TRIAL,
        component: (
          <StartTrial
            email={currentUser.email}
            name={currentUser.displayName}
            form={form}
            saveData={(field, data) => {
              saveData(field, data);
            }}
          />
        ),
      },
    ],
    [
      ADD_DRIVERS,
      {
        name: ADD_DRIVERS,
        buttonName: "Drivers",
        hidePreviousButton: true,
        component: (
          <AddDrivers
            form={form}
            numberOfDrivers={numberOfDrivers}
            colors={COLORS}
            saveDriverData={(index, field, data) => {
              saveDriverData(index, field, data);
            }}
          />
        ),
      },
    ],
  ]);

  const createSteps = () => {
    const steps = [];
    let stepNumber = 1;

    for (const [key, _value] of STEPS) {
      steps.push(
        <Step
          label={key.replace("_", " ")}
          stepNumber={stepNumber}
          isActive={currentStep === key}
        />
      );
      stepNumber++;
    }

    return steps;
  };

  const goToPreviousStep = () => {
    const keys = [...STEPS.keys()];
    const currentStepNumber = keys.indexOf(currentStep);

    if (currentStepNumber - 1 >= 0) {
      setCurrentStep(keys[currentStepNumber - 1]);
    }
  };

  const goToNextStep = async () => {

    // if current page is adding drivers
    if (currentStep === ADD_DRIVERS && form.drivers) {

      // lets fill in empty responses with Driver #
      let filledDriverInfo = []
      for (let i = 0; i < numberOfDrivers; i ++) {

        let driverInfo = { name: 'Driver #' + (i+1), email: "", phoneNum: "", colorCode: COLORS[i], id: (i+1) }

        // if index exists, else push another element
        if (form.drivers[i]) {
          if (form.drivers[i].name != '') {
            driverInfo.name = form.drivers[i].name
          }
          driverInfo.email = form.drivers[i].email
          driverInfo.phoneNum = form.drivers[i].phoneNum
          filledDriverInfo.push(driverInfo)
        } else {
          filledDriverInfo.push(driverInfo)
        }

      }

      console.log(filledDriverInfo)

      await updateDriverInfo(currentUser.email, filledDriverInfo)
      window.location.href = "/teams";
      return;
    }

    if (currentStep == COMPANY_DETAILS) {
      if ((!form.companyName || form.companyName == "") || 
          (!form.industry || form.industry == "") || 
          (!form.role || form.role == "") || 
          !form.numDrivers) {
        
        alert('One or more responses missing')
        return
      } else if (form.numDrivers <= 1) {
        alert('Invalid number of drivers. Must have more than 1 driver.')
        return
      }

      console.log('COMPANY_DETAILS ANSWERS')
      console.log(currentUser.email, form.companyName, form.industry, form.role, form.numDrivers)
      updateCompanyProfile(currentUser.email, form.companyName, form.industry, form.role, form.numDrivers)
    }

    if (currentStep == DEPOT_DETAILS) {
      if ((!form.depotName || form.depotName == "") || 
          !form.startAddress || 
          !form.endAddress) {
        
        alert('One or more responses missing')
        return
      } else if (form.averageStopTime < 0) {
        alert('Invalid average time at stop. Please enter a value greater than or equal to 0.')
        return
      } else if (!form.startTime) {
        saveData("startTime", "08:00")
      } else if (!form.averageStopTime) {
        saveData("averageStopTime", 15)
      }

      console.log('DEPOT_DETAILS ANSWERS')
      console.log(currentUser.email, form.depotName, form.startAddress, form.startTime || "08:00", form.endAddress, form.averageStopTime || 15)
      updateDepotProfile(currentUser.email, form.depotName, form.startAddress, form.startTime || "08:00", form.endAddress, form.averageStopTime || 15)
    }

    const keys = [...STEPS.keys()];
    const totalStepCount = keys.length;
    const currentStepNumber = keys.indexOf(currentStep);

    if (currentStepNumber < totalStepCount - 1) {
      setCurrentStep(keys[currentStepNumber + 1]);
    }
  };

  const isTrialStep = currentStep === START_TRIAL;

  return (
    <div className="container mx-auto h-screen" >
      <div className="grid grid-cols-6 w-full h-full">
        <div className="border-r-2 h-full pt-10">
          <RouturaLogo />
          <div className="flex flex-col space-y-5 mt-8 flex-grow">
            {createSteps()}
          </div>
        </div>
        <div className="col-span-5 h-full p-10 mt-3 flex flex-col justify-between">
          <div className="flex-grow">{STEPS.get(currentStep).component}</div>
          <div className="flex justify-start">
            <div className="ml-8">
              <div className="flex space-x-2">
                {STEPS.get(currentStep).hidePreviousButton === undefined ? (
                  <a
                    onClick={goToPreviousStep}
                    className="btn cursor-pointer text-myteal-500 bg-white hover:bg-gray-100 w-full mb-4 md:mb-0 py-2 border border-myteal-500"
                  >
                    Previous
                  </a>
                ) : null}

                {isTrialStep || (
                  <a
                    onClick={goToNextStep}
                    className="btn cursor-pointer text-white bg-myteal-500 hover:bg-myteal-600 w-full mb-4 md:mb-0 py-2 whitespace-nowrap"
                  >
                    Confirm {STEPS.get(currentStep).buttonName}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
