import { useState, useEffect } from "react";

function DriverRouteBreakdown(props) {
  const [routeInfo, setRouteInfo] = useState(null);
  
  function closeBreakdown() {
    props.setClickedDriver(0)
  }

  useEffect(() => {
    if (props.clickedDriver && props.driverRoutes) {
      // Match driver id to clickedDriver value, and retrieve route info
      let routeList = props.driverRoutes.routes;
      for (let i = 0; i < routeList.length; i++) {
        if (routeList[i].driverInfo.id == props.clickedDriver) {
          setRouteInfo(routeList[i]);
          break;
        }
      }
    }
  }, [props.clickedDriver, props.driverRoutes]);

  return (
    <div
      className="relative bg-gray-100 w-80 h-screen overflow-y-auto pt-12 pb-5 px-5 bg-white z-10 border-solid border-r border-gray-300"
      style={{ boxShadow: "inset 12px 0 10px -10px rgba(0, 0, 0, 0.1)" }}
    >
      <div className="driversRouteBreakdownCloseBtn z-20">
          <button className="text-gray-400" onClick={closeBreakdown}>
              <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/>
              </svg>
          </button>
      </div>

      {/* Tab to open/close the component */}
      {/* <div
        className="absolute top-1/2 transform -translate-y-1/2 text-white w-5 h-10 flex items-center justify-center cursor-pointer border-t border-b border-l border-solid border-gray-300 rounded-l-lg shadow-md"
        style={{
          right: '0px',  // Move the tab outside of the component
          zIndex: 9999
        }}
        onClick={closeBreakdown}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400" width="20" height="20" viewBox="0 0 512 512">
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
            d="M320 112L192 256l128 144"
          />
        </svg>
      </div> */}
      
      {routeInfo ? (
        <div className="mt-7">
          <ul className="">
            {routeInfo.routeOrder.map((stop, index) => (
              <li key={index} className="relative flex items-start">
                {/* Number Circle with Line */}
                <div className="flex flex-col items-center mr-4">
                    {/* Circle with stop number */}
                    <div
                        className="border text-xs text-white rounded-full w-7 h-7 flex items-center justify-center"
                        style={
                            index === 0 || index === routeInfo.routeOrder.length - 1
                              ? {
                                  borderColor: routeInfo.driverInfo.colorCode,
                                  backgroundColor: routeInfo.driverInfo.colorCode,
                                  color: '#FFFFFF',
                                }
                              : {
                                  borderColor: routeInfo.driverInfo.colorCode,
                                  color: routeInfo.driverInfo.colorCode,
                                }
                        }
                    >
                        {index === 0 ?
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                        : index === routeInfo.routeOrder.length - 1 ? 
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path d="M80 464V68.14a8 8 0 014-6.9C91.81 56.66 112.92 48 160 48c64 0 145 48 192 48a199.53 199.53 0 0077.23-15.77 2 2 0 012.77 1.85v219.36a4 4 0 01-2.39 3.65C421.37 308.7 392.33 320 352 320c-48 0-128-32-192-32s-80 16-80 16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/></svg>
                        : index < 10 ? 
                            `0${index}` : index
                        }
                    </div>

                    {/* Line connecting the stops (only if not the last stop) */}
                    {index !== routeInfo.routeOrder.length - 1 && (
                        <div 
                            className="h-10 border-l"
                            style={{
                                borderColor: routeInfo.driverInfo.colorCode,
                                color: routeInfo.driverInfo.colorCode,
                            }}
                        ></div>
                    )}
                </div>

                {/* Address and Info */}
                <div>
                  <div className="text-xs mt-1 text-gray-600">{stop.address}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>Loading route information...</div>
      )}
    </div>
  );
}

export default DriverRouteBreakdown;
