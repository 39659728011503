import React, { useEffect, useContext, useState } from 'react';
import Header from '../../partials/Header'

function AccComp() {

    const handleSendMessage = () => {
        // This opens the default messaging app with the number and pre-filled text "ND".
        try {
            window.location.href = "sms:4159657445&body=ND";
        } catch (error) {
            alert('An error has occured, please submit vote by texting ND to 415-965-7445. Thank you!!')
        }
    };

    // useEffect(() => {
    //     // This opens the default messaging app with the number and pre-filled text "ND".
    //     try {
    //         window.location.href = "sms:4159657445&body=ND";
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, [])

  return (
    <div >

        <Header></Header>
        <div className="buffer-100"></div>

        <div className="relative max-w-4xl mx-auto px-4 sm:px-6">
            <div className="pb-4 md:pb-7"></div>
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center">
                <h3 className="h3 mb-10 text-myblue-900">Voting ended, thank you for your consideration!</h3>
                <h2 className="h1 mb-4 text-myteal-500">Routora Wins People's Choice</h2>
            </div>

            {/* Call to Action */}
            {/* <div className="flex items-center justify-center mt-10">
                <div onClick={handleSendMessage} className="btn text-2xl py-5 px-10 bg-myteal-500 rounded-full text-white cursor-pointer hover:bg-myteal-600">
                    <p>Submit Vote</p>
                </div>
            </div>

            <div className="max-w-3xl mx-auto text-center mt-7">
                <p className="text-3xl text-gray-600">
                    Or Text <span className="font-bold">ND</span> to{' '}
                    <a href="sms:4159657445" className="font-bold underline text-gray-600 hover:text-gray-800">
                        415-965-7445
                    </a>
                </p>
            </div> */}

            {/* Image at the bottom */}
            <div className="mt-10 w-full">
                <img src="https://launchpad.syr.edu/wp-content/uploads/2018/11/ACC-InVenture-Prize.jpg" alt="ACC InVenture Prize" className="w-full object-cover" />
            </div>


        </div>
        
        

    </div>
  );
}

export default AccComp;