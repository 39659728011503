// export default [
//     {
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"
//             },
//             {
//                 "color": "#f49f53"
//             }
//         ]
//     },
//     {
//         "featureType": "landscape",
//         "stylers": [
//             {
//                 "color": "#f9ddc5"
//             },
//             {
//                 "lightness": -7
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "stylers": [
//             {
//                 "color": "#813033"
//             },
//             {
//                 "lightness": 43
//             }
//         ]
//     },
//     {
//         "featureType": "poi.business",
//         "stylers": [
//             {
//                 "color": "#645c20"
//             },
//             {
//                 "lightness": 38
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "stylers": [
//             {
//                 "color": "#1994bf"
//             },
//             {
//                 "saturation": -69
//             },
//             {
//                 "gamma": 0.99
//             },
//             {
//                 "lightness": 43
//             }
//         ]
//     },
//     {
//         "featureType": "road.local",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#f19f53"
//             },
//             {
//                 "weight": 1.3
//             },
//             {
//                 "visibility": "on"
//             },
//             {
//                 "lightness": 16
//             }
//         ]
//     },
//     {
//         "featureType": "poi.business"
//     },
//     {
//         "featureType": "poi.park",
//         "stylers": [
//             {
//                 "color": "#645c20"
//             },
//             {
//                 "lightness": 39
//             }
//         ]
//     },
//     {
//         "featureType": "poi.school",
//         "stylers": [
//             {
//                 "color": "#a95521"
//             },
//             {
//                 "lightness": 35
//             }
//         ]
//     },
//     {},
//     {
//         "featureType": "poi.medical",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#813033"
//             },
//             {
//                 "lightness": 38
//             },
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {},
//     {},
//     {},
//     {},
//     {},
//     {},
//     {},
//     {},
//     {},
//     {},
//     {},
//     {
//         "elementType": "labels"
//     },
//     {
//         "featureType": "poi.sports_complex",
//         "stylers": [
//             {
//                 "color": "#9e5916"
//             },
//             {
//                 "lightness": 32
//             }
//         ]
//     },
//     {},
//     {
//         "featureType": "poi.government",
//         "stylers": [
//             {
//                 "color": "#9e5916"
//             },
//             {
//                 "lightness": 46
//             }
//         ]
//     },
//     {
//         "featureType": "transit.station",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "transit.line",
//         "stylers": [
//             {
//                 "color": "#813033"
//             },
//             {
//                 "lightness": 22
//             }
//         ]
//     },
//     {
//         "featureType": "transit",
//         "stylers": [
//             {
//                 "lightness": 38
//             }
//         ]
//     },
//     {
//         "featureType": "road.local",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "color": "#f19f53"
//             },
//             {
//                 "lightness": -10
//             }
//         ]
//     },
//     {},
//     {},
//     {}
// ]



// export default [
//     {
//         "featureType": "all",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "color": "#f2f2f2"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "color": "#686868"
//             },
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "landscape",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "color": "#f0f0f0"
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.park",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.park",
//         "elementType": "labels.icon",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "saturation": -100
//             },
//             {
//                 "lightness": 45
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "lightness": "-22"
//             },
//             {
//                 "visibility": "on"
//             },
//             {
//                 "color": "#b4b4b4"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "saturation": "-51"
//             },
//             {
//                 "lightness": "11"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "saturation": "3"
//             },
//             {
//                 "lightness": "-56"
//             },
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "lightness": "-52"
//             },
//             {
//                 "color": "#9094a0"
//             },
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "labels.text.stroke",
//         "stylers": [
//             {
//                 "weight": "6.13"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "labels.icon",
//         "stylers": [
//             {
//                 "weight": "1.24"
//             },
//             {
//                 "saturation": "-100"
//             },
//             {
//                 "lightness": "-10"
//             },
//             {
//                 "gamma": "0.94"
//             },
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway.controlled_access",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"
//             },
//             {
//                 "color": "#a4b4b4"
//             },
//             {
//                 "weight": "5.40"
//             },
//             {
//                 "lightness": "7"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway.controlled_access",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "color": "#231f1f"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway.controlled_access",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "color": "#595151"
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "lightness": "-16"
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "on"
//             },
//             {
//                 "color": "#d7d7d7"
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "color": "#282626"
//             },
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "saturation": "-41"
//             },
//             {
//                 "lightness": "-41"
//             },
//             {
//                 "color": "#2a4592"
//             },
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "labels.text.stroke",
//         "stylers": [
//             {
//                 "weight": "1.10"
//             },
//             {
//                 "color": "#ffffff"
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "labels.icon",
//         "stylers": [
//             {
//                 "visibility": "on"
//             }
//         ]
//     },
//     {
//         "featureType": "road.local",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "lightness": "-16"
//             },
//             {
//                 "weight": "0.72"
//             }
//         ]
//     },
//     {
//         "featureType": "road.local",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "lightness": "-37"
//             },
//             {
//                 "color": "#2a4592"
//             }
//         ]
//     },
//     {
//         "featureType": "transit",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "transit.line",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "visibility": "off"
//             },
//             {
//                 "color": "#eeed6a"
//             }
//         ]
//     },
//     {
//         "featureType": "transit.line",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "visibility": "off"
//             },
//             {
//                 "color": "#0a0808"
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "color": "#b7e4f4"
//             },
//             {
//                 "visibility": "on"
//             }
//         ]
//     }
// ]

export default [
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#e0efef"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#1900ff"
            },
            {
                "color": "#c0e8e8"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "lightness": 100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": 700
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#7dcdcd"
            }
        ]
    }
]



// export default [
//     {
//         "featureType": "all",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "color": "#878787"
//             }
//         ]
//     },
//     {
//         "featureType": "all",
//         "elementType": "labels.text.stroke",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "landscape",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "color": "#f9f5ed"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "color": "#f5f5f5"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "color": "#c9c9c9"
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "color": "#aee0f4"
//             }
//         ]
//     }
// ]