import React, { useState, useContext } from 'react';
import Axios from 'axios';

import Header from '../../partials/Header';
import { AuthContext } from '../../AuthContext'
import { useHistory } from 'react-router-dom';
import CancelModalConfirmation from './CancelModalConfirmation'

import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

function CancelSubForm() {

    const history = useHistory();
    const { currentUser, currentUserTier, updateUserTier, getSubId, updateSubId, addFeedback } = useContext(AuthContext);

    const [questionNum, setQuestionNum] = useState(1)

    const [modalOpen, setModalOpen] = useState(false);
    // Modal states
    const [mainMessage, setMainMessage] = useState('')
    const [isAction, setIsAction] = useState(false)
    const [actionMessage, setActionMessage] = useState('')
    const [navigate, setNavigate] = useState('')

    const [deleteSubButtonContent, setDeleteSubContent] = useState('Delete Subscription')

    function backClicked() {
        setQuestionNum(questionNum-1)
    }

    // QUESTION 1
    const [selectedItem1, setSelectedItem1] = useState(null);
    const [options1, setOptions1] = useState(['Just exploring', 'Change in needs', 'Technical issues', 'Lack of certain features', 'Cost', 'Other'])
    // Function to handle click event
    const handleItemClick1 = (itemId) => {
        setSelectedItem1(itemId);
        setTimeout(() => {
            setQuestionNum(2)
        }, 250); // 250 milliseconds delay
    };
    // Function to determine the CSS class for each item
    const getItemClass1 = (itemId) => {
        return itemId === selectedItem1 ? "border border-myblue-900 text-white bg-myblue-900 cursor-pointer rounded-lg p-4" : "border border-myblue-900 text-myblue-900 hover:bg-myblue-50 cursor-pointer rounded-lg p-4";
    };


    // QUESTION 2
    const [reason, setReason] = useState('');

    // Function to handle input change
    const handleInputChange2 = (e) => {
        setReason(e.target.value);
    };

    let activateButton = false
    if (reason.length > 0) {
        activateButton = true
    }


    
    async function cancelSub() {

        setDeleteSubContent('')
        const subId = await getSubId(currentUser)
        console.log(subId)
        // testers dont have a sub id, so just update tier and return
        if (subId == null) {
            updateUserTier(currentUser, 0)

            setMainMessage('Your subscription has been cancelled')
            setIsAction(true)
            setActionMessage('Done')
            setNavigate('/profile')
            setModalOpen(true);
            
            setDeleteSubContent('Delete Subscription')
            return
        }
        // otherwise delete the subscription
        const res = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/deletesub`, {'subId': subId});
        const {status} = res.data;
        if (status === 'canceled') {
            console.log('cancelation success')
            updateUserTier(currentUser, 0)
            updateSubId(currentUser, null)

            // record responses
            let responses = {reason: options1[selectedItem1], feedback: reason}
            console.log(responses)
            await addFeedback(currentUser, responses)

            // amplitude tracking
            const eventProperties = {
                reason: responses.reason,
                feedback: responses.feedback
            };
            amplitude.track('Canceled Subscription', eventProperties);

            setMainMessage('Your subscription has been canceled')
            setIsAction(true)
            setActionMessage('Done')
            setNavigate('/profile')
            setModalOpen(true);
        } else {
            console.log('cancelation ERROR')
            setMainMessage('Your subscription has been canceled')
            setIsAction(true)
            setActionMessage('Done')
            setNavigate('/profile')
            setModalOpen(true);
        }

        setDeleteSubContent('Delete Subscription')
    }
    
    let deleteButtonDisplay = (<div></div>)
    if (deleteSubButtonContent === 'Delete Subscription') {
        deleteButtonDisplay = (
            <div onClick={cancelSub} className="text-center font-bold border border-red-400 text-white bg-red-400 hover:bg-red-500 cursor-pointer rounded-lg p-3">
                <span className="">Cancel my Subscription</span>
            </div>
        )
    } else {
        deleteButtonDisplay = (
          <div class="loading-container currLocation px-20">
              <div class="loader">
                  <div class="loading-circle"></div>
              </div>
          </div>
        )
    }

  return (
      
    <div>
        {modalOpen && 
        <CancelModalConfirmation 
            setOpenModal={setModalOpen} 
            mainMessage={mainMessage}
            isAction={isAction}
            actionMessage={actionMessage}
            navigate={navigate}
        ></CancelModalConfirmation>}

        <Header></Header>
        <div className="h-8"></div>
        <div className="h-screen flex justify-center mt-32" >

            <div className="max-w-md w-full mx-6">
                
                <h3 className="h3 text-myblue-900">We're sad to see you go! Your feedback matters</h3>

                <div className="flex w-full my-2">
                    <div className="w-full border-t-2 border-myblue-900 my-3"></div>
                    {questionNum >= 2 ? <div className="w-full border-t-2 border-myblue-900 my-3"></div> : <div className="w-full border-t-2 border-gray-300 my-3"></div>}
                </div>

                {questionNum == 1 && 
                    <div id="question1" className="mt-5">
                        <p className="text-gray-600">What is the primary reason for canceling your subscription?</p>

                        <div className="grid grid-cols-2 mt-3 gap-4">
                            {/* Example items (replace with your actual items) */}
                            {[0, 1, 2, 3, 4, 5].map((itemId) => (
                                <div
                                    key={itemId}
                                    className={getItemClass1(itemId)}
                                    onClick={() => handleItemClick1(itemId)}
                                >
                                    {options1[itemId]}
                                </div>
                            ))}
                        </div>
                    </div>
                }

                {questionNum == 2 && 
                    <div id="question2" className="mt-5">
                        <p className="text-gray-600">Please provide any feedback regarding your experience with Routora.</p>

                        <textarea 
                            type="text"
                            value={reason}
                            onChange={handleInputChange2}
                            className="w-full mt-5 p-2 h-32 rounded placeholder-gray-400 text-myblue-900"
                            placeholder="Enter your feedback here..."
                            autoFocus 
                        />

                        <p onClick={backClicked} className="underline w-10 mt-12 mb-3 underline-offset-2 mt-3 cursor-pointer">Back</p>
                        { activateButton ?
                            <div>
                                {deleteButtonDisplay}
                            </div>
                            :
                            <div className="text-center font-bold border border-red-400 text-white bg-red-400 cursor-not-allowed rounded-lg p-3">
                                <span className="">Cancel my Subscription</span>
                            </div>
                        }
                    </div>
                }


            </div>
        
        </div>
    </div>        

  );
}

export default CancelSubForm;