import React from 'react';

function Grid6() {
    return (
        <div className="group grid-elements grid4-parent flex flex-col justify-between text-lg items-end rounded-xl border transition duration-300 ease-in-out shadow-md hover:bg-gray-100 hover:shadow-lg overflow-hidden">
            
            <div className="text-container w-full p-5 md:p-10 md:order-1">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">Copy and paste your list of stops</h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    Another great option to save time when entering your itinerary onto Routora Web.
                </p>
            </div>

            {/* Image container */}
            <div className="flex md:order-2 px-3 md:px-5 order-2">
                <div className="w-full justify-center bg-white rounded-tr-xl rounded-tl-xl shadow-lg border-myblue-300 border-l border-r border-t">
                    <img src={require('./GridImages/paste-grid6.1.png').default} alt="Routora Maps Dashboard" className="rounded-tr-xl rounded-tl-xl"/>
                </div>
            </div>

        </div>
    );
}

export default Grid6;
