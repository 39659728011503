import React, { useEffect, useState } from 'react';
import Axios from 'axios'

function ShareRoutesModal(props) {

    /* 
        share status codes:
        0 - nothing
        1 - loading
        2 - success
        3 - fail
    */
    const [shareStatus, setShareStatus] = useState([])

    const [copyStatus, setCopyStatus] = useState([])

    const [isText, setIsText] = useState(false)
    
    function toggleEmail() {
        setIsText(false)
        let updatedStatus = [...shareStatus].map(() => 0);
        setShareStatus(updatedStatus);
    }
    function toggleText() {
        setIsText(true)
        let updatedStatus = [...shareStatus].map(() => 0);
        setShareStatus(updatedStatus);
    }

    useEffect(() => {
        let tempShareStatus = []
        let tempCopyStatus = []
        for (let i = 0; i < props.driverRoutes.routes.length; i++) {
            tempShareStatus.push(0)
            tempCopyStatus.push(false)
        }
        setShareStatus(tempShareStatus)
        setCopyStatus(tempCopyStatus)
    }, [props.driverRoutes]);

    const handleCopy = (index, routoraLink) => {
        navigator.clipboard.writeText(routoraLink).then(() => {
            // set everything to false, then set current index to true
            let updatedStatus = Array(shareStatus.length).fill(false);
            updatedStatus[index] = true;
            setCopyStatus(updatedStatus);
        });
    };

    /*
        Handle email sends
    */
    async function handleEmailSend(index, route) {

        let allowClick = true
        for (let i = 0; i < shareStatus.length; i++) {
            if (shareStatus[i] == 1) {
                allowClick = false
            }
        }

        if (allowClick) {
    
            // Set loading status
            let updatedStatus = [...shareStatus]; // Create a copy of the current state array
            updatedStatus[index] = 1; // Set the status to "loading"
            setShareStatus([...updatedStatus]); // Ensure React notices the state update by setting a new array
    
            if (!route.driverInfo.email || route.driverInfo.email == '') {
                updatedStatus[index] = 0;
            } else {
                try {
                    let status = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/sendemail`, {
                        'toEmail': route.driverInfo.email,
                        'mapsUrl': route.routoraLink
                    });
        
                    if (status.data.isSent) {
                        console.log('Email Success: ' + route.driverInfo.email);
                        updatedStatus[index] = 2; // Set success state
                    } else {
                        console.log('Email Fail: ' + route.driverInfo.email);
                        updatedStatus[index] = 3; // Set failure state
                    }
                } catch (error) {
                    console.log('Error sending email:', error);
                    updatedStatus[index] = 3; // Set failure state
                }
            }
    
            setShareStatus([...updatedStatus]); // Set the new updated state array to trigger rerender
        }
    };
    
    async function sendAllEmails() {
        // Create a copy of the current shareStatus array and set all drivers to loading (1)
        let updatedStatus = [...shareStatus].map(() => 1);
        setShareStatus(updatedStatus); // Set the loading status for all drivers
    
        const promises = props.driverRoutes.routes.map(async (route, index) => {

            if (!route.driverInfo.email || route.driverInfo.email == '') {
                updatedStatus[index] = 0;
            } else {
                try {
                    // Attempt to send the email for the current driver
                    let status = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/sendemail`, {
                        'toEmail': route.driverInfo.email,
                        'mapsUrl': route.routoraLink
                    });
        
                    // Update the status for this driver based on the success of the request
                    if (status.data.isSent) {
                        console.log('Email Success: ' + route.driverInfo.email);
                        updatedStatus[index] = 2; // Set success status
                    } else {
                        console.log('Email Fail: ' + route.driverInfo.email);
                        updatedStatus[index] = 3; // Set failure status
                    }
                } catch (error) {
                    console.log('Error sending email:', error);
                    updatedStatus[index] = 3; // Set failure status in case of error
                }
            }
    
            // Update the shareStatus after each email is sent
            setShareStatus([...updatedStatus]);
        });
    
        // Wait for all emails to be processed
        await Promise.all(promises);
    
        console.log('All emails have been processed.');
        //alert('All Emails Sent! Check spam if not in inbox')

        
        let fails = updatedStatus.includes(3)
        if (!fails) {
            setTimeout(() => {
                console.log('Closing modal...');
                props.setOpenShareRoutesModal(false);
            }, 1000);
        }
    }



    /*
        Handle text sends
    */
   async function handleTextSend(index, route) {

        let allowClick = true
        for (let i = 0; i < shareStatus.length; i++) {
            if (shareStatus[i] == 1) {
                allowClick = false
            }
        }

        if (allowClick) {

            // Set loading status
            let updatedStatus = [...shareStatus]; // Create a copy of the current state array
            updatedStatus[index] = 1; // Set the status to "loading"
            setShareStatus([...updatedStatus]); // Ensure React notices the state update by setting a new array

            if (!route.driverInfo.phoneNum || route.driverInfo.phoneNum == '') {
                updatedStatus[index] = 0;
            } else {
                try {
                    let status = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/sendtext`, {
                        'toNumber': route.driverInfo.phoneNum,
                        'mapsUrl': route.routoraLink
                    });
        
                    if (status.data.isSent) {
                        console.log('Text Success: ' + route.driverInfo.email);
                        updatedStatus[index] = 2; // Set success state
                    } else {
                        console.log('Text Fail: ' + route.driverInfo.email);
                        updatedStatus[index] = 3; // Set failure state
                    }
                } catch (error) {
                    console.log('Error sending Text:', error);
                    updatedStatus[index] = 3; // Set failure state
                }
            }

            setShareStatus([...updatedStatus]); // Set the new updated state array to trigger rerender
        }
    };

    async function sendAllTexts() {
        // Create a copy of the current shareStatus array and set all drivers to loading (1)
        let updatedStatus = [...shareStatus].map(() => 1);
        setShareStatus(updatedStatus); // Set the loading status for all drivers

        const promises = props.driverRoutes.routes.map(async (route, index) => {

            if (!route.driverInfo.phoneNum || route.driverInfo.phoneNum == '') {
                updatedStatus[index] = 0;
            } else {
                try {
                    // Attempt to send the email for the current driver
                    let status = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/sendtext`, {
                        'toNumber': route.driverInfo.phoneNum,
                        'mapsUrl': route.routoraLink
                    });
        
                    // Update the status for this driver based on the success of the request
                    if (status.data.isSent) {
                        console.log('Text Success: ' + route.driverInfo.email);
                        updatedStatus[index] = 2; // Set success status
                    } else {
                        console.log('Text Fail: ' + route.driverInfo.email);
                        updatedStatus[index] = 3; // Set failure status
                    }
                } catch (error) {
                    console.log('Error sending Text:', error);
                    updatedStatus[index] = 3; // Set failure status in case of error
                }
            }

            // Update the shareStatus after each email is sent
            setShareStatus([...updatedStatus]);
        });

        // Wait for all emails to be processed
        await Promise.all(promises);

        console.log('All texts have been processed.');
        //alert('All Emails Sent! Check spam if not in inbox')

        let fails = updatedStatus.includes(3)
        if (!fails) {
            setTimeout(() => {
                console.log('Closing modal...');
                props.setOpenShareRoutesModal(false);
            }, 1000);
        }
    }
    
    

    return (
        <div className="driversModalBackground">
            <div className="shareRoutesModalContainer">
                <div className="driversCloseBtn z-20">
                    <button className="text-gray-500" onClick={() => { props.setOpenShareRoutesModal(false) }} >
                        <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/>
                        </svg>
                    </button>
                </div>

                <div className="text-lg p-3 h-12 border-b border-gray-300 sticky top-0 bg-white z-10">
                    Share Routes
                </div>

                {isText ? 
                    <div className="flex w-full h-12 cursor-pointer">
                        <div onClick={toggleEmail} className="w-1/2 bg-white">
                            <div className="h-full bg-gray-200 rounded-br-md shadow-inner text-center flex items-center justify-center">
                                <p className="text-sm">Email</p>
                            </div>
                        </div>
                        <div onClick={toggleText} className="w-1/2 bg-gray-200">
                            <div className="h-full bg-white rounded-tl-md text-center flex items-center justify-center">
                                <p className="text-sm">Text</p>
                            </div>
                        </div>
                    </div>
                :
                    <div className="flex w-full h-12 cursor-pointer">
                        <div onClick={toggleEmail} className="w-1/2 bg-gray-200">
                            <div className="h-full bg-white rounded-tr-md text-center flex items-center justify-center">
                                <p className="text-sm">Email</p>
                            </div>
                        </div>
                        <div onClick={toggleText} className="w-1/2 bg-white">
                            <div className="h-full bg-gray-200 rounded-bl-md shadow-inner text-center flex items-center justify-center">
                                <p className="text-sm">Text</p>
                            </div>
                        </div>
                    </div>
                }
                {/* <div className="flex items-center justify-center mt-5 md:mb-3 mb-3">
                    <div className="w-25">
                        { isText ? 
                            <div className="md:text-1xl font-bold text-gray-300 mr-2">Email</div> 
                        : 
                            <div className="md:text-1xl font-bold text-gray-500 mr-2">Email</div> 
                        }
                    </div>
                    <label class="toggle" for="myToggle">
                        <input class="toggle__input" name="" type="checkbox" id="myToggle" defaultChecked onClick={toggleClicked}></input>
                        <div class="toggle__fill"></div>
                    </label>
                    <div className="flex items-center justify-center w-25">
                        <div className="md:text-1xl font-bold text-gray-500 ml-2">
                            { isText ? 
                                <div className="md:text-1xl font-bold text-gray-500 mr-2">Text</div> 
                            : 
                                <div className="md:text-1xl font-bold text-gray-300 mr-2">Text</div> 
                            }
                        </div>
                    </div>
                </div> */}

                <div id="driver-select" className="px-4 h-full pt-5 overflow-y-auto">
                    {props.driverRoutes.routes.map((route, index) => (
                        <div key={route.driverInfo.id} className="mb-3">
                            <label className="flex items-center justify-between w-full space-x-3">
                                <div className="flex items-center space-x-3 mt-1">
                                    <div className="w-4 h-4 rounded-full" style={{ backgroundColor: route.driverInfo.colorCode }}></div>
                                    <div>
                                        <div className="flex">
                                            <div className="text-sm font-medium text-gray-900">{route.driverInfo.name}</div>
                                            {isText ? 
                                                <div style={{ marginTop: 2}} className="text-xs text-gray-500 mx-3">{route.driverInfo.phoneNum}</div>
                                            :
                                                <div style={{ marginTop: 2}} className="text-xs text-gray-500 mx-3">{route.driverInfo.email}</div>
                                            }
                                        </div>
                                        <div 
                                            className="flex items-center text-gray-600 hover:text-black cursor-pointer"
                                            onClick={() => handleCopy(index, route.routoraLink)}
                                        >
                                            {copyStatus[index] ? 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
                                            : 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 512 512"><rect x="128" y="128" width="336" height="336" rx="57" ry="57" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                                            }
                                            <p className="ml-1 text-xs">
                                                {copyStatus[index] ? 'Copied!' : 'Copy route url'}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div style={{marginTop: 4}} className="mr-2">
                                        {shareStatus[index] === 0 && 
                                            <div></div>
                                        }
                                        {shareStatus[index] === 1 && 
                                            <div class="loader">
                                                <div class="loading-circle"></div>
                                            </div>
                                        }
                                        {shareStatus[index] === 2 && 
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-myteal-500" width="20" height="20" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
                                        }
                                        {shareStatus[index] === 3 && 
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-myred-500" width="20" height="20" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
                                        }
                                    </div>
                                    <div 
                                        className="py-1 w-16 cursor-pointer rounded bg-blue-500 hover:bg-blue-600 text-white flex justify-center items-center"
                                        onClick={() => isText ? handleTextSend(index, route) : handleEmailSend(index, route)}
                                    >
                                        {shareStatus[index] >= 2 ?
                                            <p className="text-xs">Re-send</p>
                                        :
                                            <p className="text-xs">Send</p>
                                        }
                                    </div>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="driversFooter sticky bottom-0 bg-white z-10 flex justify-between items-center p-3 border-t border-gray-300">
                    <button className="text-myteal-500 hover:text-myteal-600 underline">Edit Contacts</button>
                    <button onClick={isText ? sendAllTexts : sendAllEmails} className="bg-myteal-500 text-white font-semibold py-1 px-4 rounded hover:bg-myteal-600">Send All</button>
                </div>
            </div>
        </div>
    );
}

export default ShareRoutesModal;
