import React, { useState, useRef, useEffect } from 'react';
import Transition from '../../utils/Transition.js';
import './ExtensionInstructionModal.css'

function ExtensionInstructionModal(props) {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  function goMaps() {
    window.open("https://www.google.com/maps/dir/Empire+State+Building,+New+York,+NY/Times+Square,+Manhattan,+NY/Rockefeller+Center/Bryant+Park,+New+York,+NY/Radio+City+Music+Hall/Macy's,+West+34th+Street,+New+York,+NY/Grand+Central+Terminal/Birdland+Jazz+Club/@40.7550023,-73.9920025,15z/data=!3m2!4b1!5s0x89c258539b88fa5b:0xbab6890d09673afe!4m50!4m49!1m5!1m1!1s0x89c259a9aeb1c6b5:0x35b1cfbc89a6097f!2m2!1d-73.9857635!2d40.7485452!1m5!1m1!1s0x89c25855c6480299:0x55194ec5a1ae072e!2m2!1d-73.9855426!2d40.7579747!1m5!1m1!1s0x89c258fecf664df5:0x33d224a0d5dacca2!2m2!1d-73.9786736!2d40.7587402!1m5!1m1!1s0x89c259aae7a0b1bd:0xb49cafb82537f1a7!2m2!1d-73.9832326!2d40.7535965!1m5!1m1!1s0x89c258f8d61b2f3d:0x92c4e681d9aada95!2m2!1d-73.9799772!2d40.759976!1m5!1m1!1s0x89c259aeadd618df:0xdb28329e297402af!2m2!1d-73.9890961!2d40.750831!1m5!1m1!1s0x89c25a21fb011c85:0x37513b7f1821408b!2m2!1d-73.9772294!2d40.7527262!1m5!1m1!1s0x89c258538544cf75:0xb752bff9619276fa!2m2!1d-73.9896861!2d40.7590167!3e0", "_blank")
  }

  return (
    <div className="modalBackgroundEx">
      <div className="modalContainerEx">
            <div className="titleCloseBtnEx">
                <button
                    onClick={() => {
                        props.setOpenModal(false);
                    }}
                >
                    x
                </button>
            </div>
            {/* <div className="title text-xl text-gray-600">
                <h1>{props.subMessage}</h1>
            </div> */}
            
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div>

                {/* Section header */}
                <div id="extension-instructions-section" className="max-w-3xl mx-auto text-center pb-3 md:pb-4">
                    <h1 className="h2 mb-4">Extension Instructions</h1>
                    <p className="text-xl text-gray-600">Our <a href="https://chrome.google.com/webstore/detail/routify-google-maps-route/jdddfnfohdeaklgkpglonlofgapjgfbp?hl=en" className="underline-offset-2 text-myteal-500">chrome extension</a> takes the route you have entered in Google Maps and rearranges the entered stops to provide you the fastest route.</p>
                    {/* <div className="btn-sm m-7 py-3 px-24 text-white bg-myteal-500 cursor-pointer hover:bg-white hover:text-myteal-500 hover:border-4 hover:border-myteal-500" onClick={goChrome}>
                        <span>Download Extension</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 -mr-1" fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                        </svg>                  
                    </div> */}
                </div>

                {/* Section content */}
                <div className="md:grid md:grid-cols-12 md:gap-6">

                    {/* Content */}
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6" data-aos="fade-right">
                    {/* <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                        <h3 className="h3 mb-3">Powerful suite of tools</h3>
                        <p className="text-xl text-gray-600">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.</p>
                    </div> */}
                    
                    <div className="mb-8 md:mb-0">
                        <a
                        className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setTab(1); }}
                        >
                        <div>
                            <div className="font-bold leading-snug tracking-tight mb-1">Enter Stops</div>
                            <div className="text-gray-600">Using Google Maps, enter a start destination, end destination and all the stops you would like to visit</div>
                        </div>
                        <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                            <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                            </svg>
                        </div>
                        </a>
                        <a
                        className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setTab(2); }}
                        >
                        <div>
                            <div className="font-bold leading-snug tracking-tight mb-1">Click on Extension</div>
                            <div className="text-gray-600">After pinning the extension, click on the routify icon to optimize the current route</div>
                        </div>
                        <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                            <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" fillRule="nonzero" />
                            </svg>
                        </div>
                        </a>
                        <a
                        className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setTab(3); }}
                        >
                        <div>
                            <div className="font-bold leading-snug tracking-tight mb-1">Share Route</div>
                            <div className="text-gray-600">Our extension will open a new tab with an optimized Google Maps route where you have the option to send the route to your phone or start your navigation</div>
                        </div>
                        <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                            {/* <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z" fill="#191919" fillRule="nonzero" />
                            </svg> */}
                            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="17" width="17" viewBox="0 0 512 512">
                            <circle cx="256" cy="192" r="32"/><path d="M256 32c-88.22 0-160 68.65-160 153 0 40.17 18.31 93.59 54.42 158.78 29 52.34 62.55 99.67 80 123.22a31.75 31.75 0 0051.22 0c17.42-23.55 51-70.88 80-123.22C397.69 278.61 416 225.19 416 185c0-84.35-71.78-153-160-153zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z"/>
                            </svg>
                        </div>
                        </a>
                    </div>
                    </div>

                    {/* Tabs items */}
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="zoom-y-out" ref={tabs}>
                    <div className="relative flex flex-col text-center lg:text-right">
                        
                        <Transition
                        show={tab === 1}
                        appear={true}
                        className="w-full"
                        enter="transition ease-in-out duration-700 transform order-first"
                        enterStart="opacity-0 translate-y-16"
                        enterEnd="opacity-100 translate-y-0"
                        leave="transition ease-in-out duration-300 transform absolute"
                        leaveStart="opacity-100 translate-y-0"
                        leaveEnd="opacity-0 -translate-y-16"
                        >
                        <div className="relative inline-flex flex-col">
                            <img className="md:max-w-none mx-auto rounded mt-7 rounded-2xl drop-shadow-2xl" src={require('../../images/ss1new.png').default} width="530" height="462" alt="Features bg" />
                            <div className="absolute left-32 top-44 py-3 px-5 font-bold btn-sm hover:text-white hover:bg-mygray-300 cursor-pointer bg-mygray-400 text-myteal-200 animate-float" onClick={(e) => { e.preventDefault(); setTab(2); }}>
                            <img className="w-6 h-6 mr-2 rounded-full"
                                alt=""
                                src={"https://lh3.googleusercontent.com/a-/AOh14GjPE4qsWqmWJ-SQwuqmnHCVjsr9aPH1T9lolqiVtw=s96-c"}
                            />
                            <span>1 <span className="text-myteal-500">hr</span> 51 <span className="text-myteal-500">min</span></span>
                            <svg className="w-3 h-3 fill-current flex-shrink-0 ml-7 mt-1 -mr-1" fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                            </svg>  
                            </div>
                        </div>
                        </Transition>
                        
                        <Transition
                        show={tab === 2}
                        appear={true}
                        className="w-full"
                        enter="transition ease-in-out duration-700 transform order-first"
                        enterStart="opacity-0 translate-y-16"
                        enterEnd="opacity-100 translate-y-0"
                        leave="transition ease-in-out duration-300 transform absolute"
                        leaveStart="opacity-100 translate-y-0"
                        leaveEnd="opacity-0 -translate-y-16"
                        >
                        {/* <div className="relative inline-flex flex-col">
                            <img className="md:max-w-none mx-auto rounded" src={require('../../images/features-bg.png').default} width="500" height="462" alt="Features bg" />
                            <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} />
                        </div> */}
                        <div className="relative inline-flex flex-col">
                            <img className="md:max-w-none mx-auto rounded-2xl mt-20" src={require('../../images/ss2new.png').default} width="500" height="462" alt="Features bg" />
                        </div>
                        </Transition>
                        
                        <Transition
                        show={tab === 3}
                        appear={true}
                        className="w-full"
                        enter="transition ease-in-out duration-700 transform order-first"
                        enterStart="opacity-0 translate-y-16"
                        enterEnd="opacity-100 translate-y-0"
                        leave="transition ease-in-out duration-300 transform absolute"
                        leaveStart="opacity-100 translate-y-0"
                        leaveEnd="opacity-0 -translate-y-16"
                        >
                        <div className="relative inline-flex flex-col">
                            <img className="md:max-w-none mx-auto rounded mt-7 rounded-2xl drop-shadow-2xl" src={require('../../images/ss3new.png').default} width="530" height="462" alt="Features bg" />
                            <div className="absolute left-32 bottom-20 py-3 px-5 font-semibold btn-sm cursor-pointer bg-mygray-400 text-myteal-500 animate-float">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" height="20" viewBox="0 0 24 24"><path fill="#338CF5" d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                            <span>Saved <span className="text-myteal-200">38</span> min</span>
                            </div>
                        </div>
                        </Transition>
                    </div>
                    </div >

                </div >

                </div >
            </div >

            <div className="footer">

            <div
                onClick={() => {
                    props.setOpenModal(false);
                }}
                id="cancelBtn"
                className="btn-sm font-semibold text-gray-100 bg-myred-500 hover:bg-myred-600 cursor-pointer m-4 w-96"
            >
                Done
            </div>
            <div className="btn-sm font-semibold text-gray-100 bg-blue-500 hover:bg-blue-600 cursor-pointer m-4 w-96" onClick={goMaps}>Google Maps</div>
        </div>
      </div>
    </div>
  );
}

export default ExtensionInstructionModal;