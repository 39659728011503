import React, { useState } from 'react';
import './ModeBox.css'

function ModeBox(props) {

    let [buttonColors, setButtonColors] = useState({driving: '#00b28b', bicycling: '#1d1f23', walking: '#1d1f23'})

    function modeClicked(e) {
        if (props.showResultBox === 1) {
            props.setShowResultBox(2)
        }
        let currMode = e.currentTarget.id.split('-')[1]
        console.log(currMode)
        props.setMode(currMode)

        if (currMode === 'd') {
            setButtonColors({driving: '#00b28b', bicycling: '#1d1f23', walking: '#1d1f23'})
        } else if (currMode === 'b') {
            setButtonColors({driving: '#1d1f23', bicycling: '#00b28b', walking: '#1d1f23'})
        } else if (currMode === 'w') {
            setButtonColors({driving: '#1d1f23', bicycling: '#1d1f23', walking: '#00b28b'})
        }
    }

    return (
        <div className="mode-buttons-container">
            <div id="mode-d" className="mode-button" onClick={modeClicked}>
                <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px" class="ionicon" viewBox="0 0 512 512"><title>Car</title><path fill={buttonColors.driving} d="M80 224l37.78-88.15C123.93 121.5 139.6 112 157.11 112h197.78c17.51 0 33.18 9.5 39.33 23.85L432 224M80 224h352v144H80zM112 368v32H80v-32M432 368v32h-32v-32" fill="none" stroke={buttonColors.driving} stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="144" cy="288" r="16" fill="none" stroke={buttonColors.driving} stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="368" cy="288" r="16" fill="none" stroke={buttonColors.driving} stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
            </div>
            <div id="mode-b" className="mode-button" onClick={modeClicked}>
                <svg xmlns="http://www.w3.org/2000/svg" height="23px" width="23px" class="ionicon" viewBox="0 0 512 512"><title>Bicycle</title><path fill={buttonColors.bicycling} d="M388 448a92 92 0 1192-92 92.1 92.1 0 01-92 92zm0-152a60 60 0 1060 60 60.07 60.07 0 00-60-60zM124 448a92 92 0 1192-92 92.1 92.1 0 01-92 92zm0-152a60 60 0 1060 60 60.07 60.07 0 00-60-60zM320 128a31.89 31.89 0 0032-32.1A31.55 31.55 0 00320.2 64a32 32 0 10-.2 64z"/><path fill={buttonColors.bicycling} d="M367.55 192h-43.76a4 4 0 01-3.51-2.08l-31.74-58.17a31 31 0 00-49.38-7.75l-69.86 70.4a32.56 32.56 0 00-9.3 22.4c0 17.4 12.6 23.6 18.5 27.1 28.5 16.42 48.57 28.43 59.58 35.1a4 4 0 011.92 3.41v69.12c0 8.61 6.62 16 15.23 16.43A16 16 0 00272 352v-86a16 16 0 00-6.66-13l-37-26.61a4 4 0 01-.58-6l42-44.79a4 4 0 016.42.79L298 215.77a16 16 0 0014 8.23h56a16 16 0 0016-16.77c-.42-8.61-7.84-15.23-16.45-15.23z"/></svg>
            </div>
            <div id="mode-w" className="mode-button" onClick={modeClicked}>
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" width="20px" class="ionicon" viewBox="0 0 512 512"><title>Walk</title><path fill={buttonColors.walking} d="M312.55 479.9l-56.42-114-44.62-57a72.37 72.37 0 01-10.06-36.9V143.64H217a40 40 0 0140 40v182.21" stroke={buttonColors.walking} stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M127.38 291.78v-74.07s37-74.07 74.07-74.07" fill="none" stroke={buttonColors.walking} stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill={buttonColors.walking} d="M368.09 291.78a18.49 18.49 0 01-10.26-3.11L297.7 250a21.18 21.18 0 01-9.7-17.79v-23.7a5.65 5.65 0 018.69-4.77l81.65 54.11a18.52 18.52 0 01-10.29 33.93zM171.91 493.47a18.5 18.5 0 01-14.83-7.41c-6.14-8.18-4-17.18 3.7-25.92l59.95-74.66a7.41 7.41 0 0110.76 2.06c1.56 2.54 3.38 5.65 5.19 9.09 5.24 9.95 6 16.11-1.68 25.7-8 10-52 67.44-52 67.44-2.62 2.98-7.23 3.7-11.09 3.7z"/><circle fill={buttonColors.walking} cx="257" cy="69.56" r="37.04" stroke={buttonColors.walking} stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg>
            </div>
        </div>
    );
}

export default ModeBox;

