import React from 'react';
import './Modal.css'
import { useHistory } from 'react-router-dom';

function Modal(props) {

    const history = useHistory();

    function actionClicked() {
        history.push(props.navigate)
    }

  return (
    <div className="modalBackground">
      <div className="modalContainer">
            <div className="titleCloseBtn">
            <button
                onClick={() => {
                    props.setOpenModal(false);
                }}
            >
                x
            </button>
            </div>
            {/* <div className="title text-xl text-gray-600">
                <h1>{props.subMessage}</h1>
            </div> */}
            <div className="body text-2xl text-gray-600">
                <p>{props.mainMessage}</p>
            </div>
            
            {/* temporary, can probably delete this later */}
            { props.mainMessage == 'Payment failed following free trial. We\'ve canceled your subscription.' &&
            <div className="title text-lg text-gray-500">
                <h1>Questions? Email info@routora.com</h1>
            </div>
            }
            <div className="footer">
            <div
                onClick={() => {
                    props.setOpenModal(false);
                }}
                id="cancelBtn"
                className="btn-sm text-gray-100 bg-myred-500 hover:bg-myred-600 cursor-pointer m-4 w-44"
            >
                Done
            </div>
            {props.isAction && <div className="btn-sm text-gray-100 bg-blue-500 hover:bg-blue-600 cursor-pointer m-4 w-44" onClick={actionClicked}>{props.actionMessage}</div>}
        </div>
      </div>
    </div>
  );
}

export default Modal;