import React, { useEffect } from "react";
import TeamPricing from "../../components/team_pricing";
import Slider from "../components/slider";
import { OnboardingApi } from "../api";
import { useState, useContext } from "react";
import { AuthContext } from "../../../AuthContext";

const TrialDetails = (props) => {
  const FLEET_FLAT_MONTHLY_RATE = 79;
  const ADDITIONAL_DRIVER_FEE = 10;
  const [numberOfDrivers, setNumberOfDrivers] = useState(0);
  const [originalNumberOfDrivers, setOriginalNumberOfDrivers] = useState(0);
  const [fleetMonthlyPrice, setFleetMonthlyPrice] = useState(79);

  const { updateDriverCount } = useContext(AuthContext);

  useEffect(() => {
    if (props.form.numDrivers){
      let answeredNumDrivers = props.form.numDrivers
      setNumberOfDrivers(answeredNumDrivers)
      setOriginalNumberOfDrivers(answeredNumDrivers)
    }
  }, []);

  useEffect(() => {
    setFleetMonthlyPrice(calulateFleetMonthlyPrice());
  }, [numberOfDrivers]);

  const calulateFleetMonthlyPrice = () => {
    if (numberOfDrivers <= 5) {
      return FLEET_FLAT_MONTHLY_RATE;
    }

    return (
      FLEET_FLAT_MONTHLY_RATE + (numberOfDrivers - 5) * ADDITIONAL_DRIVER_FEE
    );
  };

  async function initiateTrial() {

    // first update the driver count if its changed
    if (numberOfDrivers != originalNumberOfDrivers) {
      props.saveData("numDrivers", numberOfDrivers);

      updateDriverCount(props.email, numberOfDrivers)
    }

    console.log(props.name)
    const customerId = await OnboardingApi.createCustomer(
      props.email,
      props.name
    );
    if (customerId) {
      await OnboardingApi.startTrial(
        props.email,
        customerId,
        numberOfDrivers
      );
    }
  }

  return (
    <>
      <div className="title text-lg font-medium text-black-500 px-4">
        <h1>Choose the right plan for your business.</h1>
      </div>
      <div className="flex flex-row space-x-4 w-full">
        <div className="flex-1" style={{ maxWidth: 500 }}>
          <TeamPricing
            isYearly={true}
            fleetMonthlyPrice={fleetMonthlyPrice}
            fleetYearlyPrice={Math.round(fleetMonthlyPrice * 12 * 100) / 100}
            onStartTrial={initiateTrial}
          />
        </div>
        <div className="flex-1 flex justify-center mt-12">
          <div className="w-full">
            <div className="title text-lg font-medium text-black-500 px-4">
              <h1>Choose number of drivers</h1>
            </div>
            <div className="px-4">
              <Slider
                value={numberOfDrivers}
                setValue={(value) => {
                  setNumberOfDrivers(Number(value));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrialDetails;
