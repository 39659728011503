const TeamPricing = (props) => {
  const FLEET_PRICE_YEARLY_BREAKDOWN = 79;

  return (
    <div
      style={{ height: 550, width: 400 }}
      className="p-7 bg-white rounded-2xl shadow-lg border-gray-200 border-2 md:mt-5"
      data-aos="zoom-y-out"
      data-aos-delay="150"
    >
      <h4 className="text-xl text-myblue-900 font-semibold">
        Routora for Teams
      </h4>
      <p style={{ fontSize: 15 }} className="font-medium text-gray-600">
        For teams with a fleet of drivers looking to efficiently allocate
        several routes
      </p>
      <div className="w-full border-t border-gray-300 my-3">
          <div className="border-t-0 h-0.5" />
        </div>
      <div className="flex mt-5">
        <div className="text-4xl :text-4xl text-myblue-900 font-bold leading-snug tracking-tight mr-1">
          {props.fleetMonthlyPrice ?? FLEET_PRICE_YEARLY_BREAKDOWN}
        </div>
        <div className="md:text-1xl text-gray-600 font-medium mt-6">
          / month
        </div>
      </div>
      {/* <div className="flex mb-2">
        <p className="md:text-sm text-sm text-gray-500">
          ${props.fleetYearlyPrice ?? 863.99} Billed Annually
        </p>
        <p className="md:text-sm text-sm text-myteal-500 ml-1 font-semibold">You Save ${216}</p>
      </div> */}
      <div className="flex justify-between mb-2 font-medium">
        <p className="md:text-sm text-sm text-gray-500">
          Included per month:
        </p>
        <p className="md:text-sm text-sm text-gray-500">
          5 drivers
        </p>
      </div>
      <div className="flex justify-between mb-2 font-medium">
        <p className="md:text-sm text-sm text-gray-500">
          Cost per additional driver:
        </p>
        <p className="md:text-sm text-sm text-gray-500">
          +10
        </p>
      </div>

      <div
        onClick={props.onStartTrial}
        id="Fleet"
        className="btn mt-3 py-2 font-semibold rounded-lg border-2 text-white bg-myteal-500 hover:bg-myteal-600 border-myteal-500 hover:border-myteal-600 w-full cursor-pointer"
      >
        <p>Start free 7 day trial</p>
      </div>
      <div className="mt-7">
        <div className="flex my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21px"
            height="21px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#00B28B"
              d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
            />
          </svg>
          <p
            style={{ fontSize: 15 }}
            className="font-medium text-gray-600 text-left ml-1"
          >
            Scalable fleet management
          </p>
        </div>
        <div className="flex my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21px"
            height="21px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#00B28B"
              d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
            />
          </svg>
          <p
            style={{ fontSize: 15 }}
            className="font-medium text-gray-600 text-left ml-1"
          >
            Balanced route distribution
          </p>
        </div>

        <div className="flex my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21px"
            height="21px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#00B28B"
              d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
            />
          </svg>
          <p
            style={{ fontSize: 15 }}
            className="font-medium text-gray-600 text-left ml-1"
          >
            Import stops from excel, CSV
          </p>
        </div>
        <div className="flex my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21px"
            height="21px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#00B28B"
              d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
            />
          </svg>
          <p
            style={{ fontSize: 15 }}
            className="font-medium text-gray-600 text-left ml-1"
          >
            Download itineraries
          </p>
        </div>
        <div className="flex my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21px"
            height="21px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#00B28B"
              d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
            />
          </svg>
          <p
            style={{ fontSize: 15 }}
            className="font-medium text-gray-600 text-left ml-1"
          >
            Share routes
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeamPricing;
