import React, { useState, useEffect } from 'react';


function SharedIndividualRoutes(props) {

    const [complete, setComplete] = useState([])
    const [incomplete, setIncomplete] = useState([])
    useEffect(() => {
        
        let tempFalse = []
        for (let i = 0; i < props.directionsData.routeOrder.length; i++) {
            tempFalse.push(false)
        }
        setComplete(tempFalse)
        setIncomplete(tempFalse)

    }, [])

    function completeClicked(i) {
        let completeList = [...complete]
        completeList[i] = !completeList[i]

        setComplete(completeList)

        // now if complete and incomplete are same, reverse other
        let incompleteList = [...incomplete]
        if (completeList[i] && incompleteList[i]) {
            incompleteList[i] = !incompleteList[i]
            setIncomplete(incompleteList)
        }
    }
    function incompleteClicked(i) {
        let incompleteList = [...incomplete]
        incompleteList[i] = !incompleteList[i]

        setIncomplete(incompleteList)

        // now if complete and incomplete are same, reverse other
        let completeList = [...complete]
        if (completeList[i] && incompleteList[i]) {
            completeList[i] = !completeList[i]
            setComplete(completeList)
        }
    }

    let content = (
        <div>
            <div class="md:text-3xl font-bold text-gray-700 leading-snug tracking-tight mb-1">Optimized route order will be shown here👇</div>
            <div class="md:text-2xl text-gray-600">Select your route details above including the start and stop addresses, as well as the addresses of all the stops you plan on visiting. Once complete, click optimize and the new (and fastest) route order will be displayed alongside navigatable google maps links! </div>
        </div>
    )

    function mapMenuClicked(e) {
        let mapSelected = e.currentTarget.id
        console.log(mapSelected)

        let selected = {
            googleMaps: false,
            appleMaps: false,
            waze: false
        }

        if (mapSelected === 'googleMaps') {
            selected.googleMaps = true
        } else if (mapSelected === 'appleMaps') {
            selected.appleMaps = true
        } else if (mapSelected === 'waze') {
            selected.waze = true
        }

        props.setMapSelected(selected)
    }

    let mapMenu = (
        <div className="flex mb-10 mt-2">
            <div id="googleMaps" className="cursor-pointer" onClick={mapMenuClicked}>
                {props.mapSelected.googleMaps ? 
                    (<p className="font-semibold text-myteal-500 py-1 px-2 rounded-full bg-myteal-100">Google Maps</p>) : 
                    (<p className="text-gray-400 py-1 px-2">Google Maps</p>)
                }
            </div>
            <div id="appleMaps" className="cursor-pointer" onClick={mapMenuClicked}>
                {props.mapSelected.appleMaps ? 
                    <p className="font-semibold text-myteal-500 py-1 px-2 rounded-full bg-myteal-100">Apple Maps</p> : 
                    <p className="text-gray-400 py-1 px-2">Apple Maps</p>
                }
            </div>
            <div id="waze" className="cursor-pointer" onClick={mapMenuClicked}>
                {props.mapSelected.waze ? 
                    <p className="font-semibold text-myteal-500 py-1 px-2 rounded-full bg-myteal-100">Waze</p> : 
                    <p className="text-gray-400 py-1 px-2">Waze</p>
                }
            </div>
        </div>
    )

    function openSoloLink(linksObject) {

        console.log(linksObject)

        let link = linksObject.googleUrl
        if (props.mapSelected.googleMaps) {
            link = linksObject.googleUrl
        } else if (props.mapSelected.appleMaps) {
            link = linksObject.appleUrl
        } else if (props.mapSelected.waze) {
            link = linksObject.wazeUrl
        }

        window.open(link, '_blank');
    }

    if (props.directionsData.routeOrder.length > 0) {

        let totalDistance = props.directionsData.totalDistance
        let totalDistanceText = '(' + totalDistance + ' mi)'

        let totalTime = props.directionsData.totalTime
        let totalTimeText = totalTime + ' mins'
        if (totalTime > 60) {
            let hours = parseInt(totalTime / 60)
            let minutes = parseInt(totalTime % 60)
            if (hours === 1) {
                if (minutes !== 0) {
                    totalTimeText = hours + ' hr, ' + minutes + ' mins'
                } else {
                    totalTimeText = hours + ' hr'
                }
            } else {
                if (minutes !== 0) {
                    totalTimeText = hours + ' hrs, ' + minutes + ' mins'
                } else {
                    totalTimeText = hours + ' hrs'
                }
            }
        }

        content = (
            <div>
                <div className="block justify-right">
                    <div className="text-4xl md:text-5xl text-gray-700 font-bold leading-snug tracking-tight mr-3">Start Navigating</div>
                    
                    {/* <div class="flex rounded-lg text-lg mb-7 mr-6 h-12" role="group">
                        <div class="text-myteal-500 hover:bg-mygray-400 border-2 border-r-1 border-myteal-500 rounded-l-lg px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px" viewBox="0 0 24 24"><path fill="#00B28B" d="M12.71,16.29l-.15-.12a.76.76,0,0,0-.18-.09L12.2,16a1,1,0,0,0-.91.27,1.15,1.15,0,0,0-.21.33,1,1,0,0,0,1.3,1.31,1.46,1.46,0,0,0,.33-.22,1,1,0,0,0,.21-1.09A1,1,0,0,0,12.71,16.29ZM16,2H8A3,3,0,0,0,5,5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V5A1,1,0,0,1,8,4h8a1,1,0,0,1,1,1Z"/></svg>
                        </div>
                        <div class="text-myteal-500 hover:bg-mygray-400 border-2 border-l-0 border-myteal-500 px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px" viewBox="0 0 24 24"><path fill="#00B28B" d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z"/></svg>
                        </div>
                        <div class="text-myteal-500 hover:bg-mygray-400 border-2 border-l-0 border-myteal-500 rounded-r-lg px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px" viewBox="0 0 24 24"><path fill="#00B28B" d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z"/></svg>
                        </div>
                    </div> */}
                    {mapMenu}
                </div>
                {
                    props.directionsData.routeOrder.map((addressData, i) =>
                        <div>
                            <div className="items-center text-lg p-6 rounded border transition duration-300 ease-in-out mb-3 mt-3 bg-white shadow-md border-gray-200 hover:shadow-lg cursor-pointer">
                                <div className="flex items-center w-full">
                                    <div className="flex items-center w-20 mr-2 h-16 min-h-full">
                                        {(i == 0 || i == props.directionsData.routeOrder.length-1) ? 
                                            <div className="w-full">
                                                { i == 0 ? 
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon w-11 h-11" fill="#338CF5" viewBox="0 0 512 512"><title>Home</title><path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z"/><path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z"/></svg> 
                                                : 
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon w-12 h-12" fill="#338CF5" viewBox="0 0 512 512"><title>Flag</title><path d="M80 480a16 16 0 01-16-16V68.13a24 24 0 0111.9-20.72C88 40.38 112.38 32 160 32c37.21 0 78.83 14.71 115.55 27.68C305.12 70.13 333.05 80 352 80a183.84 183.84 0 0071-14.5 18 18 0 0125 16.58v219.36a20 20 0 01-12 18.31c-8.71 3.81-40.51 16.25-84 16.25-24.14 0-54.38-7.14-86.39-14.71C229.63 312.79 192.43 304 160 304c-36.87 0-55.74 5.58-64 9.11V464a16 16 0 01-16 16z"/></svg>
                                                }
                                            </div>
                                        : 
                                            <div className="text-5xl font-bold">{i}</div>
                                        }
                                    </div>
                                    <div className="md:flex w-full">
                                        <div className="md:text-2xl md:w-7/12 text-gray-500 mb-1">{addressData.address}</div>
                                        {/* <div className="btn-sm w-44 md:absolute mt-2 md:mt-0 right-36 text-white bg-myteal-500 cursor-pointer hover:bg-myteal-100 hover:text-myteal-500 hover:border-1 hover:border-myteal-500" onClick={() => openSoloLink(addressData.soloUrls)}>
                                            <span className="w-5/6 text-left pl-1" >Start Route</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current flex-shrink-0" viewBox="0 0 512 512"><title>Navigate</title>
                                                <path d="M272 464a16 16 0 01-16-16.42V264.13a8 8 0 00-8-8H64.41a16.31 16.31 0 01-15.49-10.65 16 16 0 018.41-19.87l384-176.15a16 16 0 0121.22 21.19l-176 384A16 16 0 01272 464z"/>
                                            </svg>       
                                        </div> */}
                                        <div className="block md:flex md:absolute mt-2 md:mt-0 right-36">
                                            <div class="flex rounded-lg text-lg mb-4 md:mb-7 mr-3 h-12" role="group">
                                                {complete[i] ? 
                                                    <div onClick={() => completeClicked(i)} class="w-20 text-white bg-myteal-500 border-2 border-r-1 border-mygray-300 rounded-l-lg px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer flex flex-col items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="30px" width="30px" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
                                                        <p style={{ fontSize: '10px' }} className="font-semibold">Complete</p>
                                                    </div> 
                                                    : 
                                                    <div onClick={() => completeClicked(i)} class="w-20 text-myteal-500 hover:bg-myteal-200 border-2 border-r-1 border-mygray-300 rounded-l-lg px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer flex flex-col items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="30px" width="30px" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
                                                        <p style={{ fontSize: '10px' }} className="font-semibold">Complete</p>
                                                    </div>
                                                }
                                                {incomplete[i] ? 
                                                    <div onClick={() => incompleteClicked(i)} class="w-20 text-white bg-myred-600 border-2 border-l-0 border-mygray-300 rounded-r-lg px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer flex flex-col items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="30px" width="30px" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
                                                        <p style={{ fontSize: '10px' }} className="font-semibold">Incomplete</p>
                                                    </div>
                                                    : 
                                                    <div onClick={() => incompleteClicked(i)} class="w-20 text-myred-600 hover:bg-myred-200 border-2 border-l-0 border-mygray-300 rounded-r-lg px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer flex flex-col items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="30px" width="30px" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
                                                        <p style={{ fontSize: '10px' }} className="font-semibold">Incomplete</p>
                                                    </div>
                                                }
                                            </div>

                                            <div className="btn-sm w-40 md:w-44 h-12 text-white bg-myteal-500 cursor-pointer hover:bg-myteal-100 hover:text-myteal-500 hover:border-1 hover:border-myteal-500" onClick={() => openSoloLink(addressData.soloUrls)}>
                                                <span className="w-5/6 text-left pl-1" >Start Route</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current flex-shrink-0" viewBox="0 0 512 512"><title>Navigate</title>
                                                    <path d="M272 464a16 16 0 01-16-16.42V264.13a8 8 0 00-8-8H64.41a16.31 16.31 0 01-15.49-10.65 16 16 0 018.41-19.87l384-176.15a16 16 0 0121.22 21.19l-176 384A16 16 0 01272 464z"/>
                                                </svg>       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
    

    return (
        <div className="p-5 md:p-14 mb-32">
            {content}
        </div>
    );
}

export default SharedIndividualRoutes;

