import React, { useContext, useState } from 'react';
import { AuthContext } from '../../AuthContext'

function SharedResultsBox(props) {
        
    let totalDistance = props.directionsData.totalDistance
    let totalDistanceText = '(' + totalDistance + ' mi)'

    let totalTime = props.directionsData.totalTime
    let totalTimeText = totalTime + ' mins'
    if (totalTime > 60) {
        let hours = parseInt(totalTime / 60)
        let minutes = parseInt(totalTime % 60)
        if (hours === 1) {
            if (minutes !== 0) {
                totalTimeText = hours + ' hr, ' + minutes + ' mins'
            } else {
                totalTimeText = hours + ' hr'
            }
        } else {
            if (minutes !== 0) {
                totalTimeText = hours + ' hrs, ' + minutes + ' mins'
            } else {
                totalTimeText = hours + ' hrs'
            }
        }
    }

    function startAll() {

        let link = props.directionsData.curatedLinks.googleUrl
        if (props.mapSelected.googleMaps) {

            if (props.directionsData.routeOrder.length <= 25) {
                link = props.directionsData.curatedLinks.googleUrl
            } else {
                if (props.directionsData.splitUrls && props.directionsData.splitUrls.google) {
                    props.setSplitUrlModalOpen(true)
                } else {
                    alert('Google Maps has a 25 address limit. Please navigate using each \'Start Route\' button!')
                }
                return
            }

        } else if (props.mapSelected.appleMaps) {

            if (props.directionsData.routeOrder.length <= 15) {
                link = props.directionsData.curatedLinks.appleUrl
            } else {
                if (props.directionsData.splitUrls && props.directionsData.splitUrls.apple) {
                    props.setSplitUrlModalOpen(true)
                } else {
                    // for routes between 15 and 25, the db method is not used and therfore they do not get the segmented routes for now
                    alert('Apple Maps has a 15 address limit. Please navigate using each \'Start Route\' button!')
                }
                return
            }

        } else if (props.mapSelected.waze) {
            alert('Waze does not support multi-stop navigation. Please select a different navigation app, or navigate using each \'Start Route\' button')
            return
        }

        console.log(link)
        window.open(link, '_blank');
    }


    return (
        <div className="results-container-shared fadeInUp">
            <div className="results-inner-container flex items-center">
                <div className="flex items-center md:mb-5 mb-0">
                    <div className="text-2xl md:text-2xl text-white font-bold leading-snug tracking-tight mt-6 ml-6">{totalTimeText}</div>
                    <div className="text-1xl md:text-1xl text-gray-400 font-bold leading-snug tracking-tight mt-7 ml-1">{totalDistanceText}</div>
                </div>
                
                <div className="flex items-center mt-6 ml-auto">
                    <div onClick={startAll} className="w-full md:w-44 mb-5 btn-sm mx-5 px-6 text-gray-800 bg-myteal-500 cursor-pointer hover:bg-mygray-400 hover:text-myteal-500 hover:border-1 hover:border-myteal-500">
                        <span className="text-lg">Start All</span>
                        <svg className="w-4 h-4 fill-current flex-shrink-0 ml-3 -mr-1" fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                        </svg>        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SharedResultsBox;

