import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../AuthContext'

function FeedbackModal(props) {

    const { currentUser, submitUserFeedback } = useContext(AuthContext);

    const handleScoreClick = (score) => {
        setScore(score)
        setQuestionNum(2)
        // Handle the score submission or storage here
    };

    const getButtonColor = (score) => {
        if (score <= 6) return 'bg-red-400 hover:bg-red-600';  // Red for scores 0-6
        if (score <= 8) return 'bg-yellow-500 hover:bg-yellow-700';  // Yellow for scores 7-8
        return 'bg-green-500 hover:bg-green-700';  // Green for scores 9-10
    };

    const [questionNum, setQuestionNum] = useState(1)
    const [score, setScore] = useState(1)
    const [input, setInput] = useState('');

    function submit() {
        submitUserFeedback(currentUser, score, input)
        props.setFeedbackModalOpen(false)
    }

    function close() {
        submitUserFeedback(currentUser, score, input)
        props.setFeedbackModalOpen(false)
    }

  return (
    <div className="modalBackground">
        <div className="modalContainer feedback">

            {questionNum == 1 &&
                <div>
                    <div className="body text-2xl text-gray-600 mb-5 mt-8 font-bold">Enjoying Routora?</div>
                    <div className="body text-xl text-gray-600 mb-10">How likely are you to recommend us to others?</div>

                    <div className="flex flex-wrap justify-center items-center mt-4 space-x-2">
                        <span className="text-gray-600">Not at all likely</span>
                        {Array.from({ length: 11 }, (_, i) => (
                            <button
                                key={i}
                                onClick={() => handleScoreClick(i)}
                                className={`text-white font-bold py-2 px-4 rounded ${getButtonColor(i)}`}
                            >
                                {i}
                            </button>
                        ))}
                        <span className="text-gray-600">Very likely</span>
                    </div>
                </div>
            }

            {questionNum == 2 &&
                <div>
                    {score <= 8 ? 
                        <div className="body text-xl text-gray-600 mb-5 mt-5">What could we do to better enhance your experience?</div>
                        :
                        <div className="body text-xl text-gray-600 mb-5 mt-5">Awesome! Would you mind sharing what you love most?</div>
                    }
                
                    <textarea
                        className="textarea w-full rounded-lg p-3 h-20"
                        placeholder="Answer"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    ></textarea>
                
                    <div className="flex justify-center mt-10 space-x-3">
                        <button
                            onClick={close}
                            id="cancelBtn"
                            className="btn-sm text-gray-100 bg-gray-300 hover:bg-gray-400 cursor-pointer w-44 rounded-lg"
                        >
                            Close
                        </button>
                        <button
                            onClick={submit}
                            className="btn-sm text-gray-100 bg-blue-500 hover:bg-blue-600 cursor-pointer w-44 rounded-lg"
                        >
                            Submit Feedback
                        </button>
                    </div>
                </div>
            
            }

        </div>
    </div>
  );
}

export default FeedbackModal;