import React, { useEffect } from 'react'
import Header from '../../partials/Header.js'
// examples of react-markdown -> https://www.copycat.dev/blog/react-markdown/
import ReactMarkdown from 'react-markdown';



function TermsConditions() {

    const content = 
    `

Welcome to Routora! These Terms and Conditions govern your use of our mobile application and its related services. By accessing or using our app, you agree to comply with these terms. Please read them carefully.

## **App Description**

Routora is a route optimization tool that helps you find the fastest and most efficient multi-stop routes. By entering a list of addresses, our app will optimize the order of stops, calculate fuel costs, provide driving, cycling, and walking routes, and save previously generated routes. After optimization, you can navigate using your preferred navigation app.

## **Prohibited Conduct**

You agree not to engage in any prohibited conduct while using our app. Prohibited conduct includes using the app unlawfully, harassing or intimidating other users, impersonating someone else, spamming, reverse engineering the app, and developing third-party apps that interact with our app.

## **Scope of License**

Licensor grants to you a nontransferable license to use the Licensed Application on any Apple-branded products that you own or control and as permitted by the Usage Rules. The terms of this Standard EULA will govern any content, materials, or services accessible from or purchased within the Licensed Application as well as upgrades provided by Licensor that replace or supplement the original Licensed Application, unless such upgrade is accompanied by a Custom EULA. Except as provided in the Usage Rules, you may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time. You may not transfer, redistribute or sublicense the Licensed Application and, if you sell your Apple Device to a third party, you must remove the Licensed Application from the Apple Device before doing so. You may not copy (except as permitted by this license and the Usage Rules), reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof (except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open-sourced components included with the Licensed Application).

## **Intellectual Property**

Routora is a registered trademark, and all content, including intellectual property, graphics, written content, software code, and other components of our app, are owned by Routora Inc. We reserve all rights to our content. Any unauthorized use or reproduction is strictly prohibited.

## **Consent to Use of Data**

You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Licensed Application. Licensor may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.

## **Termination**

We reserve the right to terminate or suspend your account at any time without prior notice or liability if you violate these Terms and Conditions or engage in any prohibited conduct. You may also terminate your account at any time by contacting us.

## **DMCA**

We respect intellectual property rights. If you believe that any content on our app infringes your copyright, please notify us by sending an email to **[routorainc@gmail.com](mailto:routorainc@gmail.com)**. We will promptly review and address any infringement claims.

## **Disclaimer of Warranties**

You expressly acknowledge and agree that use of the licensed application is at your sole risk. To the maximum extent permitted by applicable law, the licensed application and any services performed or provided by the licensed application are provided "as is" and “as available,” with all faults and without warranty of any kind, and licensor hereby disclaims all warranties and conditions with respect to the licensed application and any services, either express, implied, or statutory, including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose, of accuracy, of quiet enjoyment, and of noninfringement of third-party rights. No oral or written information or advice given by licensor or its authorized representative shall create a warranty. Should the licensed application or services prove defective, you assume the entire cost of all necessary servicing, repair, or correction. Some jurisdictions do not allow the exclusion of implied warranties or limitations on applicable statutory rights of a consumer, so the above exclusion and limitations may not apply to you.

## **Payments and Plan Changes**

If you are using a free version of our service, it really is free: we do not ask for your credit card and do not sell your data. For paid services that offer a free trial in our app, we explain the length of that free trail upon sign up. After the trial has been completed you will be charged to continue using the service. 

## **Limitation of Liability**

To the extent not prohibited by law, in no event shall licensor be liable for personal injury or any incidental, special, indirect, or consequential damages whatsoever, including, without limitation, damages for loss of profits, loss of data, business interruption, or any other commercial damages or losses, arising out of or related to your use of or inability to use the licensed application, however caused, regardless of the theory of liability (contract, tort, or otherwise) and even if licensor has been advised of the possibility of such damages. Some jurisdictions do not allow the limitation of liability for personal injury, or of incidental or consequential damages, so this limitation may not apply to you. In no event shall Licensor’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.

## **Export Compliance and Prohibited Usage**

You may not use or otherwise export or re-export the Licensed Application except as authorized by United States law and the laws of the jurisdiction in which the Licensed Application was obtained. In particular, but without limitation, the Licensed Application may not be exported or re-exported (a) into any U.S.-embargoed countries or (b) to anyone on the U.S. Treasury Department's Specially Designated Nationals List or the U.S. Department of Commerce Denied Persons List or Entity List. By using the Licensed Application, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use these products for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture, or production of nuclear, missile, or chemical or biological weapons.

## **Government End User Rights**

The Licensed Application and related documentation are "Commercial Items", as that term is defined at 48 C.F.R. §2.101, consisting of "Commercial Computer Software" and "Commercial Computer Software Documentation", as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein. Unpublished-rights reserved under the copyright laws of the United States.

## **Governing Law and Jurisdiction**

Except to the extent expressly provided in the following paragraph, this Agreement and the relationship between you and Apple shall be governed by the laws of the State of California, excluding its conflicts of law provisions. You and Apple agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Santa Clara, California, to resolve any dispute or claim arising from this Agreement. If (a) you are not a U.S. citizen; (b) you do not reside in the U.S.; (c) you are not accessing the Service from the U.S.; and (d) you are a citizen of one of the countries identified below, you hereby agree that any dispute or claim arising from this Agreement shall be governed by the applicable law set forth below, without regard to any conflict of law provisions, and you hereby irrevocably submit to the non-exclusive jurisdiction of the courts located in the state, province or country identified below whose law governs:

If you are a citizen of any European Union country or Switzerland, Norway or Iceland, the governing law and forum shall be the laws and courts of your usual place of residence.

Specifically excluded from application to this Agreement is that law known as the United Nations Convention on the International Sale of Goods.
    `

    const customRenderers = {
        strong: (props) => <strong style={{ fontWeight: 'bold' }}>{props.children}</strong>,
        em: (props) => <em style={{ fontStyle: 'italic' }}>{props.children}</em>,
        u: (props) => <u>{props.children}</u>,
        link: (props) => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
    };

    return (
        <div>
            <Header></Header>
            <div className="h-20"></div>

            <div className="px-10 xl:px-80">
                <h4 className="text-3xl md:text-5xl pt-24 text-myblue-900 font-bold leading-snug tracking-tight flex"> Routora App Terms and Conditions</h4>

                {/* <p className="text-gray-700 text-md md:text-lg pb-6 font-medium text-left whitespace-pre-line">{blogContent.content}</p> */}
                <ReactMarkdown className="text-gray-700 text-md md:text-lg pb-6 font-medium text-left whitespace-pre-line">{content}</ReactMarkdown>
            </div>

            <div className="h-10"></div>
            
        </div>
    );
}

export default TermsConditions;