// wont be used once transitioned to SSR (server side rendering) for instant blog loads

import React, { useContext, useState, useEffect } from 'react'
import Header from '../../partials/Header.js'
import { useHistory, useParams } from 'react-router-dom';
// examples of react-markdown -> https://www.copycat.dev/blog/react-markdown/
import ReactMarkdown from 'react-markdown';

import { AuthContext } from '../../AuthContext.js'
import './AllBlog.css'


function Blog() {

    const { id } = useParams();
    const { getBlogData, updateBlogViewCount } = useContext(AuthContext);
    const [blogContent, setBlogContent] = useState(
        {
            title: "",
            content: "",
            thumbnailUrl: "",
            timestamp: ""
        }
    );

    console.log('PARAMS ID -> ' + id)

    useEffect(async () => {

        let currBlogData = await getBlogData(id)
        if (!currBlogData) {
            alert('No Records Shown')
        } else {
            // firestore automatically stores \n, but if i manually enter into firebase it removes it, so i can manually enter in \n but firebase stores it as \\n so just replacing that
            currBlogData.content = currBlogData.content.replaceAll('\\n', '\n');
            setBlogContent(currBlogData)
            
            // increment view count
            updateBlogViewCount(id)
        }
        
    }, []);

    const customRenderers = {
        strong: (props) => <strong style={{ fontWeight: 'bold' }}>{props.children}</strong>,
        em: (props) => <em style={{ fontStyle: 'italic' }}>{props.children}</em>,
        u: (props) => <u>{props.children}</u>,
        link: (props) => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
    };

    return (
        <div>
            <Header></Header>
            <div className="h-20"></div>

            <img
                src={blogContent.thumbnailUrl}
                className="w-full"
            ></img>
            <div className="px-10 xl:px-80">
                <h4 className="text-3xl md:text-5xl pt-24 text-myblue-900 font-bold leading-snug tracking-tight flex">{blogContent.title}</h4>
                <div className="flex py-6">
                    <p className="text-gray-400 text-lg font-bold">{blogContent.timestamp}</p>
                    <h5 className="reading-time-big">{Math.ceil(blogContent.content.length / 1200)} MINUTE READ</h5>
                </div>
                {/* <p className="text-gray-700 text-md md:text-lg pb-6 font-medium text-left whitespace-pre-line">{blogContent.content}</p> */}
                <ReactMarkdown className="text-gray-700 text-md md:text-lg pb-6 font-medium text-left whitespace-pre-line">{blogContent.content}</ReactMarkdown>
            </div>

            <div className="h-10"></div>

            {/* Call to Action Routora Ad in Blog */}
            {/* Section */}
            <div style={{ backgroundColor: 'rgba(246, 249, 251, 1)' }} className="w-full py-14">
                
                {/* Actual Container for CTA */}
                <div className="relative xl:flex bg-myblue-900 rounded-md py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden mx-5 xl:mx-52" data-aos="zoom-y-out">

                    <div className="relative flex flex-col lg:flex-row justify-between items-center xl:w-1/2 pr-4">

                        <div className="text-center lg:text-left lg:max-w-xl">
                            <h3 className="text-2xl md:text-3xl font-bold text-white mb-2">Create faster routes than Google and Apple Maps</h3>
                            <p className="text-gray-300 text-lg mb-6"><a className="underline hover:text-blue-500 cursor-pointer" href="/">Routora</a> is a route planning service that optimally re-orders your multi-stop routes to reduce overall travel time and cost. Get the most efficient path, one route at a time🚗</p>

                            <form className="w-full lg:w-auto">
                                <a className="btn text-white bg-blue-600 hover:bg-blue-700 shadow font-semibold" href="/app-install">Get the App</a>
                            </form>
                        </div>

                    </div>

                    <div className="relative w-full xl:w-1/2 h-72 mt-6 xl:mt-0">
                        <iframe className="w-full h-full" src="https://www.youtube.com/embed/wBu22Jyh9vw?si=Sz3Pb8duQLHEOru9" title="Video" allowFullScreen></iframe>
                    </div>

                </div>
            </div>
            
        </div>
    );
}

export default Blog;