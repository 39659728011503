import React from 'react';

import ExportComponent from './ExportComponent';

function IndividualRoutes(props) {

    let content = (
        <div>
            <div class="md:text-3xl font-bold text-gray-700 leading-snug tracking-tight mb-1">Optimized route order will be shown here👇</div>
            <div class="md:text-2xl text-gray-600">Select your route details above including the start and stop addresses, as well as the addresses of all the stops you plan on visiting. Once complete, click optimize and the new (and fastest) route order will be displayed alongside navigatable google maps links! </div>
        </div>
    )

    function openSoloGoogleMaps(link) {
        window.open(link, '_blank');
    }

    async function openEmailModal() {
        props.setOpenEmailModal(true)
    }

    // code to create link
    function createLink(routeOrder, totalDistance, totalTime) {
        let baseUrl = 'http://routora.com/shared-route/'

        baseUrl += (totalDistance + '+' + totalTime + '/')

        // add addresses
        for (let i = 0; i < routeOrder.length; i++) {
            if (i == routeOrder.length-1) {
                baseUrl += encodeURIComponent(routeOrder[i].address)
            } else {
                baseUrl += encodeURIComponent(routeOrder[i].address) + '+'
            }
        }

        // add coordinates
        baseUrl += '/'
        for (let i = 0; i < routeOrder.length; i++) {
            if (i == routeOrder.length-1) {
                baseUrl += (routeOrder[i].coordinates[0] + ',' + routeOrder[i].coordinates[1])
            } else {
                baseUrl += (routeOrder[i].coordinates[0] + ',' + routeOrder[i].coordinates[1] + '+')
            }
        }

        return baseUrl
    }
    function createRouteIdLink() {
        let baseUrl = 'http://routora.com/shared-route-id/'
        baseUrl += props.routeId

        return baseUrl
    }

    function openFullRoutePreview() {
        // set up url
        let routoraRouteLink

        // if route length is over 25 stops, use id strategy
        if (props.directionsData.routeOrder.length > 25) {
            routoraRouteLink = createRouteIdLink()
        } else { // else create route in link
            routoraRouteLink = createLink(props.directionsData.routeOrder, props.directionsData.totalDistance, props.directionsData.totalTime)
        }
        

        console.log('Route Link', routoraRouteLink)
        window.open(routoraRouteLink, '_blank');
    }

    if (props.directionsData.routeOrder.length > 0) {

        let totalDistance = props.directionsData.totalDistance
        let totalDistanceText = '(' + totalDistance + ' mi)'

        let totalTime = props.directionsData.totalTime
        let totalTimeText = totalTime + ' mins'
        if (totalTime > 60) {
            let hours = parseInt(totalTime / 60)
            let minutes = parseInt(totalTime % 60)
            if (hours === 1) {
                if (minutes !== 0) {
                    totalTimeText = hours + ' hr, ' + minutes + ' mins'
                } else {
                    totalTimeText = hours + ' hr'
                }
            } else {
                if (minutes !== 0) {
                    totalTimeText = hours + ' hrs, ' + minutes + ' mins'
                } else {
                    totalTimeText = hours + ' hrs'
                }
            }
        }

        content = (
            <div className="relative">
                <div className="text-3xl md:text-5xl font-bold leading-snug tracking-tight mb-1 mr-3">{totalTimeText}</div>
                <div className="flex items-center">
                    <div className="text-2xl md:text-4xl font-bold text-gray-700 leading-snug tracking-tight mb-1 mr-3">{totalDistanceText}</div>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" height="75" width="75" viewBox="0 0 24 24"><path fill="#00b28b" d="M11.29,11.46a1,1,0,0,0,1.42,0l3-3A1,1,0,1,0,14.29,7L12,9.34,9.71,7A1,1,0,1,0,8.29,8.46Zm3,1.08L12,14.84l-2.29-2.3A1,1,0,0,0,8.29,14l3,3a1,1,0,0,0,1.42,0l3-3a1,1,0,0,0-1.42-1.42Z"/></svg> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="70px" height="70px" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill="#00b28b" stroke="none">
                        <path d="M2219 2579 c-34 -13 -55 -59 -40 -88 18 -32 39 -39 107 -33 62 5 64 4 100 -32 l37 -37 -7 -67 c-6 -60 -4 -70 13 -86 12 -11 31 -16 53 -14 43 4 68 51 68 126 0 138 -107 243 -244 241 -34 0 -73 -5 -87 -10z"/>
                        <path d="M1850 2443 c-8 -3 -259 -249 -557 -547 -484 -482 -543 -544 -543 -572 0 -36 26 -64 61 -64 19 0 148 124 572 548 555 555 566 568 539 608 -7 10 -47 35 -54 33 -2 0 -10 -3 -18 -6z"/>
                        <path d="M1983 2297 c-70 -70 -93 -100 -93 -119 0 -30 41 -68 72 -68 12 0 60 39 114 93 78 77 94 98 94 123 0 38 -27 64 -65 64 -23 0 -48 -19 -122 -93z"/>
                        <path d="M1927 1912 c-161 -161 -207 -213 -207 -233 0 -33 18 -55 54 -63 27 -7 42 6 238 201 115 114 211 218 214 231 10 35 -20 72 -59 72 -29 0 -55 -23 -240 -208z"/>
                        <path d="M1800 2082 c-29 -8 -360 -346 -360 -367 0 -36 28 -65 63 -65 29 0 53 20 205 173 105 106 172 181 172 193 0 42 -43 77 -80 66z"/>
                        <path d="M831 1756 c-210 -209 -234 -237 -234 -266 0 -37 23 -60 62 -60 21 0 76 50 259 233 205 206 232 236 232 264 0 35 -29 63 -64 63 -13 0 -106 -86 -255 -234z"/>
                        <path d="M2308 1962 c-39 -24 -1071 -1070 -1081 -1094 -4 -12 -2 -31 6 -47 9 -20 21 -27 48 -29 28 -2 41 3 69 31 19 19 263 264 543 545 426 430 507 516 507 539 0 34 -8 46 -37 62 -27 14 -23 14 -55 -7z"/>
                        <path d="M517 1722 c-109 -110 -127 -132 -127 -159 0 -35 29 -63 65 -63 27 0 285 259 285 286 0 35 -29 64 -63 64 -28 0 -49 -17 -160 -128z"/>
                        <path d="M1243 1528 c-181 -177 -456 -456 -465 -472 -23 -40 26 -95 74 -83 36 10 568 548 568 575 0 27 -42 72 -66 72 -9 0 -59 -42 -111 -92z"/>
                        <path d="M1347 1322 c-176 -177 -277 -285 -277 -298 0 -35 28 -64 63 -64 29 0 62 29 306 274 238 238 274 278 274 305 0 40 -19 61 -57 61 -28 0 -63 -31 -309 -278z"/>
                        <path d="M1614 954 c-193 -194 -234 -240 -234 -263 0 -35 28 -61 66 -61 24 0 65 36 261 233 249 248 258 262 213 307 -44 44 -57 35 -306 -216z"/>
                        <path d="M858 823 c-110 -109 -128 -131 -128 -158 0 -39 29 -68 64 -63 17 2 69 46 156 132 140 139 155 167 108 200 -46 33 -65 22 -200 -111z"/>
                        <path d="M568 842 c-106 -106 -118 -132 -78 -172 37 -37 59 -26 168 83 108 108 122 136 84 170 -41 37 -70 23 -174 -81z"/>
                        <path d="M1572 637 c-78 -79 -132 -142 -132 -153 0 -35 29 -64 63 -64 28 0 49 17 159 128 69 70 129 136 132 148 9 30 -28 74 -63 74 -21 0 -54 -27 -159 -133z"/>
                        <path d="M548 512 c-85 -85 -96 -114 -58 -152 36 -36 60 -26 148 62 88 87 98 112 62 148 -38 38 -67 27 -152 -58z"/>
                        </g>
                    </svg> */}
                </div>
                <div className="flex md:text-sm text-xs md:absolute top-0 right-0 p-2 bg-white border border-gray-300 mb-10 md:mb-0 md:w-80 w-full"> {/* This div will be positioned in the top right corner */}
                    <ExportComponent
                        directionsData={props.directionsData}
                    ></ExportComponent>
                    <p className="font-semibold text-gray-700 ml-2 mr-2">|</p>
                    <div onClick={openEmailModal} className="flex text-gray-700 hover:text-blue-500 cursor-pointer">
                        <p className="font-semibold">Share</p>
                        <svg style={{width: '15px', height: '15px', marginTop: '3px', marginLeft: '2px'}} className="fill-current flex-shrink-0 mr-1 mt-1 hidden md:block" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="share">
                            <path d="m21.707 11.293-8-8A1 1 0 0 0 12 4v3.545A11.015 11.015 0 0 0 2 18.5V20a1 1 0 0 0 1.784.62 11.456 11.456 0 0 1 7.887-4.049c.05-.006.175-.016.329-.026V20a1 1 0 0 0 1.707.707l8-8a1 1 0 0 0 0-1.414ZM14 17.586V15.5a1 1 0 0 0-1-1c-.255 0-1.296.05-1.562.085a14.005 14.005 0 0 0-7.386 2.948A9.013 9.013 0 0 1 13 9.5a1 1 0 0 0 1-1V6.414L19.586 12Z"/>
                        </svg>
                    </div>
                    <p className="font-semibold text-gray-700 md:ml-0 ml-2 mr-2">|</p>
                    <div onClick={openFullRoutePreview} className="flex text-gray-700 hover:text-blue-500 cursor-pointer">
                        <p className="font-semibold">Full Route Preview</p>
                        <svg style={{width: '10px', height: '10px', marginTop: '6px'}} className="fill-current flex-shrink-0 ml-1 mr-1 hidden md:block" fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                        </svg>  
                    </div>
                </div>
                {
                    props.directionsData.routeOrder.map((addressData, i) =>
                        <div>
                            <div className="items-center text-lg p-6 rounded border transition duration-300 ease-in-out mb-3 mt-3 bg-white shadow-md border-gray-200 hover:shadow-lg cursor-pointer">
                                <div className="flex items-center">
                                    {(i == 0 || i == props.directionsData.routeOrder.length-1) ? 
                                        <div className="mr-3 w-16">
                                            { i == 0 ? 
                                                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon w-11 h-11" fill="#338CF5" viewBox="0 0 512 512"><title>Home</title><path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z"/><path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z"/></svg> 
                                            : 
                                                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon w-12 h-12" fill="#338CF5" viewBox="0 0 512 512"><title>Flag</title><path d="M80 480a16 16 0 01-16-16V68.13a24 24 0 0111.9-20.72C88 40.38 112.38 32 160 32c37.21 0 78.83 14.71 115.55 27.68C305.12 70.13 333.05 80 352 80a183.84 183.84 0 0071-14.5 18 18 0 0125 16.58v219.36a20 20 0 01-12 18.31c-8.71 3.81-40.51 16.25-84 16.25-24.14 0-54.38-7.14-86.39-14.71C229.63 312.79 192.43 304 160 304c-36.87 0-55.74 5.58-64 9.11V464a16 16 0 01-16 16z"/></svg>
                                            }
                                        </div>
                                    : 
                                        <div className="text-5xl :text-5xl font-bold leading-snug tracking-tight mr-3 w-16">{i}</div>
                                    }
                                    <div className="block md:flex md:w-full">
                                        <div className="md:text-2xl md:w-9/12 text-gray-500 mb-1">{addressData.address}</div>
                                        <div className="btn-sm w-44 md:absolute right-36 text-white bg-myteal-500 cursor-pointer hover:bg-myteal-100 hover:text-myteal-500 hover:border-1 hover:border-myteal-500" onClick={() => openSoloGoogleMaps(addressData.soloUrls.googleUrl)}>
                                            <span className="w-5/6 text-left pl-1" >Start Route</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current flex-shrink-0" viewBox="0 0 512 512"><title>Navigate</title>
                                                <path d="M272 464a16 16 0 01-16-16.42V264.13a8 8 0 00-8-8H64.41a16.31 16.31 0 01-15.49-10.65 16 16 0 018.41-19.87l384-176.15a16 16 0 0121.22 21.19l-176 384A16 16 0 01272 464z"/>
                                            </svg>       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
    

    return (
        <div id="directions-box">
            {content}
        </div>
    );
}

export default IndividualRoutes;

