import React, { useContext, useEffect } from 'react'

import Header from '../partials/Header';
import Testimonials from '../partials/Testimonials';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import Values from '../partials/Values'
import RoutoraWebDemo from '../partials/RoutoraWebDemo';
import WebGrid from '../mycomponents/RoutoraWebVisuals/WebGrid';
import RoutoraOnWeb from '../partials/RoutoraOnWeb'


function WebIntro() {

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />
      <div className="h-16"></div>

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <RoutoraWebDemo
            isWebIntro={true}
        ></RoutoraWebDemo>
        <RoutoraOnWeb
            isHomeWeb={true}
        ></RoutoraOnWeb>
        <WebGrid
            isHomeWeb={true}
        ></WebGrid>
        <Testimonials />
        <div className="mt-28">
          <Newsletter />
        </div>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default WebIntro;