import React, { useContext, useState, useEffect } from 'react'
import Axios from 'axios';
import Header from '../../partials/Header.js'
import { useHistory, useParams } from 'react-router-dom';
import AppInstructions from '../../partials/AppInstructions.js';
import Modal from '../Modal/Modal'
import Footer from '../../partials/Footer.js'

import { AuthContext } from '../../AuthContext.js'

import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

function AppInstall() {

    const { utmLinkHit, setUTMCode, utmHit, UTMCode } = useContext(AuthContext);
    const { utm } = useParams();

    // utm is if appinstall/:utm and UTMCode is if utm was tracked on another page like home and they navigated here

    useEffect(() => {

        if (utm) {
            console.log('UTM -> ' + utm)
            setUTMCode(utm)
            utmHit(utm)
        } else if (UTMCode) {
            console.log('UTM from Home -> ' + UTMCode)
        }
        
    }, []);

    const [isAndroid, toggle] = useState(false)
    const toggleClicked = () => {
        toggle(!isAndroid)
    }

    function clickAppStore() {
        if (utm) {
            utmLinkHit(utm, 'ios')
        } else if (UTMCode) {
            utmLinkHit(UTMCode, 'ios')
        }

        // facebook tracking
        try {
            if (window.fbq) {
                window.fbq('trackCustom', 'DownloadAppClick', {
                    platform: 'iOS'
                });
            }
        } catch (error) {
            console.log('Error in tracking ios download in FB Pixel', error);
        }

        // google tracking
        try {
            window.gtag && window.gtag('event', 'ios_click', {
                'event_category': 'App Downloads',
                'event_label': 'iOS',
                'value': 0
            });
            console.log('iOS Click')
        } catch (error) {
            console.log('Error in tracking trial start in Google Analytics', error);
        }

        // amplitude tracking
        const eventProperties = {
            store: 'iOS',
        };
        amplitude.track('App Download (Click)', eventProperties);
        

        window.open("https://apps.apple.com/us/app/routora/id6450558064", '_blank');
    }
    function clickPlayStore() {
        if (utm) {
            utmLinkHit(utm, 'android')
        } else if (UTMCode) {
            utmLinkHit(UTMCode, 'android')
        }

        // facebook tracking
        try {
            if (window.fbq) {
                window.fbq('trackCustom', 'DownloadAppClick', {
                    platform: 'Android'
                });
            }
        } catch (error) {
            console.log('Error in tracking ios download in FB Pixel', error);
        }

        // google tracking
        try {
            window.gtag && window.gtag('event', 'android_click', {
                'event_category': 'App Downloads',
                'event_label': 'Android',
                'value': 0
            });
            console.log('Android Click')
        } catch (error) {
            console.log('Error in tracking trial start in Google Analytics', error);
        }

        // amplitude tracking
        const eventProperties = {
            store: 'Android',
        };
        amplitude.track('App Download (Click)', eventProperties);

        window.open("https://play.google.com/store/apps/details?id=com.routora.app&pcampaignid=web_share", '_blank');
    }

    return (
        <div>
            <Header></Header>
            <div className="buffer-100"></div>

            <div className="relative max-w-4xl mx-auto px-4 sm:px-6">
                <div className="pb-4 md:pb-7"></div>
                {/* Section header */}
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="h1 mb-2 text-myblue-900">Get the App</h2>
                    <div className="md:flex mx-auto text-center items-center justify-center">
                        <button onClick={clickAppStore} type="button" class="flex items-center justify-center md:mx-0 mx-auto w-48 mt-3 text-white bg-black hover:bg-blue-500 h-14 rounded" href="https://apps.apple.com/us/app/routora/id6450558064" target="_blank">
                            <div class="mr-3">
                                <svg viewBox="0 0 384 512" width="30">
                                    <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                                    </path>
                                </svg>
                            </div>
                            <div>
                                <div class="text-xs">
                                    Download on the
                                </div>
                                <div class="-mt-1 font-sans text-xl font-semibold">
                                    App Store
                                </div>
                            </div>
                        </button>
                        <button onClick={clickPlayStore} type="button" class="flex items-center justify-center md:mx-0 md:ml-4 mx-auto w-48 mt-3 text-white bg-black hover:bg-blue-500 rounded h-14" href="https://play.google.com/store/apps/details?id=com.routora.app&pcampaignid=web_share" target="_blank">
                            <div class="mr-3">
                                <svg viewBox="30 336.7 120.9 129.2" width="30">
                                    <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z">
                                    </path>
                                    <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z">
                                    </path>
                                    <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z">
                                    </path>
                                    <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z">
                                    </path>
                                </svg>
                            </div>
                            <div>
                                <div class="text-xs">
                                    Get it on
                                </div>
                                <div class="-mt-1 font-sans text-xl font-semibold">
                                    Google Play
                                </div>
                            </div>
                        </button>
                    </div>

                    <p className="text-xl text-gray-600 mt-4 hidden md:block">Or scan the QR Code below to download the app.</p>
                    <p className="text-xl text-gray-600 mt-4 md:hidden">Join tens of thousands of drivers maximizing their savings on the road.</p>
                </div>
                {/* Toggle for iOS/Android */}
                <div className="flex items-center justify-center mt-5 md:mb-0 mb-3">
                    <div className="w-25">
                        { isAndroid ? 
                            <div className="flex ml-10">
                                <svg width="13.5" height="18" className="mt-0.5" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.811 8.6829C8.649 9.07238 8.4582 9.43088 8.2368 9.76029C7.9344 10.2098 7.6878 10.5205 7.497 10.6931C7.2018 10.9766 6.885 11.122 6.5457 11.1295C6.3027 11.1295 6.0084 11.0573 5.6673 10.9109C5.3244 10.7654 5.0094 10.6931 4.7214 10.6931C4.419 10.6931 4.095 10.7654 3.7485 10.9109C3.4011 11.0573 3.1221 11.1333 2.9079 11.1408C2.583 11.1549 2.259 11.0057 1.935 10.6922C1.7289 10.5045 1.4706 10.1826 1.161 9.7265C0.8289 9.23943 0.5562 8.67539 0.3429 8.03159C0.1152 7.33617 0 6.66327 0 6.01102C0 5.26399 0.1548 4.62018 0.4644 4.08055C0.7083 3.64697 1.0323 3.30536 1.4373 3.05384C1.8432 2.80327 2.2806 2.67469 2.7531 2.66625C3.0114 2.66625 3.3498 2.74977 3.771 2.91307C4.1904 3.07731 4.4604 3.16083 4.5783 3.16083C4.6665 3.16083 4.9662 3.06323 5.4729 2.86896C5.9526 2.68877 6.3576 2.61463 6.6897 2.64372C7.5888 2.71974 8.2638 3.08857 8.7129 3.7549C7.9092 4.26262 7.5114 4.97399 7.5195 5.88714C7.5267 6.59852 7.7742 7.18977 8.2602 7.65901C8.4807 7.87674 8.7264 8.04567 9 8.16486C8.9406 8.34598 8.8776 8.51773 8.811 8.6829ZM6.75 0.22336C6.75 0.780823 6.5547 1.30075 6.1659 1.78219C5.6961 2.35467 5.1282 2.68502 4.5126 2.6334C4.5045 2.56677 4.5 2.49638 4.5 2.42224C4.5 1.8873 4.7232 1.31482 5.1201 0.846517C5.3181 0.609079 5.5701 0.411997 5.8752 0.255269C6.1803 0.100418 6.4683 0.0150158 6.7392 0C6.7464 0.0741406 6.75 0.14922 6.75 0.22336Z" fill="#EAEAEA"></path>
                                </svg> 
                                <div className="md:text-1xl font-bold text-gray-300 mr-2 ml-1">iOS</div>
                            </div>
                        : 
                            <div className="flex ml-10">
                                <svg width="13.5" height="18" className="mt-0.5" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.811 8.6829C8.649 9.07238 8.4582 9.43088 8.2368 9.76029C7.9344 10.2098 7.6878 10.5205 7.497 10.6931C7.2018 10.9766 6.885 11.122 6.5457 11.1295C6.3027 11.1295 6.0084 11.0573 5.6673 10.9109C5.3244 10.7654 5.0094 10.6931 4.7214 10.6931C4.419 10.6931 4.095 10.7654 3.7485 10.9109C3.4011 11.0573 3.1221 11.1333 2.9079 11.1408C2.583 11.1549 2.259 11.0057 1.935 10.6922C1.7289 10.5045 1.4706 10.1826 1.161 9.7265C0.8289 9.23943 0.5562 8.67539 0.3429 8.03159C0.1152 7.33617 0 6.66327 0 6.01102C0 5.26399 0.1548 4.62018 0.4644 4.08055C0.7083 3.64697 1.0323 3.30536 1.4373 3.05384C1.8432 2.80327 2.2806 2.67469 2.7531 2.66625C3.0114 2.66625 3.3498 2.74977 3.771 2.91307C4.1904 3.07731 4.4604 3.16083 4.5783 3.16083C4.6665 3.16083 4.9662 3.06323 5.4729 2.86896C5.9526 2.68877 6.3576 2.61463 6.6897 2.64372C7.5888 2.71974 8.2638 3.08857 8.7129 3.7549C7.9092 4.26262 7.5114 4.97399 7.5195 5.88714C7.5267 6.59852 7.7742 7.18977 8.2602 7.65901C8.4807 7.87674 8.7264 8.04567 9 8.16486C8.9406 8.34598 8.8776 8.51773 8.811 8.6829ZM6.75 0.22336C6.75 0.780823 6.5547 1.30075 6.1659 1.78219C5.6961 2.35467 5.1282 2.68502 4.5126 2.6334C4.5045 2.56677 4.5 2.49638 4.5 2.42224C4.5 1.8873 4.7232 1.31482 5.1201 0.846517C5.3181 0.609079 5.5701 0.411997 5.8752 0.255269C6.1803 0.100418 6.4683 0.0150158 6.7392 0C6.7464 0.0741406 6.75 0.14922 6.75 0.22336Z" fill="#7F7F7F"></path>
                                </svg> 
                                <div className="md:text-1xl font-bold text-gray-500 mr-2 ml-1">iOS</div>
                            </div>
                        }
                    </div>
                    <label class="toggle" for="myToggle">
                        <input class="toggle__input" name="" type="checkbox" id="myToggle" onClick={toggleClicked}></input>
                        <div class="toggle__fill"></div>
                    </label>
                    <div className="w-25">
                        <div className="md:text-1xl font-bold text-gray-500 ml-2">
                            { isAndroid ? 
                                <div className="flex">
                                    <svg className="mt-2" width="19.2" height="10.8" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.26166 6.72909C4.62279 6.72909 4.91772 6.43077 4.91772 6.06547C4.91772 5.70018 4.62279 5.40185 4.26166 5.40185C3.90052 5.40185 3.60559 5.70018 3.60559 6.06547C3.60559 6.43077 3.90052 6.72909 4.26166 6.72909ZM11.5325 6.72909C11.8937 6.72909 12.1886 6.43077 12.1886 6.06547C12.1886 5.70018 11.8937 5.40185 11.5325 5.40185C11.1714 5.40185 10.8765 5.70018 10.8765 6.06547C10.8765 6.43077 11.1714 6.72909 11.5325 6.72909ZM4.02692 2.71694L2.71479 0.41559C2.63654 0.281649 2.68469 0.111179 2.81711 0.0381199C2.94953 -0.0410271 3.11806 0.00767873 3.19029 0.14162L4.52047 2.47341C5.53767 2.00462 6.68127 1.74283 7.90311 1.74283C9.12496 1.74283 10.2686 2.00462 11.2858 2.47341L12.6099 0.14162C12.6882 0.00767873 12.8507 -0.0349389 12.9831 0.0381199C13.1155 0.117267 13.1576 0.281649 13.0854 0.41559L11.7733 2.71694C14.0244 3.95894 15.5652 6.27247 15.7939 9H0.000247002C0.222948 6.27247 1.76982 3.95894 4.02692 2.71694Z" fill="#7F7F7F"></path>
                                    </svg>
                                    <div className="md:text-1xl font-bold text-gray-500 mr-2 ml-1">Android</div>
                                </div>
                            : 
                                <div className="flex">
                                    <svg className="mt-2" width="19.2" height="10.8" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.26166 6.72909C4.62279 6.72909 4.91772 6.43077 4.91772 6.06547C4.91772 5.70018 4.62279 5.40185 4.26166 5.40185C3.90052 5.40185 3.60559 5.70018 3.60559 6.06547C3.60559 6.43077 3.90052 6.72909 4.26166 6.72909ZM11.5325 6.72909C11.8937 6.72909 12.1886 6.43077 12.1886 6.06547C12.1886 5.70018 11.8937 5.40185 11.5325 5.40185C11.1714 5.40185 10.8765 5.70018 10.8765 6.06547C10.8765 6.43077 11.1714 6.72909 11.5325 6.72909ZM4.02692 2.71694L2.71479 0.41559C2.63654 0.281649 2.68469 0.111179 2.81711 0.0381199C2.94953 -0.0410271 3.11806 0.00767873 3.19029 0.14162L4.52047 2.47341C5.53767 2.00462 6.68127 1.74283 7.90311 1.74283C9.12496 1.74283 10.2686 2.00462 11.2858 2.47341L12.6099 0.14162C12.6882 0.00767873 12.8507 -0.0349389 12.9831 0.0381199C13.1155 0.117267 13.1576 0.281649 13.0854 0.41559L11.7733 2.71694C14.0244 3.95894 15.5652 6.27247 15.7939 9H0.000247002C0.222948 6.27247 1.76982 3.95894 4.02692 2.71694Z" fill="#EAEAEA"></path>
                                    </svg>
                                    <div className="md:text-1xl font-bold text-gray-300 mr-2 ml-1">Android</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="hidden md:block">
                    { isAndroid ? 
                        <img className="md:max-w-none mx-auto rounded-2xl mt-5" src={require('../../images/android-qrcode.png').default} width="250" height="250" alt="Android QR Code" />
                    : 
                        <img className="md:max-w-none mx-auto rounded-2xl mt-5" src={require('../../images/ios-qrcode.png').default} width="250" height="250" alt="Android QR Code" />
                    }
                </div>
                
                <div>
                    { isAndroid ? 
                        <div className="relative w-96 mx-auto mt-4">
                            <img className="md:max-w-none mx-auto" src={require('../../images/app-visual-android-2.2.png').default} width="370" height="502" alt="Features bg" />
                            <div className="absolute w-full bottom-52 cursor-pointer animate-float">
                                <img className="md:max-w-none mx-auto" src={require('../../images/savings-visual-1.png').default} width="350" height="502" alt="savings" />
                            </div>
                        </div>
                    : 
                        <div className="relative w-96 mx-auto mt-4">
                            <img className="md:max-w-none mx-auto" src={require('../../images/app-visual-2.2.png').default} width="370" height="502" alt="Features bg" />
                            <div className="absolute w-full bottom-44 cursor-pointer animate-float">
                                <img className="md:max-w-none mx-auto" src={require('../../images/savings-visual-1.png').default} width="350" height="502" alt="savings" />
                            </div>
                        </div>
                    }
                </div>

                {/* <h1 className="h1 text-myblue-900 mt-14 mx-auto text-center">Find the most efficient order of stops with the tap of a button</h1> */}


            </div>
            <div className="buffer-100"></div>
            <AppInstructions></AppInstructions>
            <div className="buffer-100"></div>
            {/*  Site footer */}
            <Footer />
        </div>
    );
}

export default AppInstall;