import { createContext, useState, useEffect } from "react";
import {getGeocode, getLatLng,} from "use-places-autocomplete";
import Drivers from "./Drivers/Drivers";
import LocationEntry from "./LocationEntry/LocationEntry"
import StopEntry from "./StopEntry/StopEntry";
import DriversModal from './Drivers/DriversModal'
import ListModal from '../../mycomponents/Interface/ListModal'
import ImportModal from '../../mycomponents/Interface/ImportModal'

function DataEntry(props) {

    const [openDriversModal, setOpenDriversModal] = useState(false)

    const [importType, setImportType] = useState('address')
    const [listModalOpen, setListModalOpen] = useState(false)
    const [importModalOpen, setImportModalOpen] = useState(false)
    // Import Methods File
    let [buttonTextColors, setButtonTextColors] = useState({address: '#00b28b', url: '#7F7F7F', list: '#7F7F7F', import: '#7F7F7F'})
    let [buttonBGColors, setButtonBGColors] = useState({address: '#e0fff8', url: '#FFFFFF', list: '#FFFFFF', import: '#FFFFFF'})

    async function getCoordinates(address) {
        try {
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          return { lat, lng }
        //   panTo({ lat, lng });
        } catch (error) {
          console.log("😱 Error: ", error);
        }
    };

    async function submitList(validationResults) {

        try {

            if (validationResults.length > 1) {

                // get selected results
                let selectedResults = []
                for (let i = 0; i < validationResults.length; i++) {
                    if (validationResults[i].selected) {
                        selectedResults.push(validationResults[i])
                    }
                }


                // set stop list 
                let list = []
                for (let i = 0; i < selectedResults.length; i++) {
                    list.push(selectedResults[i].formattedAddress)
                }
                list.push('')
                console.log('list', list)
                props.setEnteredStops(list)

                let tempList = {...props.addressCoordinateList}
                let lat
                let lng
                let coordinates
                for (let i = 0; i < list.length; i++) {
                    if (list[i].length > 0) {
                        
                        
                        lat = selectedResults[i].coordinates.lat
                        lng = selectedResults[i].coordinates.lng

                        // if coordinates already retrieved, add those -> else get the coordinates for the given address
                        if (lat !== null && lng !== null) {
                            tempList.stops.push({name: list[i], coordinates: [lat, lng]})
                        } else {
                            coordinates = await getCoordinates(list[i])
                            tempList.stops.push({name: list[i], coordinates: [coordinates.lat, coordinates.lng]})
                        }

                    }
                }
                console.log('temp list', tempList)
                props.setAddressCoordinateList(tempList)

            } else {
                alert('Need more than one stop')
            }
        } catch(err) {
            alert('Error with importing stops, please check if valid')
        }

    }

    return (
        <div className="relative flex flex-col h-screen w-96 border-solid border-r border-gray-300 shadow-xl">
            { listModalOpen &&
                <ListModal
                    setListModalOpen={setListModalOpen}
                    setButtonTextColors={setButtonTextColors}
                    setButtonBGColors={setButtonBGColors}
                    setImportType={setImportType}
                    submitList={submitList}
                ></ListModal>
            }
            { importModalOpen &&
                <ImportModal
                    setImportModalOpen={setImportModalOpen}
                    setButtonTextColors={setButtonTextColors}
                    setButtonBGColors={setButtonBGColors}
                    setImportType={setImportType}
                    submitList={submitList}
                ></ImportModal>
            }
            {openDriversModal &&
                <DriversModal
                    setOpenDriversModal={setOpenDriversModal}
                    selectedDriverList={props.selectedDriverList}
                    setSelectedDriverList={props.setSelectedDriverList}
                    userData={props.userData}
                ></DriversModal>
            }
            <Drivers
                selectedDriverList={props.selectedDriverList}
                setSelectedDriverList={props.setSelectedDriverList}
                setOpenDriversModal={setOpenDriversModal}
                selectedDate={props.selectedDate}
            ></Drivers>
            <LocationEntry
                startAddress={props.startAddress}
                setStartAddress={props.setStartAddress}
                addressCoordinateList={props.addressCoordinateList}
                setAddressCoordinateList={props.setAddressCoordinateList}
            ></LocationEntry>
            <StopEntry
                enteredStops={props.enteredStops}
                setEnteredStops={props.setEnteredStops}
                addressCoordinateList={props.addressCoordinateList}
                setAddressCoordinateList={props.setAddressCoordinateList}
                getCoordinates={getCoordinates}
                setImportType={setImportType}
                setListModalOpen={setListModalOpen}
                setImportModalOpen={setImportModalOpen}
                importType={importType}
                setDriverRoutes={props.setDriverRoutes}
                setButtonTextColors={setButtonTextColors}
                buttonTextColors={buttonTextColors}
                setButtonBGColors={setButtonBGColors}
                buttonBGColors={buttonBGColors}
            ></StopEntry>
            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2">
                { props.isLoading ?
                    <button className="bg-myteal-500 w-48 h-10 h-10 shadow-xl justify-center items-center flex text-white py-4 px-6 rounded hover:bg-myteal-600">
                        <div class="loader">
                            <div class="loading-circle"></div>
                        </div>
                    </button>
                :
                    <button onClick={props.buildRoutes} className="bg-myteal-500 w-48 h-10 shadow-xl justify-center items-center flex text-white py-2 px-6 rounded hover:bg-myteal-600">
                        <p className="mr-1 text-sm font-medium">Build Routes</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"/></svg>
                    </button>
                }
                
            </div>
        </div>
    );
}

export default DataEntry;