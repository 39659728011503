import React, { useState } from 'react';
import './ColumnSelectionModal.css';

function ColumnSelectionModal(props) {
    const [selectedColumn, setSelectedColumn] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission
        props.columnSelected(selectedColumn)
        props.setOpenSelectModal(false)
    };

    return (
        <div className="selectModalBackground">
            <div className="selectModalContainer p-5 flex flex-col">
                <p className="text-gray-700 font-semibold mb-2">
                    'Address' column not detected, please select the column containing addresses.
                </p>
                <form onSubmit={handleSubmit} className="columnSelectionForm flex flex-col flex-grow">
                <div className="flex-grow">
                    {props.columns.map((column, index) => (
                    <div key={index} className="radioOption mb-4">
                        <label className="flex items-center">
                        <input
                            type="radio"
                            name="columnSelection"
                            value={column}
                            checked={selectedColumn === column}
                            onChange={(e) => setSelectedColumn(e.target.value)}
                            className="mr-2"
                        />
                        {column}
                        </label>
                    </div>
                    ))}
                </div>
                <div className="mt-4">
                    <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Continue
                    </button>
                </div>
                </form>
            </div>
        </div>

    );
}

export default ColumnSelectionModal;
