import { useState, useEffect } from "react";
import Autocomplete from '../../../mycomponents/Interface/Autocomplete'
import {getGeocode, getLatLng,} from "use-places-autocomplete";


function LocationEntry(props) {

    const [startLocationContent, setStartLocationContent] = useState('');

    useEffect(() => {
        if (props.startAddress != '') {
            setStartLocationContent(props.startAddress)
        }
    }, [props.startAddress]);

    async function handleStartLocationChange(value){

        let coordinates = await getCoordinates(value)
        let list = {...props.addressCoordinateList}
        list.start = {name: value, coordinates: [coordinates.lat, coordinates.lng]}
        list.end = {name: value, coordinates: [coordinates.lat, coordinates.lng]}
        setStartLocationContent(value)
        props.setStartAddress(value)
        props.setAddressCoordinateList(list)

    }

    async function getCoordinates(address) {
        try {
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          return { lat, lng }
        //   panTo({ lat, lng });
        } catch (error) {
          console.log("😱 Error: ", error);
        }
    };


    return (
        <div className="px-4 border-solid border-gray-300 mt-7">

            <div style={{ marginTop: 9 }} className="float-left text-myteal-500">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" class="ionicon" viewBox="0 0 512 512"><path d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
            </div>
            <Autocomplete
                class="form-input w-11/12 float-right text-gray-800 px-2 mx-1 py-1 pr-8 text-xs hover:bg-gray-100"
                idName="startLocation"
                placeholder="Start Location"
                content={startLocationContent}
                contentFunction={setStartLocationContent}
                handleChange={handleStartLocationChange}
            ></Autocomplete>

        </div>
    );
}

export default LocationEntry;