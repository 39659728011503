import React, { useState, useEffect } from "react";
import Input from "../components/input";
import Dropdown from "../components/dropdown";

const CompanyDetails = (props) => {
  const INDUSTRY_OPTIONS = [
    { label: "Courier/Delivery", value: "Courier/Delivery" },
    { label: "Service", value: "Service" },
    { label: "Sales", value: "Sales" },
    { label: "Health Care", value: "Health Care" },
    { label: "Travel", value: "Travel" },
    { label: "Other", value: "Other" }
  ];
  const ROLE_OPTIONS = [
    { label: "Manager", value: "Manager" },
    { label: "Executive", value: "Executive" },
    { label: "Owner/Founder", value: "Owner/Founder" },
    { label: "Dispatcher", value: "Dispatcher" },
    { label: "Other", value: "Other" }
  ];

  const getValue = (key) => {
    if(props.form != null) {
      return props.form[key]
    }

    return null;
  }

  // Function to handle number inputs and convert to a number
  const handleNumberInput = (e) => {
    const value = e.target.value;

    // Make sure we convert to a number if it's a valid number, otherwise use 0
    const numValue = value !== "" ? Number(value) : 0;

    // Save the number value
    props.saveData("numDrivers", numValue);
  };

  return (
    <div class="px-4">
      <div className="title text-lg font-medium text-black-500 px-4">
        <h1>What's your company called?</h1>
      </div>
      <Input
        label={"Company Name"}
        placeholder={"Enter company name"}
        defaultValue={getValue('companyName')}
        onBlur={(e) => {
          props.saveData("companyName", e.target.value);
        }}
      />
      <Dropdown
        label={"What industry is your company in?"}
        default_option={"Select industry"}
        options={INDUSTRY_OPTIONS}
        defaultValue={getValue('industry')}
        onChange={(e) => {
          props.saveData("industry", e.target.value);
        }}
      />
      <Dropdown
        label={"What is your role?"}
        default_option={"Select role"}
        options={ROLE_OPTIONS}
        defaultValue={getValue('role')}
        onChange={(e) => {
          props.saveData("role", e.target.value);
        }}
      />
      <Input
        label={"How many drivers do you have on your team?"}
        type={"number"}
        placeholder={0}
        defaultValue={getValue('numDrivers')}
        onBlur={handleNumberInput}
      />
    </div>
  );
};

export default CompanyDetails;