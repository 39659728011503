import { useState, useEffect } from "react";
import Autocomplete from "../../../mycomponents/Interface/Autocomplete";
import { getGeocode, getLatLng } from "use-places-autocomplete";

function LocationEntry(props) {
  const [startLocationContent, setStartLocationContent] = useState(props.defaultAddress);

  async function handleStartLocationChange(value) {
    let coordinates = await getCoordinates(value);
    let list = { ...props.addressCoordinateList };
    list.start = {
      name: value,
      coordinates: [coordinates.lat, coordinates.lng],
    };
    list.end = { name: value, coordinates: [coordinates.lat, coordinates.lng] };
    setStartLocationContent(value);
    props.setStartAddress(value);
    props.setAddressCoordinateList(list);
  }

  async function getCoordinates(address) {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      return { lat, lng };
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  }

  return (
    <div className="px-4 border-solid border-gray-300 mt-1">
      <label
        className="block text-gray-600 text-xs font-medium mb-1"
        htmlFor="email"
      >
        {props.label}
      </label>
      <div className="flex space-x-3">
        <div className="flex-none ">
          { props.label == 'Start Location' ? 
            <div style={{ marginTop: 9 }} className=" text-myteal-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                class="ionicon"
                viewBox="0 0 512 512"
              >
                <path
                  d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="32"
                />
                <path
                  d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="32"
                />
              </svg>
            </div>

          : 
            <div style={{ marginTop: 9 }} className=" text-myteal-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" width="15" height="15" viewBox="0 0 512 512"><path d="M80 464V68.14a8 8 0 014-6.9C91.81 56.66 112.92 48 160 48c64 0 145 48 192 48a199.53 199.53 0 0077.23-15.77 2 2 0 012.77 1.85v219.36a4 4 0 01-2.39 3.65C421.37 308.7 392.33 320 352 320c-48 0-128-32-192-32s-80 16-80 16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/></svg>
            </div>
          }
        </div>
        <div className="flex-auto">
          <Autocomplete
            class="form-input w-full text-gray-800 px-2 py-1 pr-8 text-xs hover:bg-gray-100"
            idName="startLocation"
            placeholder={props.placeholder}
            content={startLocationContent}
            contentFunction={setStartLocationContent}
            handleChange={handleStartLocationChange}
          />
        </div>
      </div>
    </div>
  );
}

export default LocationEntry;
