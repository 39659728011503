import React, { useEffect } from 'react'
import Header from '../../partials/Header.js'
// examples of react-markdown -> https://www.copycat.dev/blog/react-markdown/
import ReactMarkdown from 'react-markdown';



function AppPrivacyPolicy() {

    const content = 
    `

Last Updated: Feb 16, 2024

At Routora, we are committed to protecting the privacy and security of our users' information. This Privacy Policy outlines how we collect, use, store, and disclose personal information when you use our mobile app. By using Routora, you agree to the terms and practices described in this policy.

If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at routorainc@gmail.com. You can also visit our website at routora.com

# **Routora Privacy Policy**

This Privacy Policy applies only to our online activities and is valid for visitors to our app with regards to the information that they shared and/or collect in Routora. This policy is not applicable to any information collected offline or via channels other than this website. 

## **Information We Collect**

a. User Profile Information: When you register for Routora using Google, Facebook, Email, or Apple, we collect your name and email address directly from these services. This information is essential for creating your Routora account, associating your saved routes, subscription status, and any other app-related data specifically with you. Additionally, your name and email are displayed on your profile within the app for a more personalized user experience. 
b. Location Data: Routora may request permission to access your device's location to auto-populate your start and end addresses. You can grant or revoke this permission at any time through your device's settings.

We reserve the right to use your email to market our products and inform you about progress.

In the event of an acquisition, we reserve the right to transfer data held to the acquirer. 

## **Use of Information**

a. Personalization: We use your email and name to personalize your Routora experience and provide a seamless user interface.
b. Route Optimization: The addresses you enter into Routora are used solely for the purpose of generating optimized routes based on our advanced algorithms.
c. Communication: We may occasionally send you notifications, updates, or promotional messages related to Routora. You can opt-out of these communications at any time.
d. Analytics: We collect anonymized usage data to analyze app performance, improve our services, and enhance user experience.

## **Data Storage and Security**

a. Data Storage: User information is securely stored in a cloud-based database.
b. Data Security: We implement appropriate technical and organizational measures to protect user data from unauthorized access, disclosure, alteration, or destruction.

## **Third-Party Services**

a. Third-Party Integrations: Routora may integrate with third-party services (such as navigation apps) to provide enhanced functionality. Please note that these services have their own privacy policies and terms of use, and we encourage you to review them.
b. Data Sharing: We do not sell, trade, or rent your personal information to third parties for marketing purposes.

## **User Rights**

a. Access and Update: You can access and update your profile information within the Routora app.
b. Data Deletion: You can request the deletion of your account and associated data by contacting us at routorainc@gmail.com.
c. Opt-Out: You can opt-out of location tracking by adjusting the relevant settings on your device.

## **Third Party Privacy Policies**

Routora’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.

## **CCPA Privacy Rights (Do Not Sell My Personal Information)**

Under the CCPA, among other rights, California consumers have the right to:

Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.

Request that a business delete any personal data about the consumer that a business has collected.

Request that a business that sells a consumer's personal data, not sell the consumer's personal data.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

## **GDPR Data Protection Rights**

Routora’s legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:

- Routora needs to perform a contract with you
- You have given Routora permission to do so
- Processing your personal information is in Routora’s legitimate interests
- Routora needs to comply with the law

Routora will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.

If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.

We would like to make sure you are fully aware of all of your data protection rights. Every user that falls under above conditions is entitled to the following:

The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.

The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.

The right to erasure – You have the right to request that we erase your personal data, under certain conditions.

The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.

The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.

The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

## **Children's Information**

Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.

Routora does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our app, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.

## **Consent**

By using the Routora mobile app, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.
    `

    const customRenderers = {
        strong: (props) => <strong style={{ fontWeight: 'bold' }}>{props.children}</strong>,
        em: (props) => <em style={{ fontStyle: 'italic' }}>{props.children}</em>,
        u: (props) => <u>{props.children}</u>,
        link: (props) => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
    };

    return (
        <div>
            <Header></Header>
            <div className="h-20"></div>

            <div className="px-10 xl:px-80">
                <h4 className="text-3xl md:text-5xl pt-24 text-myblue-900 font-bold leading-snug tracking-tight flex"> Routora App Privacy Policy</h4>

                {/* <p className="text-gray-700 text-md md:text-lg pb-6 font-medium text-left whitespace-pre-line">{blogContent.content}</p> */}
                <ReactMarkdown className="text-gray-700 text-md md:text-lg pb-6 font-medium text-left whitespace-pre-line">{content}</ReactMarkdown>
            </div>

            <div className="h-10"></div>
            
        </div>
    );
}

export default AppPrivacyPolicy;