import React, { useContext, useState } from 'react';
import './ResultsBox.css'

function ResultsBox(props) {

    function openGoogleMaps() {
        if (props.directionsData.routeOrder.length > 25 && props.directionsData.splitUrls && props.directionsData.splitUrls.google) {
            props.setSplitUrlModalOpen(true)
        } else {
            window.open(props.directionsData.curatedLinks.googleUrl, '_blank');
        }
    }
    function goDirections() {
        // scroll to detailed directions
        if (props.directionsData.routeOrder.length <= 25) {
            const element = document.getElementById('box-directions-gap'); 
            window.scrollTo({
                top:element.offsetTop,
                behavior:"smooth"
            });
        } else {
            alert('Text directions available for routes totalling 25 addresses and below')
        }
    }

    async function openEmailModal() {
        props.setOpenEmailModal(true)
    }

    function sendText() {
        alert('Coming Soon!')
    }

    let content = (
        <div></div>
    )
    if (props.showResultBox === 1) {
        
        let totalDistance = props.directionsData.totalDistance
        let totalDistanceText = '(' + totalDistance + ' mi)'

        let totalTime = props.directionsData.totalTime
        let totalTimeText = totalTime + ' mins'
        if (totalTime > 60) {
            let hours = parseInt(totalTime / 60)
            let minutes = parseInt(totalTime % 60)
            if (hours === 1) {
                if (minutes !== 0) {
                    totalTimeText = hours + ' hr, ' + minutes + ' mins'
                } else {
                    totalTimeText = hours + ' hr'
                }
            } else {
                if (minutes !== 0) {
                    totalTimeText = hours + ' hrs, ' + minutes + ' mins'
                } else {
                    totalTimeText = hours + ' hrs'
                }
            }
        }

        content = (
            <div className="results-container fadeInUp">
                <div className="results-inner-container flex items-center">
                    <div className="flex items-center">
                        <div className="totalTimeResultsText text-2xl :text-2xl text-white font-bold leading-snug tracking-tight mt-6 ml-6">{totalTimeText}</div>
                        <div className="totalDistanceResultsText text-1xl :text-2xl text-gray-400 font-bold leading-snug tracking-tight mt-7 ml-1">{totalDistanceText}</div>
                    </div>
                    <div className="flex items-center mt-6 ml-auto">
                        <div className="directionsResultsText font-medium text-myteal-500 hover:text-myteal-400 flex items-center cursor-pointer" onClick={goDirections}>Directions</div>
                        <div className="mapsResultsButton btn-sm mx-5 px-6 text-gray-800 bg-myteal-500 cursor-pointer hover:bg-mygray-400 hover:text-myteal-500 hover:border-1 hover:border-myteal-500" onClick={openGoogleMaps}>
                            <span className="mapsResultsText">Start All</span>
                            <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 -mr-1" fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                            </svg>        
                        </div>
                        <div class="shareOptions flex justify-center rounded-lg text-lg mr-6" role="group">
                            <div onClick={sendText} class="bg-mygray-500 text-myteal-500 hover:bg-mygray-400 border-2 border-r-1 border-myteal-500 rounded-l-lg px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px" viewBox="0 0 24 24"><path fill="#00B28B" d="M12.71,16.29l-.15-.12a.76.76,0,0,0-.18-.09L12.2,16a1,1,0,0,0-.91.27,1.15,1.15,0,0,0-.21.33,1,1,0,0,0,1.3,1.31,1.46,1.46,0,0,0,.33-.22,1,1,0,0,0,.21-1.09A1,1,0,0,0,12.71,16.29ZM16,2H8A3,3,0,0,0,5,5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V5A1,1,0,0,1,8,4h8a1,1,0,0,1,1,1Z"/></svg>
                            </div>
                            <div onClick={openEmailModal} class="bg-mygray-500 text-myteal-500 hover:bg-mygray-400 border-2 border-l-0 border-myteal-500 rounded-r-lg px-4 py-1 mx-0 outline-none focus:shadow-outline cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px" viewBox="0 0 24 24"><path fill="#00B28B" d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (props.showResultBox === 2) {
        content = (
            <div className="results-container fadeOutDown"></div>
        )
    }


    return (
        <div>
            {content}
        </div>
    );
}

export default ResultsBox;

