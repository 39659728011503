import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import Header from '../../partials/Header'
import Modal from '../Modal/Modal'
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../AuthContext'
import CancelSubForm from '../ProfilePage/CancelSubForm'

function ProfilePage() {

  const { currentUser, currentUserTier, checkSubStatus, updateUserTier, updateSubId, getSubId } = useContext(AuthContext);

  const [mainMessage, setMainMessage] = useState('')
  const [isAction, setIsAction] = useState(false)
  const [actionMessage, setActionMessage] = useState('')
  const [navigate, setNavigate] = useState('')
  const [modalOpen, setModalOpen] = useState(false);
  
  const history = useHistory();

//   const [subType, setSubType] = useState('NO/ERROR');
//   const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(async () => {

    if (currentUser) {

        // check subscription status on stripe
        let subStatus = 'active'
        if (currentUserTier > 1) {
            subStatus = await checkSubStatus(currentUser)
            console.log('Subscription Status:', subStatus)
            if (subStatus == 'canceled') {
                // delete sub in db
                updateUserTier(currentUser, 0)
                updateSubId(currentUser, null)

                // open modal to tell user
                setIsAction(true)
                setNavigate('/pricing')
                setModalOpen(true);
                setMainMessage('Payment failed following free trial. We\'ve canceled your subscription.')
                setActionMessage('Retry')
            }
        }
    }
    
  }, [])

//   useEffect(async () => {

//     if (currentUser) {

//         // check subscription status on stripe
//         let subStatus = 'active'
//         if (currentUserTier == 0) {
//             subStatus = 'canceled'
//         }
//         if (currentUserTier > 1) {
//             subStatus = await checkSubStatus(currentUser)
//             console.log('Subscription Status:', subStatus)
//             if (subStatus == 'canceled') {
//                 //   updateUserTier(currentUser, 0)
//                 //   updateSubId(currentUser, null)
//                 setSubType('')
//                 setIsSubscribed(false)
//             } else {
//                 setSubType('PLUS')
//                 setIsSubscribed(true)
//             }
//         } else {
//             setSubType('')
//             setIsSubscribed(false)
//         }
//     }

//   }, [])


  let subType = 'NO/ERROR'
  let isSubscribed = false
  if (currentUserTier) {
      if (currentUserTier === 0) {
        subType = ''
      } else if (currentUserTier === 1) {
        subType = 'BASIC'
        isSubscribed = true
      } else if (currentUserTier === 2) {
        subType = 'PLUS'
        isSubscribed = true
      }
  }

  async function deleteSub() {
      history.push('/cancelation')
  }

  function changeSubClicked() {
      history.push('/pricing')
  }

  async function goCustomerPortal() {
    try {
      // Step 1: Get the subscription ID for the current user
      const subId = await getSubId(currentUser);
  
      // Step 2: Retrieve the customer ID by calling the /get-customer-id endpoint
      const customerRes = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/get-customer-id`, {
        subscription_id: subId, // Send the subscription ID to get the customer ID
      });
  
      const { customerId } = customerRes.data;
  
      if (!customerId) {
        console.error('Error: No customer ID returned from Stripe');
        alert('Failed to retrieve user. Please try again or email info@routora.com');
        return;
      }
  
      // Step 3: Once the customer ID is retrieved, create a session to the customer portal
      const res = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/create-customer-portal-session`, {
        customerId, // Pass the retrieved customer ID
        returnUrl: 'https://www.routora.com/profile', // The return URL after the user updates payment details
      });
  
      const { url } = res.data; // Extract the session URL from the response
  
      if (url) {
        window.location.href = url; // Redirect the user to the Stripe portal URL
      } else {
        console.error('Error: No URL returned from Stripe');
        alert('Failed to open user subscription portal. Please try again or email info@routora.com');
      }
    } catch (error) {
      console.error('Error opening customer portal:', error);
      alert('Failed to open user subscription portal. Please try again or email info@routora.com');
    }
  }
  

  return (
    <div >

        {modalOpen && 
        <Modal 
            setOpenModal={setModalOpen} 
            mainMessage={mainMessage}
            isAction={isAction}
            actionMessage={actionMessage}
            navigate={navigate}
        ></Modal>}

        <Header></Header>
        <div className="buffer-100"></div>
        
        {currentUser ? (
            <div>
                <div className="flex justify-center items-center mt-10" data-aos="zoom-y-out" data-aos-delay="150">
                    {currentUser.photoURL ? (
                        <img className="w-44 h-44 rounded-full"
                            alt=""
                            src={currentUser.photoURL}
                        />
                    ) : (
                        <div className="w-44 h-44 text-6xl pt-6 text-white ml-2 bg-myteal-500 rounded-full flex justify-center cursor-pointer" data-aos="zoom-y-out" data-aos-delay="150">{currentUser.displayName.charAt(0)}</div>
                    )}
                </div>

                <div className="max-w-3xl mt-5 mx-auto text-center pb-6 md:pb-10" data-aos="zoom-y-out" data-aos-delay="300">
                    <h2 className="h2">{currentUser.displayName}</h2>
                    <p className="text-xl text-gray-600">Manage your subscriptions</p>
                    {isSubscribed ? (
                        <div className="md:text-1xl text-gray-500"><span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-myteal-500">{subType}</span> SUBSCRIPTION</div>
                    ) : (
                        <div className="md:text-1xl text-gray-500">NOT SUBSCRIBED</div>
                    )}
                </div>

                <ul className="flex flex-grow justify-center flex-wrap items-center">
                    {isSubscribed ? (
                        <li>
                            <div onClick={deleteSub} className="font-medium text-myred-500 hover:text-myred-600 px-5 py-3 flex items-center transition duration-150 ease-in-out underline underline-offset-1 cursor-pointer">Delete Subscription</div>
                        </li>
                    ) : (
                        <div></div>
                    )}
                    {isSubscribed ? (
                        <li>
                            <div onClick={goCustomerPortal} className="btn-sm text-gray-100 bg-myred-500 hover:bg-myred-600 ml-3 cursor-pointer">
                                <span>Manage Billing</span>
                                <svg className="w-3 h-3 fill-current text-gray-100 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                                </svg>                  
                            </div>
                        </li>
                    ) : (
                        <li>
                            <div onClick={changeSubClicked} className="btn-sm text-gray-100 bg-myred-500 hover:bg-myred-600 ml-3 cursor-pointer">
                                <span>Try for Free</span>
                                <svg className="w-3 h-3 fill-current text-gray-100 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                                </svg>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        ) : (
            <div></div>
        )} 
        <div className="text-sm text-gray-600 mx-auto text-center mt-10">Email us at <span className="text-black">info@routora.com</span></div>

    </div>
  );
}

export default ProfilePage;