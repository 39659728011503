import React, { useState, useContext } from 'react';

import Header from '../../partials/Header';
import { AuthContext } from '../../AuthContext'
import { useHistory } from 'react-router-dom';

function OnboardingQuestions() {

    const history = useHistory();
    const { currentUser, addOnboarding } = useContext(AuthContext);

    const [questionNum, setQuestionNum] = useState(1)

    const [selectedSegment, setSelectectSegment] = useState('')
    const [selectedMedium, setSelectectMedium] = useState('')

    function backClicked() {
        if (questionNum == 1.5) {
            setQuestionNum(1)
        } else if (questionNum == 2.5) {
            setQuestionNum(2)
        } else {
            setQuestionNum(questionNum-1)
        }
    }

    // QUESTION 1
    const [selectedItem1, setSelectedItem1] = useState(null);
    const [options1, setOptions1] = useState(['Delivery', 'Personal Use', 'Travel', 'Real Estate', 'Field Service', 'Sales', 'Health Care', 'Other'])
    // Function to handle click event
    const handleItemClick1 = (itemId) => {
        setSelectedItem1(itemId);
        setSelectectSegment(options1[itemId])
        setTimeout(() => {
            // if other was selected, send to specify
            if (options1[itemId]=='Other') {
                setQuestionNum(1.5)
            } else {
                setQuestionNum(2)
            }
        }, 250); // 250 milliseconds delay
    };
    // Function to determine the CSS class for each item
    const getItemClass1 = (itemId) => {
        return itemId === selectedItem1 ? "border border-myblue-900 text-white bg-myblue-900 cursor-pointer rounded-lg p-4" : "border border-myblue-900 text-myblue-900 hover:bg-myblue-50 cursor-pointer rounded-lg p-4";
    };

    // QUESTION 1.5
    const [other1, setOther1] = useState('');

    // Function to handle input change
    const handleInputChange1half = (e) => {
        setOther1(e.target.value);
    };
    function nextClicked1() {
        if (other1.length > 0) {
            setSelectectSegment(other1)
        }
        setQuestionNum(2)
    }

    // QUESTION 2
    const [selectedItem2, setSelectedItem2] = useState(null);
    const [options2, setOptions2] = useState(['Google', 'Article/Blog', 'Friend/Colleague', 'Ad', 'Email', 'Social Media', 'Other'])
    // Function to handle click event
    const handleItemClick2 = (itemId) => {
        setSelectedItem2(itemId);
        setSelectectMedium(options2[itemId])
        setTimeout(() => {
            // if other was selected, send to specify
            if (options2[itemId]=='Other') {
                setQuestionNum(2.5)
            } else {
                setQuestionNum(3)
            }
        }, 250); // 250 milliseconds delay
    };
    // Function to determine the CSS class for each item
    const getItemClass2 = (itemId) => {
        return itemId === selectedItem2 ? "border border-myblue-900 text-white bg-myblue-900 cursor-pointer rounded-lg p-4" : "border border-myblue-900 text-myblue-900 hover:bg-myblue-50 cursor-pointer rounded-lg p-4";
    };

    // QUESTION 2.5
    const [other2, setOther2] = useState('');

    // Function to handle input change
    const handleInputChange2half = (e) => {
        setOther2(e.target.value);
    };
    function nextClicked2() {
        if (other2.length > 0) {
            setSelectectMedium(other2)
        }
        setQuestionNum(3)
    }

    // QUESTION 3
    const [companyName, setCompanyName] = useState('');

    // Function to handle input change
    const handleInputChange3 = (e) => {
        setCompanyName(e.target.value);
    };


    
    async function goPlayground() {

        let responses = {segment: selectedSegment, medium: selectedMedium, organization: companyName}
        console.log(responses)

        await addOnboarding(currentUser, responses)
        history.push('/playground')
    }
    

  return (
      
    <div className="h-screen flex justify-center mt-24" >

        <div className="max-w-md w-full mx-6">
            
            <h3 className="h3 text-myblue-900">Just a few questions before we dive in</h3>

            <div className="flex w-full my-2">
                <div className="w-full border-t-2 border-myblue-900 my-3"></div>
                {questionNum >= 2 ? <div className="w-full border-t-2 border-myblue-900 my-3"></div> : <div className="w-full border-t-2 border-gray-300 my-3"></div>}
                {questionNum == 3 ? <div className="w-full border-t-2 border-myblue-900 my-3"></div> : <div className="w-full border-t-2 border-gray-300 my-3"></div>}
            </div>

            {questionNum == 1 && 
                <div id="question1" className="mt-5">
                    <p className="text-gray-600">What will you be using Routora for?</p>

                    <div className="grid grid-cols-2 mt-3 gap-4">
                        {/* Example items (replace with your actual items) */}
                        {[0, 1, 2, 3, 4, 5, 6, 7].map((itemId) => (
                            <div
                                key={itemId}
                                className={getItemClass1(itemId)}
                                onClick={() => handleItemClick1(itemId)}
                            >
                                {options1[itemId]}
                            </div>
                        ))}
                    </div>
                </div>
            }

            {questionNum == 1.5 && 
                <div id="question1-5" className="mt-5">
                    <p className="text-gray-600">What will you be using Routora for?</p>

                    <input 
                        type="text"
                        value={other1}
                        onChange={handleInputChange1half}
                        placeholder="Please specify..."
                        className="w-full font-medium mt-28 p-0 border-0 border-b-2 border-gray-300 bg-transparent focus:outline-none focus:ring-0 focus:border-myblue-900 placeholder-gray-400 text-myblue-900"
                        autoFocus 
                    />

                    <p onClick={backClicked} className="underline mt-36 mb-3 underline-offset-2 mt-3 cursor-pointer">Back</p>
                    <div onClick={nextClicked1} className="text-center font-bold border border-blue-500 text-white bg-blue-500 cursor-pointer rounded-lg p-4">
                        <span className="">Next</span>
                    </div>
                </div>
            }

            {questionNum == 2 && 
                <div id="question2" className="mt-5">
                    <p className="text-gray-600">How did you hear about us?</p>

                    <div className="grid grid-cols-2 mt-3 gap-4">
                        {/* Example items (replace with your actual items) */}
                        {[0, 1, 2, 3, 4, 5, 6].map((itemId) => (
                            <div
                                key={itemId}
                                className={getItemClass2(itemId)}
                                onClick={() => handleItemClick2(itemId)}
                            >
                                {options2[itemId]}
                            </div>
                        ))}
                    </div>

                    <p onClick={backClicked} className="underline underline-offset-2 mt-3 cursor-pointer">Back</p>
                </div>
            }

            {questionNum == 2.5 && 
                <div id="question2-5" className="mt-5">
                    <p className="text-gray-600">How did you hear about us?</p>

                    <input 
                        type="text"
                        value={other2}
                        onChange={handleInputChange2half}
                        placeholder="Please specify..."
                        className="w-full font-medium mt-28 p-0 border-0 border-b-2 border-gray-300 bg-transparent focus:outline-none focus:ring-0 focus:border-myblue-900 placeholder-gray-400 text-myblue-900"
                        autoFocus 
                    />

                    <p onClick={backClicked} className="underline mt-36 mb-3 underline-offset-2 mt-3 cursor-pointer">Back</p>
                    <div onClick={nextClicked2} className="text-center font-bold border border-blue-500 text-white bg-blue-500 cursor-pointer rounded-lg p-4">
                        <span className="">Next</span>
                    </div>
                </div>
            }

            {questionNum == 3 && 
                <div id="question3" className="mt-5">
                    <p className="font-bold text-xs text-blue-500">*Optional*</p>
                    <p className="text-gray-600">What organization are you a part of?</p>

                    <input 
                        type="text"
                        value={companyName}
                        onChange={handleInputChange3}
                        className="w-full font-medium mt-28 p-0 border-0 border-b-2 border-gray-300 bg-transparent focus:outline-none focus:ring-0 focus:border-myblue-900 placeholder-gray-400 text-myblue-900"
                        placeholder="Example Company Inc."
                        autoFocus 
                    />

                    <p onClick={backClicked} className="underline mt-36 mb-3 underline-offset-2 mt-3 cursor-pointer">Back</p>
                    <div onClick={goPlayground} className="text-center font-bold border border-blue-500 text-white bg-blue-500 cursor-pointer rounded-lg p-4">
                        <span className="animate-pulse">Continue to Routora Maps</span>
                    </div>
                </div>
            }


        </div>
    
    </div>


  );
}

export default OnboardingQuestions;