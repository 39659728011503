import React, { useContext, useState, useEffect } from 'react'
import Header from '../../partials/Header.js'
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../AuthContext.js'
import './AllBlog.css'


function BlogHome() {

    const { getAllBlogData } = useContext(AuthContext);

    const history = useHistory();

    const [blogData, setBlogData] = useState([]);
    const [section, setSection] = useState(0)

    // retrieve blog data on component load
    useEffect(async () => {
        
        let firestoreBlogData = await getAllBlogData()

        setBlogData(firestoreBlogData)

    }, [])

    function blogClick(blogTitle) {
        history.push('/blogtest/' + blogTitle)
    }

    let tempBlogData
    if (blogData.length >= (6*section+6)) {
        tempBlogData = blogData.slice((6*section),(6*section+6))
    } else {
        tempBlogData = blogData.slice((6*section),(blogData.length))
    }

    function nextSection() {
        setSection(section+1)
    }
    function prevSection() {
        setSection(section-1)
    }

    return (
        <div style={{ backgroundColor: 'rgba(246, 249, 251, 1)' }}>
            <Header></Header>
            <div className="buffer-100"></div>

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-4 md:pb-7"></div>
                {/* Section header */}
                <div className="mx-auto text-left">
                    <h2 className="h2 ml-4 text-4xl md:text-5xl text-myblue-900">Featured Posts📝</h2>
                </div>
                {/* Items */}
                <div className="mx-auto grid md:grid-cols-3 lg:grid-cols-3 items-start w-full">
                    {
                        tempBlogData.map((data, i) =>
                            <a 
                                href={`https://www.routora.com/blog/${encodeURIComponent(data.title)}`}
                                style={{ height: 500 }} 
                                className="blog-card rounded-lg p-4 md:mt-5 cursor-pointer"
                                data-aos="zoom-y-out" 
                                data-aos-delay="150" 
                                key={i}
                            >
                                <img
                                    style={{ height: 200 }}
                                    src={data.thumbnailUrl}
                                    className="w-full object-cover rounded-md shadow-sm"
                                    alt={data.title}
                                />
                                <div>
                                    <h4 className="text-2xl pt-6 text-gray-700 hover:text-blue-500 font-bold leading-snug tracking-tight flex">{data.title}</h4>
                                    <div className="flex py-4">
                                        <p className="text-sm font-bold">{data.timestamp}</p>
                                        <h5 className="reading-time">{Math.ceil(data.content.length / 1200)} MINUTE READ</h5>
                                    </div>
                                    <div className="overflow-y-hidden h-24">
                                        <p className="text-gray-600 text-left h-24">{data.content.substring(0,200)}</p>
                                    </div>
                                </div>
                            </a>
                        )
                    }
                </div>

            </div>
            <div className="m-auto text-center mt-10">
                {section > 0 &&
                    <div className="btn-sm text-gray-100 bg-blue-500 hover:bg-blue-600 ml-3 w-32 cursor-pointer" onClick={prevSection}>
                        <span className="py-2">&lt; Previous</span>                
                    </div>
                }
                {(blogData.length > (6*section+6)) && 
                    <div className="btn-sm text-gray-100 bg-blue-500 hover:bg-blue-600 ml-3 w-32 cursor-pointer" onClick={nextSection}>
                        <span className="py-2">Next &gt;</span>           
                    </div>
                }
            </div>
            <div className="buffer-100"></div>
        </div>
    );
}

export default BlogHome;