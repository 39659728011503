import PlacesAutocomplete from 'react-places-autocomplete';

function StopsAutocomplete(props) {
  
    return (
        <PlacesAutocomplete
            value={props.content}
            onChange={props.handleChange}
            onSelect={props.handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: props.placeholder,
                    className: props.class,
                    type: 'text',
                  })}
                />
                {/* autocomplete section */}
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#eeecec', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                        <div
                            {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                            })}
                        >
                            <span>{suggestion.description}</span>
                        </div>
                    );
                  })}
                </div>
              </div>
            )}
        </PlacesAutocomplete>
    );
  }
  
  export default StopsAutocomplete;