import React, { useState } from 'react';
import * as XLSX from 'xlsx';

function ExportComponent(props) {
  const [showDropdown, setShowDropdown] = useState(false);

  function exportExcel() {
    console.log('Exporting to Excel...');
    // Implementation for exporting to Excel

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    
    // Data to be exported
    const data = props.directionsData.routeOrder.map((item, index) => ({
      Number: index + 1,
      Address: item.address,
      'Google Maps URL': item.soloUrls.googleUrl,
      'Waze URL': item.soloUrls.wazeUrl,
      'Apple Maps URL': item.soloUrls.appleUrl
    }));
    
    // Create a new worksheet from the data
    const ws = XLSX.utils.json_to_sheet(data);
    
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Routes');
    
    // Write the workbook and initiate download
    XLSX.writeFile(wb, 'MyRoutoraRoute.xlsx');

    setShowDropdown(false);
  };

  function exportCsv() {
    console.log('Exporting to CSV...');
    // Implementation for exporting to CSV

    // Define CSV headers
    const headers = ["Number", "Address", "Google Maps URL", "Waze URL", "Apple Maps URL"];
    
    // Map the routeOrder data to CSV rows
    const csvRows = props.directionsData.routeOrder.map((item, index) => [
      index + 1,
      `"${item.address}"`, // Wrap field in double quotes
      `"${item.soloUrls.googleUrl}"`, // Wrap field in double quotes
      `"${item.soloUrls.wazeUrl}"`, // Wrap field in double quotes
      `"${item.soloUrls.appleUrl}"` // Wrap field in double quotes
    ]);

    // Combine headers and rows
    const csvData = [
      headers.join(","), // Join headers with commas
      ...csvRows.map(row => row.join(",")) // Join each row's fields with commas
    ].join("\n");

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a link and set the URL as the Blob URL
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "MyRoutoraRoute.csv";
    document.body.appendChild(a);
    a.click();
    
    // Clean up: revoke the object URL and remove the link
    window.URL.revokeObjectURL(url);
    a.remove();

    // Close the dropdown
    setShowDropdown(false);
  };

  return (
    <div className="relative">
      <div className="flex text-gray-700 hover:text-blue-500 cursor-pointer ml-1" onClick={() => setShowDropdown(!showDropdown)}>
        <p className="font-semibold">Export</p>
        <svg style={{width: '15px', height: '15px', marginTop: '3px', marginLeft: '2px'}} xmlns="http://www.w3.org/2000/svg" class="hidden md:block" viewBox="0 0 512 512"><path d="M336 176h40a40 40 0 0140 40v208a40 40 0 01-40 40H136a40 40 0 01-40-40V216a40 40 0 0140-40h40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M176 272l80 80 80-80M256 48v288"/></svg>
      </div>
      {showDropdown && (
        <div className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={exportExcel}
            >
              Excel
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={exportCsv}
            >
              CSV
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExportComponent;
