import React, { useState, useContext } from 'react'
import Header from '../../partials/Header.js'
import { AuthContext } from '../../AuthContext'
import { useHistory } from 'react-router-dom';

function CreatePost() {

    const { createBlogDocument, currentUser } = useContext(AuthContext);

    const history = useHistory();

    const [title, setTitle] = useState("");
    const [postText, setPostText] = useState("");
    const [image, setImage] = useState(null);
    
    function submitBlog() {
        try {
            if (currentUser.email == 'tomvaz11@gmail.com' || currentUser.email == 'lgblazek@gmail.com') {
                if (image) {
                    createBlogDocument(title, postText, image)
                    alert('success')
                    history.push('/blog-main')
                } else {
                    alert('no image uploaded')
                }
            } else {
                alert('Uh Oh, you do not have access to submit a blog:(')
            }
        } catch (error) {
            console.log(error)
            alert('Uh Oh, you do not have access to submit a blog:(')
        }
    }

    return (
        <div style={{ backgroundColor: 'rgba(246, 249, 251, 1)' }} className="flex flex-col min-h-screen overflow-hidden pb-20">

            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="">
                <div className="buffer-100"></div>

                <div className="pb-4 md:pb-7"></div>
                {/* Section header */}
                <div className="max-w-3xl mb-5 mx-auto text-center">
                    <h2 className="h2 mb-2 text-myblue-900">Build your Blog Post</h2>
                    <p className="text-xl text-gray-600">Start creating below👇</p>
                </div>

                <input 
                    className="form-control
                        block
                        w-5/6
                        md:w-1/2
                        px-3
                        py-3
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        mx-auto
                        mb-2
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                        placeholder="Title" 
                        required
                        onChange={(event) => {
                            setTitle(event.target.value);
                        }}
                />
                <textarea
                    class="
                        form-control
                        block
                        w-5/6
                        md:w-1/2
                        px-3
                        py-3
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        mx-auto
                        h-96
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                    "
                    rows="3"
                    placeholder="Your message"
                    onChange={(event) => {
                        setPostText(event.target.value);
                    }}
                ></textarea>

                <div className="md:flex md:w-1/2 w-5/6 mt-7 mx-auto">
                    <input
                        type="file"
                        id="image"
                        className="md:w-1/2 w-full py-4 pl-8 md:mr-2 font-semibold text-lg rounded text-white bg-blue-500 text-center"
                        onChange={(e) => setImage(e.target.files[0])}
                    />
                    <div onClick={submitBlog} className="md:w-1/2 w-full md:mt-0 mt-7 py-5 md:ml-2 font-semibold text-lg rounded text-white cursor-pointer bg-myteal-500 hover:shadow-xl hover:bg-myteal-600 text-center">Submit</div>
                </div>


            </main>

        </div>
    );
}

export default CreatePost;