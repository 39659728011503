import React from 'react';

function Grid3() {
    return (
        <div className="group grid-elements grid3-parent flex flex-col justify-between text-lg items-end rounded-xl border transition duration-300 ease-in-out shadow-md hover:bg-gray-100 hover:shadow-lg">
            
            <div className="flex w-full pl-16 md:pl-24">
                <div className="route-chart shadow-md hover:shadow-lg flex w-full bg-white rounded-bl-xl rounded-tr-xl border-l border-b border-myblue-300 text-gray-500">
                    {/* Column for numbers */}
                    <div className="flex flex-col w-1/6 border-r border-myblue-300 bg-myblue-100 rounded-bl-xl">
                        <div className="h-12 border-b border-myblue-300 flex items-center justify-center">20</div>
                        <div className="h-12 border-b border-myblue-300 flex items-center justify-center">21</div>
                        <div className="h-12 border-b border-myblue-300 flex items-center justify-center">22</div>
                        <div className="h-12 border-b border-myblue-300 flex items-center justify-center">23</div>
                        <div className="h-12 border-b border-myblue-300 flex items-center justify-center">24</div>
                        <div className="h-12 border-myblue-300 flex items-center justify-center">25</div>
                    </div>

                    {/* Column for addresses */}
                    <div className="flex flex-col bg-myblue-50 w-5/6">
                        <div className="h-12 border-b border-myblue-300 flex items-center">
                            <p className="ml-5 overflow-hidden whitespace-nowrap">1001 Jacobson Dr, Dallas, TX 75253, USA</p>
                        </div>
                        <div className="h-12 border-b border-myblue-300 flex items-center">
                            <p className="ml-5 overflow-hidden whitespace-nowrap">9911 Bruton Rd, Dallas, TX 75217, USA</p>
                        </div>
                        <div className="h-12 border-b border-myblue-300 flex items-center">
                            <p className="ml-5 overflow-hidden whitespace-nowrap">8401 Maddox St, Dallas, TX 75217, USA</p>
                        </div>
                        <div className="h-12 border-b border-myblue-300 flex items-center">
                            <p className="ml-5 overflow-hidden whitespace-nowrap">201 Stoneport Dr, Dallas, TX 75217, USA</p>
                        </div>
                        <div className="h-12 border-b border-myblue-300 flex items-center">
                            <p className="ml-5 overflow-hidden whitespace-nowrap">5300 Audrey St, Dallas, TX 75210, USA</p>
                        </div>
                        <div className="h-12 border-myblue-300 flex items-center">
                            <p className="ml-5 overflow-hidden whitespace-nowrap">State Fair Of Texas Park, Grand Avenue, Dallas, TX, USA</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-container w-full p-5 md:p-10">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">
                    Download your itinerary
                </h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    Export your new route order into an Excel or CSV file for management and sharing.
                </p>
            </div>
        </div>
    );
}

export default Grid3;
