import React from 'react'
import Header from '../../partials/Header.js'

function APIDocumentation() {

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="">

                <h1>API Documentation</h1>


            </main>

        </div>
    );
}

export default APIDocumentation;