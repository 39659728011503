import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

const AddressImport = ({ onAddressesLoaded, setColumns, setJson, setOpenSelectModal }) => {
  const [fileName, setFileName] = useState('');

  const handleFileRead = (file) => {
    setFileName(file.name);
    
    const fileExtension = file.name.split('.').pop();
    const acceptedExtensions = ['xlsx', 'xls', 'csv'];

    if (!acceptedExtensions.includes(fileExtension)) {
      setFileName(null)
      alert("Please upload an Excel or CSV file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      let data;
      try {
        if (fileExtension === 'csv') {
          data = e.target.result;
        } else {
          data = new Uint8Array(e.target.result);
        }
        const workbook = XLSX.read(data, { type: fileExtension === 'csv' ? 'string' : 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        let columnHeaders = Object.keys(json[0]);
        console.log('Columns', columnHeaders)
        setColumns(columnHeaders)
        setJson(json)

        if (json.length === 0 || !json[0].hasOwnProperty('Address')) {
            setOpenSelectModal(true)
            return;
        }
        const addresses = json.map((row) => row.Address);
        onAddressesLoaded(addresses);
      } catch (error) {
        setFileName(null)
        alert("There was an error reading the file.");
        console.error(error);
      }
    };

    if (fileExtension === 'csv') {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    handleFileRead(file);
  }, [onAddressesLoaded]); // Make sure to include onAddressesLoaded in the dependency array

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.xlsx, .xls, .csv'
  });

  return (
    <div {...getRootProps()} className="w-full p-16 border-2 border-gray-300 border-dashed rounded-md cursor-pointer bg-white text-center">
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className="text-blue-500 p-8">Drop the file here ...</p>
      ) : (
        <div className="space-y-1 text-center">
          <div className="flex justify-center items-center">
            <button className="px-6 py-3 bg-blue-500 text-white text-sm font-bold rounded-full hover:bg-blue-700 focus:outline-none transition-colors">
              Upload File
            </button>
          </div>
          <p className="text-xs text-gray-500">
            or drag 'n' drop file here; .xlsx, .xls, .csv accepted 
          </p>
          {fileName && (
            <p className="text-sm text-gray-600 font-medium mt-2">
              Selected file: {fileName}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressImport;
