import React from "react";

const Step = (props) => {
  return (
    <div className="flex items-center">
      {props.isActive ? (
        <>
          <div className="flex items-center justify-center w-8 h-8 rounded-full bg-myteal-600 text-white text-sm font-semibold mr-3">
            {props.stepNumber}
          </div>
          <div className="text-myteal-600 text-sm font-medium">{props.label}</div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-600 text-sm font-semibold mr-3">
            {props.stepNumber}
          </div>
          <div className="text-gray-600 text-sm font-medium">{props.label}</div>
        </>
      )}
    </div>
  );
};

export default Step;
