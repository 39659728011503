import React from "react";

const Input = (props) => {
  return (
    <div className="p-4">
      <label
        className="block text-gray-600 text-xs font-medium mb-1"
        htmlFor="input"
      >
        {props.label}
      </label>
      <input
        id="input"
        type={props.type ?? "email"}
        className="form-input w-full text-gray-800 px-2 py-1 pr-2 text-xs hover:bg-gray-100"
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        defaultValue={props.defaultValue}
        required
      />
    </div>
  );
};

export default Input;
