import React from 'react';
import { useHistory } from 'react-router-dom';

function CancelModalConfirmation(props) {

    const history = useHistory();

    function actionClicked() {
        history.push(props.navigate)
    }

  return (
    <div className="modalBackground">
      <div className="modalContainer">
          
            <div className="body text-2xl text-gray-600">
                <p>{props.mainMessage}</p>
            </div>
            <div className="footer">
                {props.isAction && <div className="btn-sm text-gray-100 bg-blue-500 hover:bg-blue-600 cursor-pointer m-4 w-44" onClick={actionClicked}>{props.actionMessage}</div>}
            </div>
      </div>
    </div>
  );
}

export default CancelModalConfirmation;