import React, { useContext } from 'react';

function Grid2() {
    return (
        <div className="group grid-elements grid1-parent flex flex-col md:flex-row text-lg items-end rounded-xl border transition duration-300 ease-in-out mb-3 shadow-md hover:bg-gray-100 hover:shadow-lg">
            
            {/* Text container */}
            <div className="text-container md:w-2/5 w-full p-5 md:p-10 md:order-1">
                <h1 className="text-3xl text-white group-hover:text-myblue-900 font-extrabold leading-tighter tracking-tighter mb-4">Share or start your journey</h1>
                <p className="text-myblue-100 group-hover:text-gray-500">
                    Keep track of your route in real-time. Not driving? Share your newly generated route to whoever is embarking on this journey. Compatible with: 
                </p>
                <div className="text-myblue-100 group-hover:text-gray-500">
                    <div className="flex my-2">
                        <svg style={{marginTop: 5}} xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 512 512"><path fill="none" stroke="#07dbae" stroke-linecap="round" stroke-linejoin="round" stroke-width="50" d="M416 128L192 384l-96-96"/></svg>
                        <p className="text-left ml-2">Google Maps</p>
                    </div>
                    <div className="flex my-2">
                        <svg style={{marginTop: 5}} xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 512 512"><path fill="none" stroke="#07dbae" stroke-linecap="round" stroke-linejoin="round" stroke-width="50" d="M416 128L192 384l-96-96"/></svg>
                        <p className="text-left ml-2">Apple Maps</p>
                    </div>
                    <div className="flex my-2">
                        <svg style={{marginTop: 5}} xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 512 512"><path fill="none" stroke="#07dbae" stroke-linecap="round" stroke-linejoin="round" stroke-width="50" d="M416 128L192 384l-96-96"/></svg>
                        <p className="text-left ml-2">Waze</p>
                    </div>
                </div>
            </div>

            {/* Image container */}
            <div className="grid2-container md:w-3/5 w-full md:order-2">
                <img src={require('./GridImages/mockup-grid2.png').default} alt="Shared Route Mockup" className="grid1-img rounded-br-lg rounded-tl-lg md:rounded-r-lg"/>
            </div>
        </div>
    );
}

export default Grid2;






